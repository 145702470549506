/**
 * Created by vladislav on 29/11/2022
 */

var HighlightView = cleverapps.Spine.extend({
    ctor: function (highlight) {
        this._super(bundles.battlefield.jsons.target_cell_json);

        this.highlight = highlight;

        this.setVisible(false);
        this.setLocalZOrder(-10000);

        highlight.onMove = this.move.bind(this);
    },

    move: function () {
        if (this.highlight.pos) {
            this.setPositionRound(
                (this.highlight.pos.x + 0.5) * cleverapps.styles.EpicartScene.cell.width,
                (this.highlight.pos.y + 0.5) * cleverapps.styles.EpicartScene.cell.height
            );
            this.setVisible(true);
            this.setAnimation(0, "animation", true);
        } else {
            this.setVisible(false);
        }
    }
});