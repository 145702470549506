/**
 * Created by ivan on 11.04.18.
 */

var ArmyLibraryToolbarItem = function () {
    ToolbarItem.call(this, ToolbarItem.ARMY_LIBRARY);

    this.enable();
};

ArmyLibraryToolbarItem.prototype = Object.create(ToolbarItem.prototype);
ArmyLibraryToolbarItem.prototype.constructor = ArmyLibraryToolbarItem;

ArmyLibraryToolbarItem.prototype.resetState = function () {
    if (cleverapps.armyLibrary.needsAttention()) {
        this.mark();
    } else {
        this.unmark();
    }
};

ArmyLibraryToolbarItem.prototype.isVisible = function () {
    return true;
};

ArmyLibraryToolbarItem.prototype.onClick = function () {
    cleverapps.focusManager.display({
        control: ["MenuBarCoinsItem"],
        focus: "UnitsLibraryIconClicked",
        action: function (f) {
            new ArmyLibraryWindow();

            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    });
};