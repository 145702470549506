/**
 * Created by vladislav on 28/03/2023
 */

var Skill = function (warrior, options) {
    this.warrior = warrior;
    this.options = options;

    this.range = this.options.range;
    this.targetSelection = this.options.targetSelection;
};

Skill.prototype.canUse = function () {
    return false;
};

Skill.Create = function (warrior, name, options) {
    if (name === "rage") {
        return new Rage(warrior, options);
    } if (name === "specialAttack") {
        return new SpecialAttack(warrior, options);
    } if (name === "teleport") {
        return new Teleport(warrior, options);
    } if (name === "flank") {
        return new Flank(warrior, options);
    } if (name === "throwingKnives") {
        return new ThrowingKnives(warrior, options);
    }
};