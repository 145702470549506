/**
 * Created by mac on 4/22/20
 */

var EpicArtEditor = function () {
    EditorBase.call(this);

    this.onUpdateSize = function () {};
    this.onUpdatePowerRating = function () {};

    this.orangery = new Orangery(BattlefieldOrangery);
    this.armyGenerator = new ArmyGenerator();
};

EpicArtEditor.prototype = Object.create(EditorBase.prototype);
EpicArtEditor.prototype.constructor = EpicArtEditor;

Editor = EpicArtEditor;

EpicArtEditor.BUNDLES = ["admin"];

EpicArtEditor.prototype.spawnSquad = function (data) {
    var squad;
    var removedSquad;
    this.history.add({
        redo: function () {
            if (!removedSquad) {
                removedSquad = Game.currentGame.battlefield.removeSquadAt(data.x, data.y);
            }
            if (squad) {
                Game.currentGame.battlefield.addSquad(squad);
            } else {
                squad = Game.currentGame.battlefield.spawnSquad(data, Warrior.TEAM_ENEMY);
            }

            this.onUpdatePowerRating();
            this.save();
        }.bind(this),

        undo: function () {
            Game.currentGame.battlefield.removeSquadAt(squad.x, squad.y);
            if (removedSquad) {
                Game.currentGame.battlefield.addSquad(removedSquad);
            }

            this.onUpdatePowerRating();
            this.save();
        }.bind(this)
    });
};

EpicArtEditor.prototype.removeSquadAt = function (x, y) {
    if (!Game.currentGame.battlefield.findSquad(x, y)) {
        return;
    }

    var squad;
    this.history.add({
        redo: function () {
            squad = Game.currentGame.battlefield.removeSquadAt(x, y);

            this.onUpdatePowerRating();
            this.save();
        }.bind(this),

        undo: function () {
            Game.currentGame.battlefield.addSquad(squad);

            this.onUpdatePowerRating();
            this.save();
        }.bind(this)
    });
};

EpicArtEditor.prototype.moveSquad = function (squad, x, y) {
    var prevX;
    var prevY;
    this.history.add({
        redo: function () {
            prevX = squad.x;
            prevY = squad.y;

            Game.currentGame.battlefield.moveSquad(squad, x, y);

            this.onUpdatePowerRating();
            this.save();
        }.bind(this),

        undo: function () {
            Game.currentGame.battlefield.moveSquad(squad, prevX, prevY);

            this.onUpdatePowerRating();
            this.save();
        }.bind(this)
    });
};

EpicArtEditor.prototype.editorClicked = function (pos) {
    var selected = this.orangery.selected;
    if (selected) {
        if (selected.eraser) {
            this.removeSquadAt(pos.x, pos.y);
        } else {
            this.spawnSquad({
                code: selected.code,
                stage: selected.stage,
                x: pos.x,
                y: pos.y
            });
        }

        return true;
    }
};

EpicArtEditor.prototype.getSaveData = function () {
    var data = EditorBase.prototype.getSaveData.call(this);

    data.army = {};

    var region = Game.currentGame.battlefield.region;
    if (region.width !== Battlefield.GRID.x || region.height !== Battlefield.GRID.y) {
        data.army.size = {
            x: region.width / 2,
            y: region.height
        };
    }

    data.army.formation = Game.currentGame.battlefield.listSquads(Warrior.TEAM_ENEMY).map(function (squad) {
        return {
            x: squad.x,
            y: squad.y,
            code: squad.code,
            stage: squad.stage
        };
    });

    return data;
};

EpicArtEditor.prototype.setSize = function (x, y) {
    Game.currentGame.battlefield = new Battlefield(
        { x: x, y: y },
        LevelManager.getInstance().getMaxVersionContent().army.formation
    );

    this.save();

    this.onUpdateSize();
};

EpicArtEditor.prototype._load = function () {
};

EpicArtEditor.prototype.clear = function () {
    var squads = Game.currentGame.battlefield.listSquads(Warrior.TEAM_ENEMY);

    this.history.add({
        redo: function () {
            squads.forEach(function (squad) {
                Game.currentGame.battlefield.removeSquad(squad.x, squad.y);
            });

            this.onUpdatePowerRating();
            this.save();
        }.bind(this),

        undo: function () {
            squads.forEach(function (squad) {
                Game.currentGame.battlefield.addSquad(squad);
            });

            this.onUpdatePowerRating();
            this.save();
        }.bind(this)
    });
};

EpicArtEditor.prototype.clearEnemies = function () {
    Game.currentGame.battlefield.listSquads(Warrior.TEAM_ENEMY).forEach(function (squad) {
        Game.currentGame.battlefield.removeSquad(squad.x, squad.y);
    });

    this.onUpdatePowerRating();
    this.save();
};
