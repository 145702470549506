/**
 * Created by vladislav on 30/11/2022
 */

var BarracksSquadView = cc.Node.extend({
    ctor: function (barracksSquad) {
        this._super();

        this.barracksSquad = barracksSquad;

        this.setAnchorPoint(0.5, 0.5);

        this.backlight = this.createBacklight();
        this.addChild(this.backlight);

        this.setContentSize2(this.backlight.getContentSize());
        this.backlight.setPositionRound(this.width / 2, this.height / 2);

        this.bg = this.createBg();
        this.addChild(this.bg);
        this.bg.setPositionRound(this.width / 2, this.height / 2);

        this.icon = this.createIcon();
        this.addChild(this.icon);
        this.icon.setPositionRound(this.width / 2, this.height / 2);

        this.raceIcon = this.createRaceIcon();
        this.addChild(this.raceIcon);

        this.addChild(this.createLevel());

        this.barracksSquad.onDragStart = this.createListener(this.setDragged.bind(this, true));
        this.barracksSquad.onDragEnd = this.createListener(this.setDragged.bind(this, false));
        this.barracksSquad.onGetView = this.createListener(function () {
            return this;
        }.bind(this));

        var squad;
        var squadView;
        cleverapps.UI.onDrag(this, {
            holdForDrag: true,

            onClick: this.createListener(this.onClick.bind(this)),

            followPointer: function (touch) {
                if (squad) {
                    squadView.setPositionRound(squadView.parent.convertTouchToNodeSpace(touch));
                }
            },

            onDragStart: function () {
                cleverapps.barracks.startDrag(this.barracksSquad);

                squad = new Squad({
                    x: -999,
                    y: -999,
                    code: barracksSquad.code,
                    stage: barracksSquad.stage
                }, Warrior.TEAM_PLAYER);
                squadView = new SquadView(squad);
                Game.currentGame.battlefield.addToLayer(Battlefield.LAYERS.UNITS, squadView);

                return squad.handleDragStart();
            }.bind(this),

            onDragMove: function (touch) {
                squad.handleDragMove(squadView.parent.convertTouchToNodeSpace(touch));
            },

            onDragEnd: function (options) {
                squad.handleDragEnd(options, true);
            }
        });
    },

    onClick: function () {
        cleverapps.barracks.deployInstantly(this.barracksSquad);
    },

    setDragged: function (dragged) {
        if (dragged) {
            this.icon.setColor(new cc.Color(120, 120, 120, 255));
            this.bg.setColor(new cc.Color(120, 120, 120, 255));
        } else {
            this.icon.setColor(new cc.Color(255, 255, 255, 255));
            this.bg.setColor(new cc.Color(255, 255, 255, 255));
        }
    },

    createBg: function () {
        var styles = cleverapps.styles.BarracksSquadView;

        var background = new cc.Scale9Sprite(bundles.barracks.frames.barracks_unit_bg);
        background.setContentSize2(styles.background);
        background.setPositionRound(this.width / 2, this.height / 2);

        return background;
    },

    createLevel: function () {
        var styles = cleverapps.styles.BarracksSquadView;

        var bg = new cc.Sprite(bundles.troop_icon.frames.level_bg);
        bg.setPositionRound(styles.level);

        var level = cleverapps.UI.generateOnlyText(this.barracksSquad.stage + 1, cleverapps.styles.FONTS.SMALL_WHITE_TEXT);
        bg.addChild(level);
        level.setPositionRound(bg.width / 2, bg.height / 2);

        return bg;
    },

    createRaceIcon: function () {
        var styles = cleverapps.styles.BarracksSquadView;

        var raceIcon = new cc.Scale9Sprite(bundles.race_icons.frames["race_" + this.barracksSquad.line.base.race]);
        raceIcon.setScale(0.6);
        raceIcon.setPositionRound(styles.raceIcon);

        return raceIcon;
    },

    createBacklight: function () {
        var styles = cleverapps.styles.BarracksSquadView;

        var backlight = new cc.Scale9Sprite(bundles.barracks.frames["barracks_backlight_" + this.barracksSquad.line.base.rarity]);
        backlight.setContentSize2(styles.backlight);
        backlight.setPositionRound(this.width / 2, this.height / 2);

        return backlight;
    },

    createIcon: function () {
        var styles = cleverapps.styles.BarracksSquadView;

        var icon = WarriorView.getUnitImage(this.barracksSquad.line, this.barracksSquad.stage);
        cleverapps.UI.fitToBox(icon, styles.icon);
        icon.setPositionRound(styles.icon);

        return icon;
    }
});

cleverapps.styles.BarracksSquadView = {
    raceIcon: {
        x: { align: "left", dx: 10 },
        y: { align: "bottom", dy: 9 }
    },

    backlight: {
        width: 143,
        height: 143
    },

    background: {
        width: 130,
        height: 130
    },

    icon: {
        width: 100,
        height: 100
    },

    level: {
        x: { align: "right", dx: -7 },
        y: { align: "bottom", dy: 7 }
    }
};