/**
 * Created by vladislav on 15/12/2022
 */

var WarriorsAmountView = cc.Sprite.extend({
    ctor: function (team) {
        this._super(team === Warrior.TEAM_PLAYER
            ? bundles.battlefield.frames.banner_player
            : bundles.battlefield.frames.banner_enemy);

        this.team = team;

        this.amount = Game.currentGame.aliveWarriorsAmount(team);

        var styles = cleverapps.styles.WarriorsAmountView[team];

        this.amountText = this.createAmount();
        this.addChild(this.amountText);
        this.amountText.setPositionRound(styles.amount);

        var icon = new cc.Sprite(bundles.battlefield.frames.icon_player_white);
        this.addChild(icon);
        icon.setPositionRound(styles.icon);

        this.setPositionRound(styles);
        this.originalPosition = styles;
    },

    createAmount: function () {
        var amountText = cleverapps.UI.generateImageText(this.amount, cleverapps.styles.FONTS.WARRIORS_AMOUNT_TEXT);

        Game.currentGame.battlefield.on("removeSquad", this.createListener(function (squad) {
            if (squad.team === this.team) {
                this.changeAmount(-squad.getAliveAmount());
            }
        }.bind(this)));

        Game.currentGame.battlefield.on("spawnSquad", this.createListener(function (squad) {
            if (squad.team === this.team) {
                this.changeAmount(squad.getAliveAmount());
            }
        }.bind(this)));

        Game.currentGame.on("warriorDied", this.createListener(function (warrior) {
            if (warrior.team === this.team) {
                this.changeAmount(-1);
            }
        }.bind(this)));

        Game.currentGame.battlefield.on("spawnWarrior", this.createListener(function (warrior) {
            if (warrior.team === this.team) {
                this.changeAmount(1);
            }
        }.bind(this)));

        return amountText;
    },

    changeAmount: function (diff) {
        this.amount += diff;

        this.amountText.setString(this.amount);
    }
});

cleverapps.styles.WarriorsAmountView = {};
cleverapps.styles.WarriorsAmountView[Warrior.TEAM_PLAYER] = {
    x: { align: "left", dx: -20 },
    y: { align: "top", dy: -130 },

    icon: {
        x: { align: "center", dx: 18 },
        y: { align: "center", dy: 26 }
    },

    amount: {
        x: { align: "center", dx: 18 },
        y: { align: "center", dy: -23 }
    }
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    WARRIORS_AMOUNT_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.WarriorsAmountView[Warrior.TEAM_ENEMY] = {
    x: { align: "right", dx: 20 },
    y: { align: "top", dy: -130 },

    icon: {
        x: { align: "center", dx: -18 },
        y: { align: "center", dy: 26 }
    },

    amount: {
        x: { align: "center", dx: -18 },
        y: { align: "center", dy: -23 }
    }
};