/**
 * Created by vladislav on 26/04/2023
 */

var NewTroopWindow = Window.extend({
    onWindowLoaded: function (code, stage) {
        this._super({
            name: "NewTroopWindow",
            title: "NewTroopWindow.title",
            noBackground: true,
            button: {
                text: "OK"
            },
            content: this.createContent(code, stage)
        });
    },

    createContent: function (code, stage) {
        var styles = cleverapps.styles.NewTroopWindow;

        var icon = new ArmyLibraryIcon(code, stage);

        var shine = new cleverapps.Spine(bundles.troop_reward_window.jsons.open_particles_json);
        shine.setAnimation(0, "idle", true);
        shine.setLocalZOrder(-2);
        icon.addChild(shine);
        shine.setPositionRound(icon.width / 2, icon.height / 2);

        var text = cleverapps.UI.generateOnlyText("NewTroopWindow.text", cleverapps.styles.FONTS.SMALL_WHITE_TEXT);
        text.setDimensions(styles.text.width, 0);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        return new cleverapps.Layout([icon, text], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    }
});

cleverapps.styles.NewTroopWindow = {
    margin: 60,

    icon: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 }
    },

    text: {
        width: 600
    }
};