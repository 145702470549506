/**
 * Created by vladislav on 15/12/2022
 */

var BattleStatsComponent = cleverapps.Layout.extend({
    ctor: function () {
        var styles = cleverapps.styles.BattleStatsComponent;

        var isVictory = Game.currentGame.outcome === GameBase.OUTCOME_VICTORY;

        var msg = isVictory ? "Report.victory" : "Report.lose";
        var text = cleverapps.UI.generateOnlyText(msg, cleverapps.styles.FONTS.WHITE_TEXT);

        var amount = Game.currentGame.aliveWarriorsAmount(isVictory ? Warrior.TEAM_PLAYER : Warrior.TEAM_ENEMY);

        var amountText = cleverapps.UI.generateImageText(amount, cleverapps.styles.FONTS.WHITE_TEXT);

        var button = new cleverapps.UI.Button({
            text: "Report.button",
            width: styles.button.width,
            height: styles.button.height,
            onClicked: function () {
                new BattleStatsWindow();
            }
        });

        var layout = new cleverapps.Layout([amountText, button], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.amountMargin
        });

        this._super([text, layout], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });

        this.setCascadeOpacityEnabledRecursively(true);
    },

    hide: function () {
        this.runAction(new cc.FadeOut(0.3));
    }
});

cleverapps.styles.BattleStatsComponent = {
    button: {
        width: 200,
        height: 90
    },

    padding: {
        bottom: 30
    },

    amountMargin: 100,
    margin: 30
};