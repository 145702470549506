/**
 * Created by vladislav on 13/02/2023
 */

var GenerateArmyButton = cleverapps.UI.Button.extend({
    ctor: function () {
        var styles = cleverapps.styles.GenerateArmyButton;

        this._super({
            width: styles.width,
            height: styles.height,
            text: "generate",
            onClicked: this.onClick.bind(this)
        });

        this.setPositionRound(cleverapps.styles.GenerateArmyButton);
    },

    onClick: function () {
        Editor.currentEditor.clearEnemies();

        var army = Editor.currentEditor.armyGenerator.generate(
            Battlefield.GRID,
            +Game.currentGame.level.episodeNo * AdminEpisode.LEVELS_PER_EPISODE + +Game.currentGame.level.levelNo
        );

        for (var i = 0; i < army.length; i++) {
            for (var j = 0; j < army[i].length; j++) {
                if (army[i][j]) {
                    Editor.currentEditor.spawnSquad(army[i][j]);
                }
            }
        }
    }
});

cleverapps.styles.GenerateArmyButton = {
    width: 300,
    height: 100,

    x: { align: "center" },
    y: { align: "top", dy: -200 }
};
