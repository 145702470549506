/**
 * Created by mac on 9/18/22
 */

var ShortMeta = function () {
    if (cleverapps.config.regime === "knockout") {
        this.knockoutGame = cleverapps.meta.knockoutGame = new KnockoutGame(); // temporarily
    }

    var data = cleverapps.dataLoader.load(DataLoader.TYPES.SHORTMETA)
        || cleverapps.dataLoader.load(DataLoader.TYPES.KNOCKOUT);
    this.load(data);
};

ShortMeta.prototype.reset = function () {
    if (this.knockoutGame) {
        this.knockoutGame.reset();
    }

    this.save();
};

ShortMeta.prototype.gamePlayed = function (outcome) {
    if (cleverapps.config.regime === "knockout") {
        if (this.knockoutGame.rumble.outcome === Rumble.OUTCOME_WIN) {
            this.multiplyRewards();
        }
    } else if (outcome === GameBase.OUTCOME_VICTORY) {
        this.multiplyRewards();
    }
};
ShortMeta.prototype.getReward = function (level) {
    return RewardsConfig.ShortMeta[level % 10];
};

ShortMeta.prototype.multiplyRewards = function () {
    var level = cleverapps.user.getVirtualProgress();

    var reward = this.getReward(level);
    if (reward) {
        for (var type in reward) {
            var amount = Game.currentGame.rewards[type] * (reward[type].factor || 1);
            amount -= Game.currentGame.rewards[type];

            if (type === GameBase.REWARD_HARD) {
                Game.currentGame.addHardReward(amount);
            } else if (type === GameBase.REWARD_SOFT) {
                Game.currentGame.addSoftReward(amount);
            }
        }
    }
};

ShortMeta.prototype.getInfo = function () {
    var info = {};

    if (this.knockoutGame) {
        info.knockoutLevel = this.knockoutGame.getLevel();
    }

    return info;
};

ShortMeta.prototype.updateInfo = function (serverData) {
    this.load(serverData);
    this.save(true);
};

ShortMeta.prototype.load = function (data) {
    if (this.knockoutGame) {
        this.knockoutGame.load();

        data = data || {};

        if (data.knockoutLevel !== undefined) {
            this.knockoutGame.setLevel(data.knockoutLevel);
            this.save();
        } else {
            var level = data.cups || data.level || 0;

            var userProgress = cleverapps.user.getVirtualProgress();

            cleverapps.user.setLevel(level);

            this.knockoutGame.setLevel(userProgress);
            this.save();
        }
    }
};

ShortMeta.prototype.save = function (fromServer) {
    cleverapps.dataLoader.save(DataLoader.TYPES.SHORTMETA, this.getInfo());
    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("metha");
    }

    if (this.knockoutGame) {
        this.knockoutGame.save();
    }
};