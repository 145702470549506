/**
 * Created by vladislav on 06/04/2023
 */

var TeleportActivity = function (warrior, target, firePercent) {
    Activity.call(this, warrior, "teleport");

    this.target = target;
    this.firePercent = firePercent;
    this.sound = bundles.units.urls.teleport_effect;
};

TeleportActivity.prototype = Object.create(Activity.prototype);
TeleportActivity.prototype.constructor = TeleportActivity;

TeleportActivity.prototype.onFire = function () {
    this.teleport();
};

TeleportActivity.prototype.teleport = function () {
    var target = this.target;

    var sign = this.warrior.head === Warrior.HEAD_RIGHT ? 1 : -1;
    var position = cc.p(
        target.x + sign * (target.getRadius() + this.warrior.getRadius()),
        target.y
    );

    this.warrior.setPosition(position.x, position.y);

    this.warrior.turnAround();
};
