/**
 * Created by mac on 10/22/22
 */

var WarriorView = cleverapps.Spine.extend({
    ctor: function (warrior) {
        this.warrior = warrior;

        var animationData = WarriorView.UnitAnimationData(warrior.code, warrior.stage, warrior.squadIndex, warrior.team);

        this._super(animationData.json);
        this.setAnchorPoint(0.5, 0);
        this.setIdle("idle");

        if (animationData.skin !== undefined && this.hasSkin(animationData.skin)) {
            this.setSkin(animationData.skin);
        }

        this.hp = new ScaledProgressBar({
            progress: warrior.team === Warrior.TEAM_PLAYER ? bundles.units.frames.hpbar_green : bundles.units.frames.hpbar_red,
            background: bundles.units.frames.hpbar_bg
        });
        this.hp.setPositionRound(cleverapps.styles.WarriorView.hp);
        this.addChild(this.hp);

        this.hp.setLength(cleverapps.styles.WarriorView.hp.width);
        this.hp.setPercentage(warrior.getRemainingHealthPercent());
        this.hp.visible = false;

        this.baseScaleX = 1;
        this.baseScaleY = 1;
        if (warrior.head === Warrior.HEAD_LEFT) {
            this.setScaleX(-1);
            this.hp.setScale(this.scaleX);
        }

        this.effects = {};

        warrior.onChangeActivity = this.createListener(this.changeActivity.bind(this));
        warrior.onMoved = this.createListener(this.syncPosition.bind(this));
        warrior.onTurned = this.createListener(this.turned.bind(this));
        warrior.onArrowRelease = this.createListener(this.arrowReleased.bind(this));
        warrior.onDamage = this.createListener(this.damaged.bind(this));
        warrior.onRemoved = this.createListener(this.removed.bind(this));
        warrior.getDurationTicks = this.createListener(this.getDurationTicks.bind(this));
        warrior.onDie = this.createListener(this.onDie.bind(this));
        warrior.onSetTimeScale = this.createListener(this.onSetTimeScale.bind(this));
        warrior.onSetPosition = this.createListener(this.syncPosition.bind(this));
        warrior.onApplyEffect = this.createListener(this.applyEffect.bind(this));
        warrior.onRemoveEffect = this.createListener(this.removeEffect.bind(this));
        warrior.onSetSkin = this.createListener(this.setSkin.bind(this));
        warrior.onGetView = this.createListener(function () {
            return this;
        }.bind(this));

        if (cleverapps.config.debugMode && !cleverapps.environment.isSceneWithPreview()) {
            this.debugInfo = new WarriorDebugInfo(this.warrior);
            this.addChild(this.debugInfo);
            this.debugInfo.setPositionRound(this.width / 2, 0);
            this.debugInfo.setLocalZOrder(-1);
        }
    }
});

WarriorView.prototype.applyEffect = function (animationName) {
    if (this.effects[animationName]) {
        this.effects[animationName].counter++;
        return;
    }

    var animation = new cleverapps.Spine(bundles.units.jsons["effect_" + animationName]);
    this.addChild(animation);
    animation.setPositionRound(this.width / 2, this.height / 2);
    animation.setAnimation(0, "animation", true);

    this.effects[animationName] = {
        animation: animation,
        counter: 1
    };
};

WarriorView.prototype.removeEffect = function (animationName) {
    this.effects[animationName].counter--;

    if (this.effects[animationName].counter === 0) {
        this.effects[animationName].animation.removeFromParent();
        delete this.effects[animationName];
    }
};

WarriorView.prototype.syncPosition = function () {
    var koef = Epicart.virtualToReal();

    this.x = this.warrior.x * koef;
    this.y = this.warrior.y * koef;

    this.setLocalZOrder(-this.warrior.y);
};

WarriorView.prototype.turned = function () {
    if (this.warrior.head === Warrior.HEAD_LEFT) {
        this.setScaleX(-1);
    } else {
        this.setScaleX(1);
    }

    this.hp.setScale(this.scaleX);
};

WarriorView.prototype.arrowReleased = function (options, attack, callback) {
    var arrow = new ArrowView(this.warrior, attack);
    this.addChild(arrow);
    Game.currentGame.battlefield.addToLayer(Battlefield.LAYERS.ANIMATIONS, arrow);
    arrow.shoot(options, callback);
};

WarriorView.prototype.removed = function () {
    this.removeFromParent(true);
};

WarriorView.prototype.damaged = function (diff, head, source) {
    this.hp.visible = true;
    this.hp.setPercentage(this.warrior.getRemainingHealthPercent());

    var blood = new cleverapps.Spine(bundles.battlefield.jsons.blood_json);
    this.addChild(blood);
    blood.setPositionRound(this.width / 2, this.height / 2);
    blood.setAnimation(0, "animation", false);
    if (head !== Warrior.HEAD_ANY) {
        blood.setScaleX(head === Warrior.HEAD_LEFT ? -this.scaleX : this.scaleX);
    }
    blood.setCompleteListenerRemove();

    if (source.asound) {
        cleverapps.audio.playSound(bundles.units.urls[source.asound + "_damage_effect"]);
    }
};

WarriorView.prototype.onSetTimeScale = function (timeScale) {
    this.setTimeScale(timeScale);
};

WarriorView.prototype.onDie = function () {
    this.hp.removeFromParent(true);
};

WarriorView.prototype.getDurationTicks = function (animation) {
    var duration = this.getAnimationData(animation).duration;

    return Math.ceil(duration / Epicart.TICK);
};

WarriorView.prototype.changeActivity = function (activity) {
    if (activity.sound) {
        cleverapps.audio.playSound(activity.sound);
    }

    if (activity.timeScale) {
        this.setTimeScale(activity.timeScale);
    }

    if (activity.idle) {
        this.setIdle(activity.idle);
    } else if (activity.animation) {
        this.setAnimation(0, activity.animation, false);
    }
};

WarriorView.UnitArrowSpine = function (code) {
    var unitKey = "unit_" + code + "_arrow";
    return bundles.units.jsons[unitKey];
};

WarriorView.UnitAnimationData = function (code, stage, squadIndex, team) {
    var data = WarriorsConfig[code][stage][squadIndex];
    if (!data) {
        data = WarriorsConfig[code][stage][0];
    }

    if (team === Warrior.TEAM_ENEMY) {
        return {
            json: data.json.replace("unit_", "unit_enemy_"),
            skin: data.skin
        };
    }

    return {
        json: data.json,
        skin: data.skin
    };
};

WarriorView.getUnitImage = function (unitLine, stage, preferStatic) {
    var node = new cc.Node();
    node.setAnchorPoint(0.5, 0.5);

    var amount = unitLine.line[stage].load;
    var formation = Epicart.SMALL_FORMATIONS[amount - 1];

    var dx = 1 / (formation[0].length + 1);
    var dy = 1 / (formation.length + 1);

    var cell = cleverapps.styles.EpicartScene.cell;

    var squadIndex = 0;
    for (var i = 0; i < formation.length; i++) {
        for (var j = 0; j < formation[i].length; j++) {
            if (formation[i].charAt(j) === ".") {
                continue;
            }

            var animationData = WarriorView.UnitAnimationData(unitLine.base.id, stage, squadIndex);

            var animation = new cleverapps.Spine(animationData.json);
            if (animationData.skin !== undefined && animation.hasSkin(animationData.skin)) {
                animation.setSkin(animationData.skin);
            }
            animation.setIdle("idle");
            if (preferStatic) {
                setTimeout(function () {
                    animation.clearTrack(0);
                }, 0);
            }
            node.addChild(animation);
            animation.setPositionRound(dx * cell.width * j, dy * cell.height * (formation.length - i - 1));

            squadIndex++;
        }
    }

    cleverapps.UI.wrap(node);

    return node;
};

cleverapps.styles.WarriorView = {
    hp: {
        x: { align: "center" },
        y: { align: "top", dy: 20 },
        width: 100
    }
};