/**
 * Created by vladislav on 05/04/2023
 */

var Activity = function (warrior, name) {
    this.warrior = warrior;
    this.name = name;

    this.counter = 0;
    this.duration = this.getDuration();
    this.timeScale = 1;
    this.animation = name;
};

Activity.prototype.setTimeScale = function (timeScale) {
    this.timeScale = timeScale;
    this.duration = Math.round(this.duration / timeScale);
    this.counter = Math.round(this.counter / timeScale);

    this.warrior.onSetTimeScale(this.timeScale);
};

Activity.prototype.getDuration = function () {
    return this.warrior.getDurationTicks(this.name) || 1;
};

Activity.prototype.getTicks = function () {
    return this.counter;
};

Activity.prototype.isFinished = function () {
    return this.counter === this.duration;
};

Activity.prototype.onTick = function () {
    this.counter++;

    if (this.firePercent !== undefined && this.counter === Math.round(this.duration * this.firePercent / 100)) {
        this.onFire();
    }
};

Activity.prototype.onStart = function () {};

Activity.prototype.onFire = function () {};

Activity.prototype.onFinish = function () {};