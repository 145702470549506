/**
 * Created by vladislav on 29/03/2023
 */

var PauseWindow = Window.extend({
    onWindowLoaded: function () {
        this._super({
            name: "PauseWindow",
            noBackground: true,
            closeButton: false,
            content: this.createContent()
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.PauseWindow;

        var buttons = [
            {
                type: cleverapps.styles.UI.Button.Images.button_green,
                icon: bundles.buttons.frames.resume_png,
                text: "Button.resume",
                onClicked: function () {
                    this.close();

                    Game.currentGame.resume();
                }.bind(this)
            },
            {
                type: cleverapps.styles.UI.Button.Images.button_blue,
                icon: bundles.buttons.frames.quit_png,
                text: "Button.quit",
                onClicked: function () {
                    cleverapps.meta.distract({
                        focus: "QuitViaPauseWindow",
                        action: function (f) {
                            f();

                            this.close();

                            cc.director.getRunningScene().closeAction();
                        }.bind(this)
                    });
                }.bind(this)
            }
        ].map(this.createButton.bind(this));

        return new cleverapps.Layout(buttons, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createButton: function (options) {
        var styles = cleverapps.styles.PauseWindow;

        var text = cleverapps.UI.generateOnlyText(options.text, cleverapps.styles.FONTS.BUTTON_TEXT);
        var textNode = new cc.Node();
        textNode.setContentSize2(styles.button.text.width, text.height);
        textNode.setAnchorPoint(0.5, 0.5);
        textNode.addChild(text);
        text.setPositionRound(textNode.width / 2, textNode.height / 2);
        text.fitTo(textNode.width);

        var icon = new cc.Sprite(options.icon);
        var iconNode = new cc.Node();
        iconNode.setAnchorPoint(0.5, 0.5);
        iconNode.setContentSize2(styles.button.icon.width, text.height);

        iconNode.addChild(icon);
        icon.setPositionRound(iconNode.width / 2, iconNode.height / 2);

        var content = new cleverapps.Layout([iconNode, textNode], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.button.margin
        });

        return new cleverapps.UI.Button({
            type: options.type,
            content: content,
            width: styles.button.width,
            height: styles.button.height,
            onClicked: options.onClicked
        });
    }
});

cleverapps.styles.PauseWindow = {
    margin: 20,

    button: {
        icon: {
            width: 75
        },

        text: {
            width: 250
        },

        width: 350,
        height: 110,

        margin: 5
    }
};