/**
 * Created by mac on 9/16/22
 */

var Warrior = function (options) {
    cleverapps.EventEmitter.call(this);

    this.code = options.code;
    this.stage = options.stage;

    this.squad = options.squad;
    this.squadIndex = options.squadIndex;
    this.squadX = options.squadX;
    this.squadY = options.squadY;

    this.moveVector = cc.p(0, 0);

    this.team = options.team;
    this.head = (this.team === Warrior.TEAM_PLAYER) ? Warrior.HEAD_RIGHT : Warrior.HEAD_LEFT;

    this.hp = this.get("hp");
    this.dead = false;

    this.counter = 0;
    this.activity = undefined;

    this.appliedForces = [];
    this.force = {
        x: 0,
        y: 0
    };

    this.effects = [];

    var normalAttack = this.get("normalAttack");

    this.skills = [];
    var skills = this.get("skills");
    if (skills) {
        for (var name in skills) {
            var skill = skills[name];
            if (name === "extendNormalAttack") {
                if (skill.normalAttack.effects) {
                    var effects = cleverapps.extend(normalAttack.effects, skill.normalAttack.effects);
                }

                cleverapps.extend(normalAttack, skill.normalAttack);
                normalAttack.effects = effects;
            } else {
                this.skills.push(Skill.Create(this, name, skill));
            }
        }
    }

    this.normalAttack = new NormalAttack(this, normalAttack);

    this.skills.push(this.normalAttack);

    this.ignoresCollision = this.get("ignoresCollision");

    var animationData = WarriorView.UnitAnimationData(this.code, this.stage, this.squadIndex, this.team);

    var skeleton = cc.loader.getRes(processVirtualJson(animationData.json)).skeleton;
    this.size = cc.size(skeleton.width, skeleton.height);

    this.logic = Warrior.LOGICS[this.get("logic") || "base"];

    this.onChangeActivity = function () {};
    this.onMoved = function () {};
    this.onTurned = function () {};
    this.onArrowRelease = function () {};
    this.onDamage = function () {};
    this.onRemoved = function () {};
    this.onSetPosition = function () {};
    this.onSetSkin = function () {};
    this.onGetView = function () {};

    if (cleverapps.config.debugMode) {
        this.onUpdateDebugSize = function () {};
        this.onToggleDebugInfo = function () {};
    }
};

Warrior.prototype = Object.create(cleverapps.EventEmitter.prototype);
Warrior.prototype.constructor = Warrior;

Warrior.HEAD_ANY = 0;
Warrior.HEAD_RIGHT = 1;
Warrior.HEAD_LEFT = 2;

Warrior.TEAM_PLAYER = 0;
Warrior.TEAM_ENEMY = 1;
Warrior.TEAM_ANY = 2;

Warrior.prototype.setPosition = function (x, y) {
    this.x = x;
    this.y = y;

    this.onSetPosition();
};

Warrior.prototype.getPosition = function () {
    return cc.p(this.x, this.y);
};

Warrior.prototype.getMass = function () {
    return this.getRadius() * this.getRadius() / 100;
};

Warrior.prototype.getRadius = function () {
    return this.size.width / 2;
};

Warrior.prototype.getSize = function () {
    return this.size;
};

Warrior.prototype.applyKnockback = function (knockbackDist, pos) {
    var direction = this.directionTo(pos);
    var distance = knockbackDist * Epicart.DISTANCE_MULTIPLIER - direction.l;
    var force = cc.pMult(cc.pNeg(direction), distance);

    var duration = 0.25;
    this.applyForce(force, duration);
};

Warrior.prototype.applyForce = function (force, duration) {
    this.appliedForces.push(new AppliedForce(this, force, duration));
};

Warrior.prototype.processAppliedForces = function () {
    this.appliedForces = this.appliedForces.filter(function (force) {
        return !force.isFinished();
    });

    this.appliedForces.forEach(function (force) {
        force.onTick();
    });
};

Warrior.prototype.addForce = function (force) {
    cc.pAddIn(this.force, force);
};

Warrior.prototype.calcPushForce = function (other) {
    var size = this.getRadius();

    var direction = this.directionTo(other);

    var distance = size + other.getRadius() - direction.l;
    if (distance <= 0) {
        return;
    }

    var force = -distance * distance / 16 / this.getMass();

    return cc.pMult(direction, force);
};

Warrior.prototype.calcMoveForce = function (others) {
    var moveForce = this.moveVector;

    for (var i = 0; i < others.length; i++) {
        var direction = this.directionTo(others[i]);

        var angle = cc.pAngleSigned(direction, moveForce);
        if (Math.abs(angle) < Math.PI / 4) {
            var deviationAngle = Math.PI / 4 - Math.abs(angle);
            if (angle < 0) {
                deviationAngle *= -1;
            }

            return cc.p(
                Math.cos(deviationAngle) * moveForce.x - Math.sin(deviationAngle) * moveForce.y,
                Math.sin(deviationAngle) * moveForce.x + Math.cos(deviationAngle) * moveForce.y
            );
        }
    }

    return moveForce;
};

Warrior.prototype.resetForce = function () {
    this.force.x = 0;
    this.force.y = 0;
    this.isBeingPushed = false;
};

Warrior.prototype.receiveDamage = function (damage, head, damageDealer, source) {
    if (this.dead) {
        return;
    }

    if (this.squad) {
        this.squad.addStat(BattleStats.TYPES.DAMAGE_BLOCKED, Math.min(damage, this.get("def")));
    }

    var diff = damage - this.get("def");
    if (diff <= 0) {
        return;
    }

    if (diff > this.hp) {
        diff = this.hp;
    }

    if (cleverapps.flags.noDeath && this.hp - diff === 0) {
        return;
    }

    if (this.squad) {
        this.squad.addStat(BattleStats.TYPES.DAMAGE_RECEIVED, diff);
    }

    if (damageDealer.squad) {
        damageDealer.squad.addStat(BattleStats.TYPES.DAMAGE, diff);
    }

    this.hp -= diff;
    this.onDamage(diff, head, source);

    this.trigger("receiveDamage");

    if (this.hp === 0) {
        this.die();
    }
};

Warrior.prototype.afterDied = function () {
    this.needsRemoval = true;

    this.onRemoved();
};

Warrior.prototype.canAttack = function (enemy, distance, range) {
    if (enemy === this) {
        return true;
    }

    if (distance > this.attackRange(range) + enemy.getRadius()) {
        return false;
    }

    return Warrior.headingTo(this, enemy);
};

Warrior.headingTo = function (a, b) {
    return a.head === Warrior.HEAD_RIGHT && a.x <= b.x || a.head === Warrior.HEAD_LEFT && a.x >= b.x;
};

Warrior.prototype.directionTo = function (target) {
    var dx = target.x - this.x;
    var dy = target.y - this.y;

    var length = Math.sqrt(dx * dx + dy * dy);

    return {
        x: length === 0 ? 0 : dx / length,
        y: length === 0 ? 0 : dy / length,
        l: length
    };
};

Warrior.prototype.applyEffect = function (type, options, warrior, source) {
    var existingEffect = this.effects.find(function (effect) {
        return effect.type === type && effect.source === source;
    });

    if (existingEffect) {
        existingEffect.refresh();
    } else {
        var effect = Effect.Create(type, this, options, warrior, source);
        this.effects.push(effect);
        effect.onStart();
    }
};

Warrior.prototype.getAttribute = function (attr, baseValue) {
    var value = baseValue || this.get(attr);

    this.effects.forEach(function (effect) {
        if (effect.applyModifier) {
            value = effect.applyModifier(attr, value);
        }
    });

    return value;
};

Warrior.prototype.fireAttack = function (attack, target, targetSize, distance) {
    var head = this.head;

    var targetPosition = target.getPosition();
    if (attack.type === WarriorLines.ATTACK.RANGED) {
        var approxTicks = Math.round(distance / attack.projectile.speed / Epicart.TICK);

        targetPosition.x += target.moveVector.x * approxTicks;
        targetPosition.y += target.moveVector.y * approxTicks;

        var options = {
            x: targetPosition.x,
            y: targetPosition.y,
            size: targetSize,
            distance: distance
        };

        this.onArrowRelease(options, attack, function () {
            Game.currentGame.applyAttack(this, attack, targetPosition, head);
        }.bind(this));
    } else {
        if (attack.applicationPoint === WarriorLines.APPLICATION_POINT.FROM_CENTER) {
            targetPosition = cc.p(this.x, this.y);
        }

        Game.currentGame.applyAttack(this, attack, targetPosition, head);
    }

    this.trigger("attack", attack);
};

Warrior.prototype.moveSpeed = function () {
    return this.getAttribute("speed") * 0.45;
};

Warrior.prototype.turnAround = function () {
    this.head = (this.head === Warrior.HEAD_RIGHT) ? Warrior.HEAD_LEFT : Warrior.HEAD_RIGHT;

    this.onTurned();
};

Warrior.prototype.move = function (direction) {
    if (Math.abs(direction.x) + Math.abs(direction.y) < 0.01) {
        return;
    }

    this.x += direction.x;
    this.y += direction.y;

    this.onMoved();
};

Warrior.LOGICS = {
    base: new WarriorLogic()
};

Warrior.prototype.getRemainingHealthPercent = function () {
    return 100 * this.hp / this.get("hp");
};

Warrior.GET = function (code, stage, key) {
    if (WarriorLines[code].line[stage][key] !== undefined) {
        return WarriorLines[code].line[stage][key];
    }

    return WarriorLines[code].base[key];
};

Warrior.prototype.get = function (key) {
    return Warrior.GET(this.code, this.stage, key);
};

Warrior.prototype.getInfo = function () {
    return {
        code: code,
        stage: stage
    };
};

Warrior.prototype.attackRange = function (range) {
    var ranges = {
        close: 30,
        medium: 60,
        infinity: 1e9
    };

    return ranges[range] || range * Epicart.DISTANCE_MULTIPLIER;
};

Warrior.prototype.otherTeam = function () {
    return this.team === Warrior.TEAM_PLAYER ? Warrior.TEAM_ENEMY : Warrior.TEAM_PLAYER;
};

Warrior.prototype.runActivity = function (activity) {
    if (this.activity) {
        this.activity.onFinish();
    }

    this.activity = activity;
    if (this.activity) {
        this.activity.onStart();

        this.onChangeActivity(this.activity);
    }
    this.counter = 0;
};

Warrior.prototype.die = function () {
    this.dead = true;

    if (this.team === Warrior.TEAM_ENEMY) {
        Game.currentGame.addSoftReward(Epicart.KILL_REWARD, {
            x: this.x,
            y: this.y
        });
    }

    Game.currentGame.trigger("warriorDied", this);

    this.runActivity(new DieActivity(this));
};

Warrior.prototype.processEffects = function () {
    var finishedEffects = [];

    this.effects.forEach(function (effect) {
        effect.onTick();
    });

    this.effects = this.effects.filter(function (effect) {
        var isFinished = effect.isFinished();
        if (isFinished) {
            finishedEffects.push(effect);
        }
        return !isFinished;
    });

    finishedEffects.forEach(function (effect) {
        effect.onFinish();
    });
};

Warrior.prototype.tick = function () {
    this.processEffects();

    if (this.activity) {
        this.activity.onTick();
    }

    var newActivity = this.logic.chooseActivity(this);
    if (newActivity !== this.activity) {
        this.runActivity(newActivity);
    } else {
        this.counter++;
    }
};