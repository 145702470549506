/**
 * Created by mac on 10/22/22
 */

var ArmyLibrary = function () {
    this.load(cleverapps.dataLoader.load(DataLoader.TYPES.ARMY_LIBRARY));

    this.codesByType = {};

    this.codesByType[0] = WarriorLines.UNITS.filter(function (unit) {
        return !unit.base.hero;
    }).map(function (unit) {
        return unit.base.id;
    });

    this.codesByType[1] = WarriorLines.UNITS.filter(function (unit) {
        return !unit.base.hero;
    }).map(function (unit) {
        return unit.base.id;
    });
};

ArmyLibrary.prototype.needsAttention = function () {
    return cleverapps.values(this.fresh).some(function (freshForCode) {
        return cleverapps.values(freshForCode).filter(Boolean).length > 0;
    }, this);
};

ArmyLibrary.prototype.removeFresh = function (code, source) {
    var stages = cleverapps.values(this.fresh[code]).filter(Boolean).length;

    this.fresh[code] = {};

    this.giveReward(stages * 10, source);

    cleverapps.toolbar.resetByType(ToolbarItem.ARMY_LIBRARY);

    this.save();
};

ArmyLibrary.prototype.giveReward = function (amount, source) {
    var options = {
        event: cleverapps.EVENTS.EARN.OTHER
    };

    var reward = new Reward("soft", amount, options);
    reward.receiveReward();
    reward.collectRewardsAnimation(source, options);
};

ArmyLibrary.prototype.isFresh = function (code) {
    return this.fresh[code] && cleverapps.values(this.fresh[code]).filter(Boolean).length > 0;
};

ArmyLibrary.prototype.setFresh = function (code, stage) {
    if (!this.fresh[code]) {
        this.fresh[code] = {};
    }

    this.fresh[code][stage] = true;

    this.save();
};

ArmyLibrary.prototype.isCodeOpened = function (code) {
    if (!this.opened[code]) {
        return false;
    }

    return Object.keys(this.opened[code]).some(function (stage) {
        return this.opened[code][stage];
    }, this);
};

ArmyLibrary.prototype.isOpened = function (code, stage) {
    return this.opened[code] && this.opened[code][stage];
};

ArmyLibrary.prototype.setOpened = function (code, stage) {
    if (!this.opened[code]) {
        this.opened[code] = {};
    }

    if (this.opened[code][stage]) {
        return;
    }

    this.opened[code][stage] = true;

    this.setFresh(code, stage);

    this.save();
};

ArmyLibrary.prototype.load = function (stored) {
    if (stored && typeof stored === "object") {
        if (stored.fresh) {
            this.opened = cleverapps.listFromBit(stored.opened);
            this.fresh = cleverapps.listFromBit(stored.fresh);
        } else {
            this.opened = cleverapps.listFromBit(stored);
            this.fresh = {};
        }
    } else {
        this.opened = {};
        this.fresh = {};
    }
};

ArmyLibrary.prototype.getInfo = function () {
    return {
        opened: cleverapps.listToBit(this.opened),
        fresh: cleverapps.listToBit(this.fresh)
    };
};

ArmyLibrary.prototype.updateInfo = function (serverData) {
    this.load(serverData);

    this.save(true);
};

ArmyLibrary.prototype.save = function (fromServer) {
    cleverapps.dataLoader.save(DataLoader.TYPES.ARMY_LIBRARY, this.getInfo());

    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("armylibrary");
    }
};

ArmyLibrary.prototype.reset = function () {
    this.opened = {};
    this.fresh = {};

    this.save();
};

ArmyLibrary.prototype.getUnopenedAvailable = function () {
    return this.listBy({}, true).find(function (code) {
        return !this.isCodeOpened(code);
    }, this);
};

ArmyLibrary.prototype.listBy = function (options, onlyAvailable) {
    var race = cleverapps.toArray(options.race);
    var rarity = cleverapps.toArray(options.rarity);

    var codes = this.codesByType[0].filter(function (id) {
        var base = WarriorLines[id].base;

        return (race === undefined || race.includes(base.race))
            && (rarity === undefined || rarity.includes(base.rarity));
    });

    if (onlyAvailable) {
        return codes.filter(function (code) {
            return cleverapps.user.checkAvailable(WarriorLines[code].base.available);
        });
    }

    return codes;
};

ArmyLibrary.prototype.getUnitName = function (unitLine) {
    return Messages.get("Units." + unitLine.base.id + ".name");
};
