/**
 * Created by vladislav on 30/03/2023
 */

var SpecialAttack = function (warrior, options) {
    Skill.call(this, warrior, options);

    this.triggerNumber = 0;

    this.warrior.on("attack", this.onAttack.bind(this));
};

SpecialAttack.prototype = Object.create(Skill.prototype);
SpecialAttack.prototype.constructor = SpecialAttack;

SpecialAttack.prototype.onAttack = function () {
    if (this.warrior.activity.name === "attack") {
        this.triggerNumber++;
    } else {
        this.triggerNumber = 0;
    }
};

SpecialAttack.prototype.canUse = function () {
    return this.triggerNumber >= this.options.triggerNumber;
};

SpecialAttack.prototype.getActivity = function (targetData) {
    return new AttackActivity(this.warrior, this.options, targetData.target, targetData.distance, "specialAttack");
};