/**
 * Created by vladislav on 20/02/2023
 */

var TroopCardView = cc.Sprite.extend({
    ctor: function (product) {
        this._super(bundles.troop_cards.frames["troop_card_" + product.stage]);

        var styles = cleverapps.styles.TroopCardView;

        var stageBadge = new TroopStageView(product.stage);
        this.addChild(stageBadge);
        stageBadge.setPositionRound(styles.stars);
    }
});

cleverapps.styles.TroopCardView = {
    stars: {
        margin: 10,

        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 30 }
    }
};