/**
 * Created by mac on 10/22/22
 */

var ArmyLibraryWindow = Window.extend({
    onWindowLoaded: function (unit) {
        this.searchUnit = unit;

        this._super({
            name: "ArmyLibraryWindow",
            noPadding: true,
            content: this.createContent(),
            foreground: bundles.windows.frames.window_foreground_png
        });
    },

    createRarityLayouts: function () {
        var styles = cleverapps.styles.ArmyLibraryWindow;

        return cleverapps.values(WarriorLines.RARITY).map(function (rarity) {
            var codes = cleverapps.armyLibrary.listBy({
                rarity: rarity
            });

            var icons = codes.map(function (code) {
                var icon = new ArmyLibraryIcon(code, undefined, {
                    library: true
                });

                if (cleverapps.config.debugMode) {
                    var availableLevel = Math.round(WarriorLines[code].base.available.level * Episode.LEVELS_PER_EPISODE);
                    var availableText = cleverapps.UI.generateOnlyText("lvl " + availableLevel, cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
                    icon.addChild(availableText);
                    availableText.setPositionRound(styles.availableLevelDebug);
                }

                var helpButton = new cleverapps.UI.HelpButton(this.onClick.bind(this, code, icon));
                icon.addChild(helpButton);
                helpButton.setPositionRound(styles.helpButton);

                cleverapps.UI.onClick(icon, this.onClick.bind(this, code, icon));
                cleverapps.UI.applyHover(icon);

                return icon;
            }, this);

            return new FamilyLayout("", icons, {
                title: "Rarity." + rarity,
                columns: 6,
                width: styles.width,
                gridPosition: styles.gridPosition,
                margin: styles.layout.margin
            });
        }, this);
    },

    createContent: function () {
        var styles = cleverapps.styles.ArmyLibraryWindow;

        this.grid = new cleverapps.Layout(this.createRarityLayouts(), {
            margin: styles.margin,
            direction: cleverapps.UI.VERTICAL,
            padding: styles.grid.padding
        });

        var wrap = new cc.Node();
        wrap.setAnchorPoint(0.5, 0.5);
        wrap.setContentSize2(this.grid.width, Math.max(this.grid.height, styles.height));
        wrap.addChild(this.grid);
        this.grid.setPositionRound(wrap.width / 2, wrap.height - this.grid.height / 2);

        var scroll = new cleverapps.UI.ScrollView(wrap, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_FULLCHECK,
            scrollBarEnabled: false,
            direction: cleverapps.UI.ScrollView.DIR_VERTICAL
        });

        scroll.setContentSize(wrap.width, styles.height);
        scroll.scrollToDefault();

        return cleverapps.UI.wrapWithPadding(scroll, styles.padding);
    },

    onClick: function (code, icon) {
        if (cleverapps.armyLibrary.isFresh(code)) {
            icon.collectReward();
        } else {
            new TroopWindow(code);
        }
    }
});

cleverapps.styles.ArmyLibraryWindow = {
    width: 2000,
    height: 1100,

    margin: 30,

    padding: {
        x: 60,
        y: 30
    },

    layout: {
        margin: {
            x: 15,
            y: 20
        }
    },

    helpButton: {
        x: { align: "right", dx: -20 },
        y: { align: "top", dy: -20 }
    },

    gridPosition: {
        x: { align: "left" },
        y: { align: "center", dy: -20 }
    },

    grid: {
        padding: {
            top: 45,
            bottom: 30
        }
    },

    availableLevelDebug: {
        x: { align: "left", dx: 25 },
        y: { align: "top", dy: -20 }
    }
};
