/**
 * Created by vladislav on 10/04/2023
 */

var AreaView = cleverapps.Spine.extend({
    ctor: function (area) {
        this.area = area;

        this._super(bundles.units.jsons["unit_" + area.sourceWarrior.code + "_area"]);

        var coef = Epicart.virtualToReal();
        this.setPositionRound(this.area.position.x * coef, this.area.position.y * coef);

        this.setAnimationAndIdleAfter("open", "idle");

        this.area.onRemove = this.createListener(this.onRemove.bind(this));
    },

    onRemove: function () {
        this.setAnimation(0, "close", false);
        this.setCompleteListenerRemove();
    }
});