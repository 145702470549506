/**
 * Created by vladislav on 20/03/2023
 */

var BattlefieldTutorial = function () {
    cleverapps.EventEmitter.call(this);

    this.shownTutorials = {};
};

BattlefieldTutorial.prototype = Object.create(cleverapps.EventEmitter.prototype);
BattlefieldTutorial.prototype.constructor = BattlefieldTutorial;

BattlefieldTutorial.prototype.showTutorial = function () {
    if (Game.currentGame.isBattleStopped()) {
        return;
    }

    var tutorial = this.findTutorial();
    if (!tutorial) {
        return;
    }

    if (Game.currentGame.mode === Epicart.MODE_BATTLE) {
        this._showTutorial(function () {}, tutorial);
    } else {
        cleverapps.meta.display({
            focus: "Tutorial",
            action: function (f) {
                this._showTutorial(f, tutorial);
            }.bind(this)
        });
    }
};

BattlefieldTutorial.prototype.isActive = function () {
    return this.current;
};

BattlefieldTutorial.prototype._showTutorial = function (f, tutorial) {
    this.onTutorialFinished = f;

    this.current = tutorial;

    this.shownTutorials[tutorial.id] = (this.shownTutorials[tutorial.id] || 0) + 1;

    this.trigger("showTutorial", tutorial);
};

BattlefieldTutorial.prototype.stopTutorial = function () {
    if (!this.current) {
        return;
    }

    this.trigger("stopTutorial");

    delete this.current;

    this.onTutorialFinished();

    Game.currentGame.counter.trigger();
};

BattlefieldTutorial.prototype.findTutorial = function () {
    for (var i = 0; i < BattlefieldTutorial.TUTORIALS.length; i++) {
        var tutorial = BattlefieldTutorial.TUTORIALS[i];

        if (this.shownTutorials[i] >= tutorial.amount) {
            continue;
        }

        if (tutorial.level !== cleverapps.user.level || tutorial.episode !== cleverapps.user.episode) {
            continue;
        }

        if (tutorial.mode !== undefined && tutorial.mode !== Game.currentGame.mode) {
            continue;
        }

        tutorial = cleverapps.clone(tutorial);
        tutorial.id = i;

        if (tutorial.type === "merge") {
            var units = this.findUnitsForMerge();

            if (!units) {
                continue;
            }

            tutorial.units = units;
            tutorial.allUnits = Game.currentGame.battlefield.listSquads();
        } else if (tutorial.type === "reinforcement") {
            var region = Game.currentGame.battlefield.region;
            tutorial.fingerPosition = cc.p(
                (region.x + region.width / 2) * Epicart.VIRTUAL_CELL.width,
                (region.y - 1) * Epicart.VIRTUAL_CELL.height
            );
            tutorial.rects = [
                cc.rect(
                    tutorial.fingerPosition.x - Epicart.VIRTUAL_CELL.width / 2,
                    tutorial.fingerPosition.y - Epicart.VIRTUAL_CELL.width / 2,
                    Epicart.VIRTUAL_CELL.width,
                    Epicart.VIRTUAL_CELL.width
                )
            ];
            Game.currentGame.alive[Warrior.TEAM_ANY].forEach(function (warrior) {
                tutorial.rects.push(warrior.onGetView().getBoundingBox());
            });
        }

        return tutorial;
    }
};

BattlefieldTutorial.prototype.onMerge = function () {
    if (this.current && this.current.type === "merge") {
        this.stopTutorial();
    }
};

BattlefieldTutorial.prototype.onReinforcement = function () {
    if (this.current && this.current.type === "reinforcement") {
        this.stopTutorial();
    }
};

BattlefieldTutorial.prototype.onDragStart = function () {
    if (this.current) {
        this.trigger("hideFinger");
    }
};

BattlefieldTutorial.prototype.onDragEnd = function () {
    if (this.current) {
        this.trigger("showFinger", this.current);
    }
};

BattlefieldTutorial.prototype.findUnitsForMerge = function () {
    var squads = Game.currentGame.battlefield.listSquads(Warrior.TEAM_PLAYER);

    var mergeable = {};
    squads.forEach(function (squad) {
        var key = squad.code + "_" + squad.stage;
        if (!mergeable[key]) {
            mergeable[key] = [];
        }

        if (squad.stage < WarriorLines[squad.code].line.length - 1) {
            mergeable[key].push(squad);
        }
    });

    var key = Object.keys(mergeable).find(function (key) {
        return mergeable[key].length >= 2;
    });

    if (!key) {
        return;
    }

    return mergeable[key].slice(0, 2);
};

BattlefieldTutorial.TUTORIALS = [
    {
        level: 1,
        episode: 0,
        amount: 2,
        type: "merge",
        text: "BattlefieldTutorial.merge"
    },

    {
        level: 2,
        episode: 0,
        mode: Epicart.MODE_BATTLE,
        forcePosition: Dialogue.POSITIONS.BOTTOM,
        amount: 1,
        type: "reinforcement",
        text: "BattlefieldTutorial.reinforcement"
    }
];