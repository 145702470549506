/**
 * Created by vladislav on 21/02/2023
 */

var WarriorDebugInfo = cc.Node.extend({
    ctor: function (warrior) {
        this._super();

        this.warrior = warrior;

        this.setAnchorPoint(0.5, 0.5);

        var color = this.warrior.team === Warrior.TEAM_ENEMY
            ? new cc.Color(254, 12, 0, 255)
            : new cc.Color(4, 41, 227, 255);

        this.setCascadeColorEnabled(true);

        if (this.warrior.normalAttack.range !== WarriorLines.RANGE.INFINITY) {
            this.range = new cc.Sprite(bundles.debug_info.frames.unit_range);
            this.addChild(this.range);
            this.range.setScale(2 * warrior.attackRange(warrior.normalAttack.range) / this.range.width);
            this.range.setColor(color);
        }

        this.size = new cc.Sprite(bundles.debug_info.frames.unit_size);
        this.addChild(this.size);
        this.size.setScale(2 * warrior.getRadius() / this.size.width);
        this.size.setColor(new cc.Color(251, 12, 246, 255));

        this.warrior.onUpdateDebugSize = this.createListener(this.updateSize.bind(this));
        this.warrior.onToggleDebugInfo = this.createListener(this.onToggle.bind(this));

        this.updateSize();
        this.onToggle();
    },

    onToggle: function () {
        this.setVisible(cleverapps.flags.debugInfo);
    },

    updateSize: function () {
        if (Game.currentGame.mode === Epicart.MODE_SETUP) {
            this.size.setVisible(true);
            return;
        }

        this.size.setVisible(this.warrior.isBeingPushed);
    },

    onChangeActivity: function (activity) {
        if (!this.range) {
            return;
        }

        if (activity !== "move" && activity !== "attack") {
            this.range.setVisible(false);
            return;
        }

        this.range.setVisible(true);

        this.range.setSpriteFrame(activity === "attack" ? bundles.debug_info.frames.unit_range_attack : bundles.debug_info.frames.unit_range);
    }
});