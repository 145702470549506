/**
 * Created by mac on 9/16/22
 */

var Army = function () {
    this.heroes = {};
    this.selected = undefined;

    var data = cleverapps.dataLoader.load(DataLoader.TYPES.ARMY);

    this.load(data);
};

Army.prototype.getInfo = function () {
    return {
        heroes: this.heroes,
        selected: this.selected,
        barracks: cleverapps.barracks.getInfo(),
        formation: cleverapps.formation.getInfo()
    };
};

Army.prototype.save = function (fromServer) {
    cleverapps.dataLoader.save(DataLoader.TYPES.ARMY, this.getInfo());

    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("army");
    }
};

Army.prototype.load = function (data) {
    data = data || {
        heroes: {},
        barracks: [],
        formation: []
    };

    this.heroes = data.heroes;
    this.selected = data.selected;

    cleverapps.barracks.load(data.barracks);

    cleverapps.formation.load(data.formation);
};

Army.prototype.updateInfo = function (serverData) {
    this.load(serverData);
    this.save(true);
};