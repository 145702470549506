/**
 * Created by vladislav on 30/03/2023
 */

var Teleport = function (warrior, options) {
    Skill.call(this, warrior, options);
};

Teleport.prototype = Object.create(Skill.prototype);
Teleport.prototype.constructor = Teleport;

Teleport.prototype.canUse = function () {
    return !this.used;
};

Teleport.prototype.getActivity = function (targetData) {
    this.used = true;

    return new TeleportActivity(this.warrior, targetData.target, this.options.firePercent);
};