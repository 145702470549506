/**
 * Created by vladislav on 22/03/2023
 */

var TroopWindow = Window.extend({
    onWindowLoaded: function (code) {
        this.code = code;
        this.stage = 0;

        this.page = 0;

        this._super({
            name: "TroopWindow",
            title: "Units." + code + ".name",
            content: this.createContent()
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.TroopWindow;

        var top = this.createTop();
        this.infoBlock = this.createInfoBlock();

        var content = new cleverapps.Layout([top, this.infoBlock], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        top.y = top.height / 2;

        return content;
    },

    createArrows: function () {
        var styles = cleverapps.styles.TroopWindow;

        var nextArrow = new cc.Sprite(bundles.troop_window.frames.next_arrow);
        var nextText = cleverapps.UI.generateOnlyText("TroopWindow.nextLevel", cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
        
        var next = new cleverapps.Layout([nextText, nextArrow], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.textMargin
        });

        var prevArrow = new cc.Sprite(bundles.troop_window.frames.next_arrow);
        prevArrow.setScaleX(-1);
        var prevText = cleverapps.UI.generateOnlyText("TroopWindow.prevLevel", cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);

        var prev = new cleverapps.Layout([prevArrow, prevText], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.textMargin
        });

        cleverapps.UI.applyHover(next);
        cleverapps.UI.onClick(next, this.onArrowClick.bind(this, 1));

        cleverapps.UI.applyHover(prev);
        cleverapps.UI.onClick(prev, this.onArrowClick.bind(this, -1));

        return new cleverapps.Layout([prev, next], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.arrows.margin
        });
    },

    onArrowClick: function (inc) {
        var stages = WarriorLines[this.code].line.length;

        this.stage = (this.stage + inc + stages) % stages;

        var position = this.scroll.getPosition();
        var parent = this.scroll.getParent();
        this.scroll.removeFromParent();
        this.scroll = this.createScroll();
        parent.addChild(this.scroll);
        this.scroll.setPositionRound(position);

        this.stageText.setString("TroopWindow.stage", {
            stage: this.stage + 1
        });

        if (this.changeAnimation) {
            this.changeAnimation.removeFromParent();
        }

        this.changeUnitAnimation();
    },

    changeUnitAnimation: function () {
        var styles = cleverapps.styles.TroopWindow;

        var animation = new cleverapps.Spine(bundles.troop_window.jsons.change_level_spark_json);
        animation.setLocalZOrder(2);
        this.unitView.parent.addChild(animation);
        animation.setPositionRound(styles.changeAnimation);
        animation.setAnimation(0, "animation", false);
        animation.setCompleteListenerRemove();
        this.changeAnimation = animation;

        var baseScale = this.unitBaseScale;
        var basePosition = this.unitBasePosition;

        this.circleAnimation.setAnimationAndIdleAfter("animation", "idle");

        this.unitView.stopAllActions();
        this.unitView.setScale(baseScale);
        this.unitView.setPositionRound(basePosition);
        this.unitView.setOpacity(255);
        this.unitView.setVisible(true);
        this.unitView.runAction(new cc.Sequence(
            new cc.DelayTime(0.13),
            new cc.Spawn(
                new cc.MoveTo(0.13, basePosition.x, basePosition.y + styles.changeAnimation.dy),
                new cc.ScaleTo(0.13, baseScale * 0.6, baseScale * 1.4),
                new cc.FadeTo(0.13, 220)
            ),
            new cc.Hide(),
            new cc.DelayTime(0.2),
            new cc.CallFunc(function () {
                var unitView = WarriorView.getUnitImage(WarriorLines[this.code], this.stage);
                this.unitView.parent.addChild(unitView);
                unitView.setAnchorPoint(0.5, 0);
                unitView.setPositionRound(this.unitView.getPosition());
                unitView.setOpacity(this.unitView.opacity);
                unitView.setScale(this.unitView.scaleX, this.unitView.scaleY);

                this.unitView.removeFromParent();
                this.unitView = unitView;
                unitView.runAction(new cc.Sequence(
                    new cc.Show(),
                    new cc.Spawn(
                        new cc.MoveTo(0.1, basePosition),
                        new cc.ScaleTo(0.1, baseScale),
                        new cc.FadeTo(0.13, 255)
                    )
                ));
            }.bind(this))
        ));
    },

    createTop: function () {
        var styles = cleverapps.styles.TroopWindow;

        var descBg = cleverapps.UI.createScale9Sprite(bundles.windows.frames.windows_details_bg_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
        descBg.setContentSize2(styles.description.bg);

        this.unitView = WarriorView.getUnitImage(WarriorLines[this.code], this.stage);
        this.unitView.setScale(1.5);
        this.unitView.setAnchorPoint(0.5, 0);
        this.unitBaseScale = this.unitView.scale;
        descBg.addChild(this.unitView);
        this.unitView.setPositionRound(styles.description.unit);
        this.unitView.setLocalZOrder(1);

        this.circleAnimation = new cleverapps.Spine(bundles.troop_window.jsons.troop_circle_json);
        descBg.addChild(this.circleAnimation);
        this.circleAnimation.setAnimation(0, "idle", true);
        this.circleAnimation.setPositionRound(this.unitView.getPosition());

        var descText = cleverapps.UI.generateOnlyText("Units." + this.code + ".description", cleverapps.styles.FONTS.SMALL_WHITE_TEXT);
        descText.setDimensions(styles.description.text.width, 0);
        descText.fitTo(undefined, styles.description.text.height);
        descText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        this.stageText = cleverapps.UI.generateOnlyText("TroopWindow.stage", cleverapps.styles.FONTS.WHITE_TEXT, {
            stage: this.stage + 1
        });

        var textsLayout = new cleverapps.Layout([this.stageText, descText], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.textMargin
        });
        descBg.addChild(textsLayout);
        textsLayout.setPositionRound(styles.description.texts);

        var raceIcon = new cc.Sprite(bundles.race_icons.frames["race_" + WarriorLines[this.code].base.race]);
        descBg.addChild(raceIcon);
        raceIcon.setPositionRound(styles.description.raceIcon);

        var sourceText = cleverapps.UI.generateOnlyText("Source.normalCards", cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
        sourceText.setDimensions(styles.sourceText.width, 0);
        sourceText.fitTo(undefined, styles.sourceText.height);
        sourceText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        var layout = new cleverapps.Layout([descBg, sourceText], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.top.margin
        });

        this.unitBasePosition = this.unitView.getPosition();

        return layout;
    },

    createScroll: function () {
        var styles = cleverapps.styles.TroopWindow;

        var statsBlock = this.createStatsBlock();
        var pages = [statsBlock];

        var skills = WarriorLines[this.code].line[this.stage].skills;
        if (skills) {
            for (var name in skills) {
                if (skills[name].showInWindow !== false) {
                    pages.push(this.createSkillBlock(skills[name].name, statsBlock.getContentSize()));
                }
            }
        }

        pages.forEach(function (page) {
            cleverapps.UI.wrapWithPadding(page, styles.stats.padding, true);
        });

        return new SnapScrollView(pages, {
            direction: cleverapps.UI.ScrollView.DIR_HORIZONTAL,
            scrollBarEnabled: false,
            page: this.page,
            onChangePage: function (page) {
                this.points[this.page].setSpriteFrame(bundles.troop_window.frames.page_off_icon);
                this.points[page].setSpriteFrame(bundles.troop_window.frames.page_on_icon);

                this.page = page;
            }.bind(this)
        });
    },

    createSkillBlock: function (skillName, size) {
        var styles = cleverapps.styles.TroopWindow;

        var icon = new cc.Sprite(bundles.troop_window.frames["skill_icon_" + skillName]);

        var name = cleverapps.UI.generateOnlyText("Skill.name." + skillName, cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);

        var desc = cleverapps.UI.generateOnlyText("Skill.description." + skillName, cleverapps.styles.FONTS.STAT_NAME_TEXT);
        desc.setHorizontalAlignment(cc.TEXT_ALIGNMENT_LEFT);
        desc.setDimensions(styles.skill.desc.width, 0);

        var textLayout = new cleverapps.Layout([name, desc], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.textMargin,
            align: cleverapps.UI.ALIGN_START
        });

        var skillLayout = new cleverapps.Layout([icon, textLayout], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.textMargin
        });

        var stats = this.createStatsGroup(undefined, LinesHelper.listSkillStats(this.code, this.stage, skillName));

        var title = cleverapps.UI.generateOnlyText("Stats.title.skill", cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);

        var layout = new cleverapps.Layout([title, skillLayout, stats], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.skill.margin
        });

        skillLayout.setPositionRound(skillLayout.width / 2, skillLayout.y);

        var wrap = new cc.Node();
        wrap.setAnchorPoint(0.5, 0.5);
        wrap.setContentSize2(size);

        wrap.addChild(layout);
        layout.setPositionRound(wrap.width / 2, wrap.height - layout.height / 2);

        return wrap;
    },

    createInfoBlock: function () {
        var styles = cleverapps.styles.TroopWindow;

        this.scroll = this.createScroll();

        var bg = new cc.Scale9Sprite(bundles.windows.frames.windows_group_bg_png);
        bg.setContentSize2(this.scroll.getContentSize());
        bg.addChild(this.scroll);
        this.scroll.setPositionRound(bg.width / 2, bg.height / 2);

        if (this.scroll.getPagesAmount() > 1) {
            var pagePoints = this.createPoints(this.scroll.getPagesAmount());
            bg.addChild(pagePoints);
            pagePoints.setPositionRound(styles.points);
        }

        var arrows = this.createArrows();

        return new cleverapps.Layout([bg, arrows], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.infoBlock.margin
        });
    },

    createPoints: function (amount) {
        var styles = cleverapps.styles.TroopWindow;

        this.points = [];
        for (var i = 0; i < amount; i++) {
            var image = this.page === i ? bundles.troop_window.frames.page_on_icon : bundles.troop_window.frames.page_off_icon;

            this.points.push(new cc.Sprite(image));
        }
        return new cleverapps.Layout(this.points.map(function (point) {
            var node = new cc.Node();
            node.setContentSize2(styles.points);
            node.setAnchorPoint(0.5, 0.5);
            node.addChild(point);
            point.setPositionRound(node.width / 2, node.height / 2);
            return node;
        }), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.points.margin
        });
    },

    createStatsBlock: function () {
        var styles = cleverapps.styles.TroopWindow;

        var basicStats = this.createStatsGroup("Stats.title.basic", LinesHelper.listBasicStats(this.code, this.stage));
        var attackStats = this.createStatsGroup("Stats.title.normalAttack", LinesHelper.listAttackStats(this.code, this.stage));

        return new cleverapps.Layout([basicStats, attackStats], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.textMargin
        });
    },

    createStatsGroup: function (title, stats) {
        var styles = cleverapps.styles.TroopWindow;

        var icons = stats.map(this.createStat.bind(this));

        var grid = new cleverapps.GridLayout(icons, {
            columns: 2,
            margin: styles.grid.margin
        });

        if (!title) {
            return grid;
        }

        var titleText = cleverapps.UI.generateOnlyText(title, cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);

        return new cleverapps.Layout([titleText, grid], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.textMargin
        });
    },

    createStat: function (stat) {
        var type = stat.type;
        var value = stat.value;
        var baseValue = stat.baseValue;

        var diff = value - baseValue;

        if (stat.valueType === "percent") {
            value = Math.round((value - 1) * 100) + "%";
            baseValue = Math.round((baseValue - 1) * 100) + "%";
            if (diff !== 0) {
                diff = (diff < 0 ? "-" : "+") + Math.round(diff * 100) + "%";
            }
        } else if (diff) {
            diff = (diff < 0 ? "-" : "+") + Math.abs(diff);
        }

        var styles = cleverapps.styles.TroopWindow;

        var bgImage = diff ? bundles.troop_window.frames.stats_bg_green : bundles.troop_window.frames.stats_bg;
        var bg = cleverapps.UI.createScale9Sprite(bgImage, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize(styles.stat);

        var image = bundles.troop_window.frames["icon_" + type + "_" + value] || bundles.troop_window.frames["icon_" + type];
        var icon = new cc.Sprite(image);

        bg.addChild(icon);
        icon.setPositionRound(styles.stat.icon);

        var typeText = this.createTypeText(type, value);
        bg.addChild(typeText);

        var valueText = this.createValueText(type, value, baseValue);
        bg.addChild(valueText);

        if (diff) {
            var diffText = this.createDiffText(diff);
            bg.addChild(diffText);
            diffText.setPositionRound(valueText.x + valueText.width / 2 + diffText.width / 2 + styles.textMargin, valueText.y);
        }

        return bg;
    },

    createDiffText: function (diff) {
        var diffText;
        if (cleverapps.UI.ImageFont.IsApplicable(cleverapps.styles.FONTS.STAT_DIFF_TEXT, diff)) {
            diffText = cleverapps.UI.generateImageText(diff, cleverapps.styles.FONTS.STAT_DIFF_TEXT);
        } else {
            diffText = cleverapps.UI.generateOnlyText(diff, cleverapps.styles.FONTS.STAT_DIFF_TEXT);
        }

        return diffText;
    },

    createValueText: function (type, value, baseValue) {
        var styles = cleverapps.styles.TroopWindow;

        var valueMsg = Messages.has("Stats." + type + "." + value)
            ? "Stats." + type + "." + value
            : baseValue;

        var valueText;
        if (cleverapps.UI.ImageFont.IsApplicable(cleverapps.styles.FONTS.WINDOW_SMALL_TEXT, valueMsg)) {
            valueText = cleverapps.UI.generateImageText(valueMsg, cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
        } else {
            valueText = cleverapps.UI.generateOnlyText(valueMsg, cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
        }

        valueText.fitTo(styles.stat.textWidth);
        valueText.setPositionRound(styles.stat.value);

        return valueText;
    },

    createTypeText: function (type, value) {
        var styles = cleverapps.styles.TroopWindow;

        var typeMsg = Messages.has("StatsName." + type + "." + value)
            ? "StatsName." + type + "." + value
            : "StatsName." + type;

        var typeText = cleverapps.UI.generateOnlyText(typeMsg, cleverapps.styles.FONTS.STAT_NAME_TEXT);
        typeText.fitTo(styles.stat.textWidth);
        typeText.setPositionRound(styles.stat.type);

        return typeText;
    },

    listBundles: function () {
        return ["troop_window"];
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    STAT_NAME_TEXT: {
        name: "default",
        size: 25,
        color: new cc.Color(199, 124, 100, 255)
    },

    STAT_DIFF_TEXT: {
        name: "nostroke",
        size: 30,
        color: new cc.Color(71, 209, 71, 255),
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.TroopWindow = {
    margin: 30,

    textMargin: 10,

    changeAnimation: {
        dy: 200,

        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -70 }
    },

    top: {
        margin: 15
    },

    unit: {
        offsetY: 30
    },

    arrows: {
        margin: 280,
        next: {
            x: { align: "right", dx: 20 },
            y: { align: "bottom", dy: 0 }
        },

        prev: {
            x: { align: "left", dx: -20 },
            y: { align: "bottom", dy: 0 }
        }
    },

    grid: {
        margin: {
            x: 10,
            y: 10
        }
    },

    infoBlock: {
        margin: 10
    },

    stat: {
        width: 360,
        height: 80,

        textWidth: 260,

        icon: {
            x: { align: "left", anchor: "center", dx: 38 },
            y: { align: "center", dy: 0 }
        },

        type: {
            x: { align: "left", dx: 80 },
            y: { align: "top", dy: -3 }
        },

        value: {
            x: { align: "left", dx: 80 },
            y: { align: "bottom", dy: 3 }
        }
    },

    stats: {
        padding: {
            x: 30,
            top: 30,
            bottom: 80
        }
    },

    skill: {
        margin: 20,
        desc: {
            width: 500
        }
    },

    points: {
        margin: 10,

        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 30 },

        width: 20,
        height: 20
    },

    description: {
        bg: {
            width: 600,
            height: 800
        },
        text: {
            width: 400,
            height: 140
        },
        texts: {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 70 }
        },
        unit: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 70 }
        },
        raceIcon: {
            x: { align: "right", dx: -30 },
            y: { align: "bottom", dy: 70 }
        }
    },

    sourceText: {
        width: 400,
        height: 100
    }
};
