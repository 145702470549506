/**
 * Created by mac on 9/18/22
 */

var WarriorLines = {};
WarriorLines.CODES = {};

WarriorLines.CODES.INFANTRY = 0;
WarriorLines.CODES.ARCHERS = 1;
WarriorLines.CODES.IRON_GUARD = 2;
WarriorLines.CODES.BOMBER = 3;
WarriorLines.CODES.CATAPULT = 4;
WarriorLines.CODES.HELL_JAILERS = 5;
WarriorLines.CODES.FIRE_MAGE = 6;
WarriorLines.CODES.BANDITS = 7;
WarriorLines.CODES.OGRE_WARRIOR = 8;
WarriorLines.CODES.GHOST_ASSASSINS = 9;
WarriorLines.CODES.MAGIC_APPRENTICE = 10;
WarriorLines.CODES.VIKING_WARRIOR = 11;
// WarriorLines.CODES.DEMON = 10;
// WarriorLines.CODES.BEAST_MASTER = 11;
// WarriorLines.CODES.NECROMANCER = 8;
// WarriorLines.CODES.PILGRIMS = 9;

WarriorLines.RARITY = {};
WarriorLines.RARITY.COMMON = 0;
WarriorLines.RARITY.RARE = 1;
WarriorLines.RARITY.EPIC = 2;
WarriorLines.RARITY.LEGENDARY = 3;

WarriorLines.RACE = {};
WarriorLines.RACE.HUMAN = 0;
WarriorLines.RACE.WILD = 1;
WarriorLines.RACE.SACRED = 2;
WarriorLines.RACE.DARK = 3;

WarriorLines.ATTACK = {};
WarriorLines.ATTACK.MELEE = 1;
WarriorLines.ATTACK.RANGED = 2;

WarriorLines.RANGE = {};
WarriorLines.RANGE.CLOSE = "close";
WarriorLines.RANGE.MEDIUM = "medium";
WarriorLines.RANGE.INFINITY = "infinity";

WarriorLines.SHAPE = {};
WarriorLines.SHAPE.SINGLE = 1;
WarriorLines.SHAPE.CIRCLE = 2;
WarriorLines.SHAPE.RECT = 3;

WarriorLines.PROJECTILE = {};
WarriorLines.PROJECTILE.ARROW = 1;
WarriorLines.PROJECTILE.BOMB = 2;
WarriorLines.PROJECTILE.SIMPLE = 3;

WarriorLines.DURATION = {};
WarriorLines.DURATION.INFINITY = 1;

WarriorLines.APPLICATION_POINT = {};
WarriorLines.APPLICATION_POINT.DEFAULT = 1;
WarriorLines.APPLICATION_POINT.FROM_CENTER = 2;

WarriorLines.TARGET_SELECTION = {};
WarriorLines.TARGET_SELECTION.CLOSEST = 1;
WarriorLines.TARGET_SELECTION.RANDOM = 2;
WarriorLines.TARGET_SELECTION.FARTHEST = 3;
WarriorLines.TARGET_SELECTION.SELF = 4;

WarriorLines[WarriorLines.CODES.INFANTRY] = LinesHelper.createLine(10, {
    rarity: WarriorLines.RARITY.COMMON,
    race: WarriorLines.RACE.HUMAN,
    speed: 5.2,
    hp: [800, 1440, 2590, 4660, 8390, 15100, 27180, 48925, 88065, 158515],
    def: [30, 35, 40, 50, 60, 70, 85, 100, 120, 145],
    load: [1],
    available: {
        level: 0
    },
    normalAttack: {
        type: WarriorLines.ATTACK.MELEE,
        range: WarriorLines.RANGE.CLOSE,
        targetSelection: WarriorLines.TARGET_SELECTION.CLOSEST,
        shape: WarriorLines.SHAPE.SINGLE,
        effects: {
            atk: [130, 220, 375, 640, 1090, 1855, 3155, 5365, 9120, 15505]
        },
        aspd: 1.4,
        asound: "stick"
    }
});

WarriorLines[WarriorLines.CODES.ARCHERS] = LinesHelper.createLine(10, {
    rarity: WarriorLines.RARITY.COMMON,
    race: WarriorLines.RACE.HUMAN,
    speed: 1.2,
    hp: [600, 1080, 1945, 3500, 6300, 11340, 20410, 36740, 66130, 119035],
    def: [20, 25, 30, 35, 40, 50, 60, 70, 85, 100],
    load: [1],
    available: {
        level: 0.33
    },
    normalAttack: {
        arrowRange: 20,
        asound: "arrow",
        aspd: 1,
        type: WarriorLines.ATTACK.RANGED,
        projectile: {
            type: WarriorLines.PROJECTILE.ARROW,
            speed: 2000
        },
        range: WarriorLines.RANGE.INFINITY,
        targetSelection: WarriorLines.TARGET_SELECTION.CLOSEST,
        shape: WarriorLines.SHAPE.SINGLE,
        firePercent: 21,
        effects: {
            atk: [155, 265, 450, 765, 1300, 2210, 3755, 6385, 10855, 18455]
        }
    }
});

WarriorLines[WarriorLines.CODES.IRON_GUARD] = LinesHelper.createLine(10, {
    rarity: WarriorLines.RARITY.COMMON,
    race: WarriorLines.RACE.HUMAN,
    speed: 5.2,
    hp: [1000, 1800, 3240, 5830, 10495, 18890, 34000, 61200, 110160, 198290],
    def: [35, 40, 50, 60, 70, 85, 100, 120, 145, 175],
    load: [1],
    available: {
        level: 1
    },
    normalAttack: {
        asound: "sword",
        aspd: 1.4,
        type: WarriorLines.ATTACK.MELEE,
        range: WarriorLines.RANGE.CLOSE,
        targetSelection: WarriorLines.TARGET_SELECTION.CLOSEST,
        shape: WarriorLines.SHAPE.SINGLE,
        effects: {
            atk: [105, 180, 305, 520, 885, 1505, 2560, 4350, 7395, 12570]
        }
    }
});

WarriorLines[WarriorLines.CODES.BOMBER] = LinesHelper.createLine(10, {
    rarity: WarriorLines.RARITY.COMMON,
    race: WarriorLines.RACE.HUMAN,
    speed: 5.2,
    hp: [500, 900, 1620, 2915, 5245, 9440, 16990, 30580, 55045, 99080],
    def: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
    load: [1],
    available: {
        level: 1.67
    },
    normalAttack: {
        asound: "bomb",
        aspd: 0.9,
        type: WarriorLines.ATTACK.RANGED,
        projectile: {
            type: WarriorLines.PROJECTILE.BOMB,
            speed: 1000
        },
        range: 7,
        targetSelection: WarriorLines.TARGET_SELECTION.CLOSEST,
        shape: WarriorLines.SHAPE.CIRCLE,
        shapeSize: 3,
        firePercent: 30,
        effects: {
            atk: [105, 180, 305, 520, 885, 1505, 2560, 4350, 7395, 12570]
        }
    }
});

WarriorLines[WarriorLines.CODES.CATAPULT] = LinesHelper.createLine(10, {
    rarity: WarriorLines.RARITY.COMMON,
    race: WarriorLines.RACE.HUMAN,
    hp: [1000, 1800, 3240, 5830, 10495, 18890, 34000, 61200, 110160, 198290],
    speed: 1,
    def: [15, 20, 25, 30, 35, 40, 50, 60, 70, 85],
    load: [1],
    available: {
        level: 5.33
    },
    normalAttack: {
        type: WarriorLines.ATTACK.RANGED,
        projectile: {
            type: WarriorLines.PROJECTILE.ARROW,
            speed: 2000
        },
        range: WarriorLines.RANGE.INFINITY,
        targetSelection: WarriorLines.TARGET_SELECTION.CLOSEST,
        shape: WarriorLines.SHAPE.CIRCLE,
        shapeSize: 4,
        firePercent: 2,
        aspd: 0.25,
        asound: "catapult",
        effects: {
            atk: [140, 240, 410, 695, 1180, 2005, 3410, 5795, 9850, 16745]
        }
    },
    skills: {
        extendNormalAttack: {
            name: "burningOil",
            normalAttack: {
                effects: {
                    area: {
                        duration: 3.2,
                        shape: WarriorLines.SHAPE.CIRCLE,
                        shapeSize: 4,
                        effects: {
                            dot: {
                                dmg: [45, 75, 130, 220, 375, 640, 1090, 1855, 3155, 5365],
                                dmgCount: 4,
                                dmgInterval: 60,
                                animation: "burn",
                                sound: "burn"
                            }
                        }
                    }
                }
            }
        }
    }
});

WarriorLines[WarriorLines.CODES.HELL_JAILERS] = LinesHelper.createLine(10, {
    rarity: WarriorLines.RARITY.COMMON,
    race: WarriorLines.RACE.DARK,
    speed: 6,
    hp: [800, 1440, 2590, 4660, 8390, 15100, 27180, 48925, 88065, 158515],
    def: [30, 35, 40, 50, 60, 70, 85, 100, 120, 145],
    load: [1],
    disableWalkSound: true,
    available: {
        level: 10
    },
    normalAttack: {
        asound: "stick",
        aspd: 1.4,
        type: WarriorLines.ATTACK.MELEE,
        range: WarriorLines.RANGE.CLOSE,
        targetSelection: WarriorLines.TARGET_SELECTION.CLOSEST,
        shape: WarriorLines.SHAPE.SINGLE,
        effects: {
            atk: [145, 245, 415, 705, 1200, 2040, 3470, 5900, 10030, 17050]
        }
    }
});

WarriorLines[WarriorLines.CODES.FIRE_MAGE] = LinesHelper.createLine(10, {
    rarity: WarriorLines.RARITY.RARE,
    race: WarriorLines.RACE.HUMAN,
    speed: 5.2,
    arrowRange: 20,
    hp: [600, 1080, 1945, 3500, 6300, 11340, 20410, 36740, 66130, 119035],
    def: [20, 25, 30, 35, 40, 50, 60, 70, 85, 100],
    load: [1],
    available: {
        level: 2.33
    },
    normalAttack: {
        type: WarriorLines.ATTACK.RANGED,
        projectile: {
            type: WarriorLines.PROJECTILE.SIMPLE,
            speed: 2000
        },
        range: WarriorLines.RANGE.INFINITY,
        targetSelection: WarriorLines.TARGET_SELECTION.CLOSEST,
        shape: WarriorLines.SHAPE.CIRCLE,
        shapeSize: 4,
        aspd: 0.9,
        asound: "magician",
        firePercent: 74,
        effects: {
            atk: [130, 220, 375, 640, 1090, 1855, 3155, 5365, 9120, 15505]
        }
    }
});

WarriorLines[WarriorLines.CODES.BANDITS] = LinesHelper.createLine(10, {
    rarity: WarriorLines.RARITY.RARE,
    race: WarriorLines.RACE.HUMAN,
    speed: 5.2,
    hp: [800, 1120, 2010, 2410, 4340, 5860, 10550, 15190, 27340, 41015],
    def: [20, 25, 30, 35, 40, 50, 60, 70, 85, 100],
    load: [1, 2, 2, 3, 3, 4, 4, 5, 5, 6],
    available: {
        level: 3
    },
    normalAttack: {
        asound: "stick",
        aspd: 1.6,
        type: WarriorLines.ATTACK.MELEE,
        range: WarriorLines.RANGE.CLOSE,
        targetSelection: WarriorLines.TARGET_SELECTION.CLOSEST,
        shape: WarriorLines.SHAPE.SINGLE,
        effects: {
            atk: [155, 170, 285, 320, 550, 700, 1190, 1615, 2750, 3895]
        }
    },
    skills: {
        throwingKnives: {
            name: "knifeMan",
            type: WarriorLines.ATTACK.RANGED,
            range: 5,
            targetSelection: WarriorLines.TARGET_SELECTION.CLOSEST,
            shape: WarriorLines.SHAPE.SINGLE,
            projectile: {
                type: WarriorLines.PROJECTILE.SIMPLE,
                speed: 2000
            },
            skillUsages: [3, 3, 4, 4, 5, 5, 6, 6, 6, 6],
            firePercent: 52,
            effects: {
                atk: [280, 475, 810, 1375, 2340, 3980, 6765, 11500, 19550, 33235]
            },
            asound: "knife_man"
        }
    }
});

WarriorLines[WarriorLines.CODES.OGRE_WARRIOR] = LinesHelper.createLine(10, {
    rarity: WarriorLines.RARITY.RARE,
    race: WarriorLines.RACE.WILD,
    speed: 5.2,
    hp: [2000, 3600, 6480, 11665, 20995, 37790, 68020, 122435, 220385, 396695],
    def: [45, 55, 65, 80, 95, 115, 140, 170, 205, 245],
    load: [1],
    available: {
        level: 4
    },
    normalAttack: {
        type: WarriorLines.ATTACK.MELEE,
        range: WarriorLines.RANGE.MEDIUM,
        shape: WarriorLines.SHAPE.SINGLE,
        targetSelection: WarriorLines.TARGET_SELECTION.CLOSEST,
        effects: {
            atk: [260, 440, 750, 1275, 2170, 3690, 6275, 10670, 18140, 30840]
        },
        asound: "club",
        aspd: 1.1
    },
    skills: {
        specialAttack: {
            name: "heavyBash",
            type: WarriorLines.ATTACK.MELEE,
            range: WarriorLines.RANGE.MEDIUM,
            shape: WarriorLines.SHAPE.CIRCLE,
            shapeSize: 4,
            applicationPoint: WarriorLines.APPLICATION_POINT.FROM_CENTER,
            firePercent: 40,
            effects: {
                atk: [195, 330, 560, 950, 1615, 2745, 4665, 7930, 13480, 22915],
                knockback: 5
            },
            triggerNumber: [2, 2, 2, 2, 2, 1, 1, 1, 1, 1],
            asound: "heavy_bash"
        }
    }
});

WarriorLines[WarriorLines.CODES.GHOST_ASSASSINS] = LinesHelper.createLine(10, {
    rarity: WarriorLines.RARITY.RARE,
    race: WarriorLines.RACE.DARK,
    speed: 5.2,
    hp: [700, 1260, 2270, 4085, 7355, 13240, 23830, 42895, 77210, 138980],
    def: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
    load: [1],
    available: {
        level: 4.67
    },
    normalAttack: {
        type: WarriorLines.ATTACK.MELEE,
        range: WarriorLines.RANGE.CLOSE,
        shape: WarriorLines.SHAPE.SINGLE,
        targetSelection: WarriorLines.TARGET_SELECTION.CLOSEST,
        aspd: 1.5,
        effects: {
            atk: [195, 330, 560, 950, 1615, 2745, 4665, 7930, 13480, 22915]
        },
        asound: "dagger"
    },
    skills: {
        teleport: {
            name: "teleport",
            showInWindow: false,
            firePercent: 50,
            targetSelection: WarriorLines.TARGET_SELECTION.FARTHEST,
            range: WarriorLines.RANGE.INFINITY,
            sound: "teleport"
        },

        specialAttack: {
            name: "shadowDagger",
            type: WarriorLines.ATTACK.MELEE,
            range: WarriorLines.RANGE.MEDIUM,
            shape: WarriorLines.SHAPE.CIRCLE,
            shapeSize: 4,
            firePercent: 40,
            effects: {
                atk: [295, 500, 850, 1445, 2455, 4175, 7100, 12070, 20520, 34885]
            },
            triggerNumber: 6,
            asound: "shadow_dagger"
        }
    }
});

WarriorLines[WarriorLines.CODES.MAGIC_APPRENTICE] = LinesHelper.createLine(10, {
    rarity: WarriorLines.RARITY.LEGENDARY,
    race: WarriorLines.RACE.HUMAN,
    speed: 7.2,
    hp: [500, 900, 1620, 2915, 5245, 9440, 16990, 30580, 55045, 99080],
    def: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    load: [1],
    available: {
        level: 6.67
    },
    disableWalkSound: true,
    normalAttack: {
        type: WarriorLines.ATTACK.MELEE,
        range: WarriorLines.RANGE.CLOSE,
        shape: WarriorLines.SHAPE.CIRCLE,
        shapeSize: 3,
        targetSelection: WarriorLines.TARGET_SELECTION.CLOSEST,
        applicationPoint: WarriorLines.APPLICATION_POINT.FROM_CENTER,
        aspd: 1,
        firePercent: 45,
        effects: {
            atk: [145, 245, 415, 705, 1200, 2040, 3470, 5900, 10030, 17050]
        },
        asound: "magic_apprentice"
    },
    skills: {
        flank: {
            name: "flank",
            showInWindow: false,
            speed: 300,
            range: WarriorLines.RANGE.INFINITY,
            targetSelection: WarriorLines.TARGET_SELECTION.FARTHEST
        }
    }
});

WarriorLines[WarriorLines.CODES.VIKING_WARRIOR] = LinesHelper.createLine(10, {
    rarity: WarriorLines.RARITY.LEGENDARY,
    race: WarriorLines.RACE.HUMAN,
    speed: 5,
    hp: [2000, 3600, 6480, 11665, 20995, 37790, 68020, 122435, 220385, 396695],
    def: [30, 35, 40, 50, 60, 70, 85, 100, 120, 145],
    load: [1],
    available: {
        level: 11.67
    },
    normalAttack: {
        type: WarriorLines.ATTACK.MELEE,
        range: WarriorLines.RANGE.CLOSE,
        shape: WarriorLines.SHAPE.SINGLE,
        targetSelection: WarriorLines.TARGET_SELECTION.CLOSEST,
        aspd: 1.4,
        asound: "spear",
        effects: {
            atk: [295, 500, 850, 1445, 2455, 4175, 7100, 12070, 20520, 34885]
        }
    },
    skills: {
        rage: {
            name: "rage",
            targetSelection: WarriorLines.TARGET_SELECTION.SELF,
            buff: {
                duration: WarriorLines.DURATION.INFINITY,
                buffs: {
                    atk: [1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2, 2.2, 2.4],
                    aspd: [1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2.1, 2.3],
                    speed: [1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2.1, 2.3]
                }
            }
        }
    }
});

// WarriorLines[WarriorLines.CODES.NECROMANCER] = LinesHelper.createLine(10, {
//     rarity: WarriorLines.RARITY.EPIC,
//     race: WarriorLines.RACE.WILD,
//     type: WarriorLines.ATTACK.MELEE,
//     range: WarriorLines.RANGE.CLOSE,
//     aspd: 0.35,
//     speed: 5.2
// }, {
//     hp: [1000, 1050],
//     atk: [100, 110],
//     def: [25, 28],
//     load: [1]
// });
//
// WarriorLines[WarriorLines.CODES.PILGRIMS] = LinesHelper.createLine(10, {
//     rarity: WarriorLines.RARITY.EPIC,
//     race: WarriorLines.RACE.HUMAN,
//     type: WarriorLines.ATTACK.RANGED,
//     projectile: WarriorLines.PROJECTILE.ARROW,
//     range: WarriorLines.RANGE.INFINITY,
//     aspd: 1,
//     speed: 5.2,
//     arrowRange: 20
// }, {
//     hp: [900, 950],
//     atk: [100, 110],
//     def: [35, 40],
//     load: [1]
// });
//
// WarriorLines[WarriorLines.CODES.DEMON] = LinesHelper.createLine(10, {
//     rarity: WarriorLines.RARITY.EPIC,
//     race: WarriorLines.RACE.WILD,
//     type: WarriorLines.ATTACK.MELEE,
//     range: WarriorLines.RANGE.CLOSE,
//     aspd: 0.9,
//     speed: 5.2
// }, {
//     hp: [2000, 2100],
//     atk: [220, 230],
//     def: [35, 38],
//     load: [1]
// });
//
// WarriorLines[WarriorLines.CODES.BEAST_MASTER] = LinesHelper.createLine(10, {
//     rarity: WarriorLines.RARITY.LEGENDARY,
//     race: WarriorLines.RACE.WILD,
//     type: WarriorLines.ATTACK.RANGED,
//     range: WarriorLines.RANGE.INFINITY,
//     aspd: 1.1,
//     speed: 5.2,
//     arrowRange: 20
// }, {
//     hp: [2000, 2100],
//     atk: [220, 230],
//     def: [30, 33],
//     load: [1]
// });
// "Units.10.name": "Necromancer",
// "Units.11.name": "Pilgrims",
// "Units.12.name": "Demon",
// "Units.13.name": "Beast Master",
// "Units.10.description": "I'm harmless and won't attack. Why you don't believe me?",
// "Units.11.description": "Strong faith can heal friendly troops.",
// "Units.12.description": "Flies back to the back of enemy troops and releases the unstoppable spell.",
// "Units.13.description": "Salut to a classic action.",

WarriorLines.UNITS = [];

(function () {
    Object.keys(WarriorLines.CODES).forEach(function (name) {
        var id = WarriorLines.CODES[name];

        if (WarriorLines[id]) {
            WarriorLines[id].base.id = id;
            WarriorLines[id].base.name = name.toLowerCase();

            WarriorLines.UNITS.push(WarriorLines[id]);
        }
    });
}());

if (typeof module !== "undefined") {
    module.exports = WarriorLines;
}