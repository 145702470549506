/**
 * Created by vladislav on 13/12/2022
 */

var BattleFieldSizeView = cleverapps.Layout.extend({
    ctor: function () {
        var xBlock = this.createXBlock();
        var yBlock = this.createYBlock();

        var text = cleverapps.UI.generateOnlyText("Size:", cleverapps.styles.FONTS.EDITOR_TEXT);

        var styles = cleverapps.styles.BattleFieldSizeView;

        this._super([text, xBlock, yBlock], {
            margin: styles.margin,
            direction: cleverapps.UI.VERTICAL
        });

        this.setPositionRound(styles);
    },

    createXBlock: function () {
        var block = new IncDecBlock({
            value: Game.currentGame.battlefield.region.width / 2,
            range: [1, Battlefield.GRID.x / 2],
            onChange: function (value) {
                Editor.currentEditor.setSize(value, Game.currentGame.battlefield.region.height);
            },

            radius: cleverapps.styles.BattleFieldSizeView.radius
        });
        cleverapps.UI.wrap(block);

        return block;
    },

    createYBlock: function () {
        var block = new IncDecBlock({
            value: Game.currentGame.battlefield.region.height,
            range: [1, Battlefield.GRID.y],

            onChange: function (value) {
                Editor.currentEditor.setSize(Game.currentGame.battlefield.region.width / 2, value);
            },

            radius: cleverapps.styles.BattleFieldSizeView.radius
        });
        cleverapps.UI.wrap(block);

        return block;
    }
});

cleverapps.styles.BattleFieldSizeView = {
    x: { align: "right", dx: -300 },
    y: { align: "top", dy: 0 },

    margin: 10,

    radius: 70
};