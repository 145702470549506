/**
 * Created by mac on 9/16/22
 */

if (typeof exports !== "undefined") {
    var GameBase = function () {};
}

var Epicart = function (level, options) {
    GameBase.call(this, level, options);

    this.mode = Epicart.MODE_SETUP;

    var enemyFormation = this.savedGame && this.savedGame.enemyFormation || this.level.content.army.formation;
    this.battlefield = new Battlefield(this.level.content.army.size, enemyFormation);

    this.battleStats = new BattleStats();

    cleverapps.Random.seed(Date.now());

    if (cleverapps.config.debugMode && !cleverapps.environment.isEditorScene()) {
        this.orangery = new Orangery(BattlefieldOrangery);
    }

    this.elapsedTime = 0;

    this.tutorial = new BattlefieldTutorial();

    this.targetSelection = new TargetSelection();

    if (Reinforcement.IsAvailable()) {
        this.reinforcement = new Reinforcement(this.battlefield.region);
    }

    this.areas = [];

    this.sounds = {};

    this.counter.registerStage(1, function () {
        this.tutorial.showTutorial();
    }.bind(this));
    if (cleverapps.config.debugMode) {
        cleverapps.flags.on("toggle:debugInfo", this.toggleDebugInfo.bind(this), cc.director.getRunningScene());
        cleverapps.flags.on("toggle:unitBorders", this.toggleUnitBorders.bind(this), cc.director.getRunningScene());
    }
};

Epicart.prototype = Object.create(GameBase.prototype);
Epicart.constructor = Epicart;

if (cleverapps.config.debugMode) {
    Epicart.prototype.toggleDebugInfo = function () {
        var warriors;
        if (this.mode === Epicart.MODE_BATTLE) {
            warriors = [Warrior.TEAM_PLAYER, Warrior.TEAM_ENEMY].map(function (team) {
                return this.teams[team];
            }, this).flat();
        } else {
            warriors = this.battlefield.listSquads().map(function (squad) {
                return squad.listWarriors();
            }).flat();
        }

        warriors.forEach(function (warrior) {
            if (warrior) {
                warrior.onToggleDebugInfo();
            }
        });
    };

    Epicart.prototype.toggleUnitBorders = function () {
        var warriors;
        if (this.mode === Epicart.MODE_BATTLE) {
            warriors = [Warrior.TEAM_PLAYER, Warrior.TEAM_ENEMY].map(function (team) {
                return this.teams[team];
            }, this).flat();
        } else {
            warriors = this.battlefield.listSquads().map(function (squad) {
                return squad.listWarriors();
            }).flat();
        }

        warriors.forEach(function (warrior) {
            if (cleverapps.flags.unitBorders && cleverapps.flags.debugInfo) {
                warrior.onGetView().debugBorder();
            } else {
                warrior.onGetView().cleanupBorder();
            }
        });
    };

    Epicart.prototype.findWarriorForDrag = function (pos) {
        return this.alive[Warrior.TEAM_ANY].sort(function (warriorA, warrioB) {
            return warriorA.y - warrioB.y;
        }).find(function (warrior) {
            var bb = warrior.onGetView().getBoundingBox();
            if (cc.rectContainsPoint(bb, pos)) {
                return warrior;
            }

            return undefined;
        });
    };
}

var Game = Epicart;

Epicart.MODE_SETUP = 0;
Epicart.MODE_BATTLE = 1;

Epicart.KILL_REWARD = 2;

Epicart.VIRTUAL_CELL = {
    width: 193,
    height: 141
};

Epicart.calcArmyPowerRating = function (squads) {
    return squads.reduce(function (acc, squad) {
        return acc + Epicart.calcPowerRating(squad.stage, WarriorLines[squad.code].base.rarity);
    }, 0);
};

Epicart.calcPowerRating = function (stage, rarity) {
    return Math.pow(2, stage) * 100 + Math.pow(2, stage) * 20 * rarity;
};

Epicart.virtualToReal = function () {
    return cleverapps.styles.EpicartScene.cell.width / Epicart.VIRTUAL_CELL.width;
};

Epicart.realToVirtual = function () {
    return 1 / Epicart.virtualToReal();
};

Epicart.prototype.pause = function () {
    if (this.paused) {
        return;
    }

    this.paused = true;

    if (this.reinforcement) {
        this.reinforcement.pauseRecharge();
    }

    for (var sound in this.sounds) {
        cleverapps.audio.pauseSound(this.sounds[sound].instance);
    }

    this.trigger("pause");
};

Epicart.prototype.resume = function () {
    if (!this.paused) {
        return;
    }

    this.paused = false;

    if (this.reinforcement) {
        this.reinforcement.resumeRecharge();
    }

    for (var sound in this.sounds) {
        cleverapps.audio.resumeSound(this.sounds[sound].instance);
    }

    this.trigger("resume");
};

Epicart.prototype.getInfo = function () {
    var info = GameBase.prototype.getInfo.call(this);

    info.enemyFormation = this.battlefield.listSquads(Warrior.TEAM_ENEMY).map(function (squad) {
        return {
            code: squad.code,
            stage: squad.stage,
            x: squad.x,
            y: squad.y
        };
    });

    return info;
};

Epicart.prototype.buttonPressed = function (f) {
    this.commence();

    this.userStatus.reportUserAction();

    this.battle(f);
};

Epicart.SMALL_FORMATIONS = [
    [
        "x"
    ],
    [
        "x",
        "x"
    ],
    [
        ".x.",
        "x.x"
    ],
    [
        "xx",
        "xx"
    ],
    [
        ".x.",
        "xxx",
        ".x."
    ],
    [
        "xx",
        "xx",
        "xx"
    ],
    [
        ".xx",
        "xxx",
        ".xx"
    ],
    [
        "xxx",
        ".xx",
        "xxx"
    ],
    [
        "xxx",
        "xxx",
        "xxx"
    ]
];

Epicart.prototype.listAffectedUnits = function (shape, shapeSize, pos, team, head) {
    var units = [];
    switch (shape) {
        case WarriorLines.SHAPE.SINGLE:
            var res = Game.currentGame.targetSelection.closestEnemy(pos, team, head);
            if (res) {
                var target = res.target;
                var distance = res.distance;

                if (distance <= 20) {
                    units.push(target);
                }
            }
            break;
        case WarriorLines.SHAPE.CIRCLE:
            this.alive[team].forEach(function (unit) {
                if (unit) {
                    var dist = cc.pDistance(pos, unit);
                    if (dist < shapeSize / 2 * Epicart.DISTANCE_MULTIPLIER + unit.getRadius()) {
                        units.push(unit);
                    }
                }
            }, this);
            break;
    }

    return units;
};

Epicart.prototype.applyAttack = function (warrior, attack, pos, head) {
    this.applyEffects(attack.shape, attack.shapeSize, attack.effects, pos, head, warrior, attack);
};

Epicart.prototype.applyEffects = function (shape, shapeSize, effects, pos, head, warrior, source) {
    var affected = this.listAffectedUnits(shape, shapeSize, pos, warrior.otherTeam(), head);

    affected.forEach(function (unit) {
        for (var effect in effects) {
            var value = warrior.getAttribute(effect, effects[effect]);

            if (effect === "atk") {
                unit.receiveDamage(value, head, warrior, source);
            } else if (effect === "knockback") {
                unit.applyKnockback(value, pos, warrior, source);
            } else if (effect !== "area") {
                unit.applyEffect(effect, effects[effect], warrior, source);
            }
        }
    });

    if (effects.area) {
        this.spawnArea(pos, effects.area, warrior, source);
    }
};

Epicart.prototype.spawnArea = function (pos, options, warrior, source) {
    var area = new Area(pos, options.duration, options.shape, options.shapeSize, options.effects, warrior, source);

    this.areas.push(area);

    this.battlefield.trigger("spawnArea", area);
};

Epicart.hashPosition = function (warrior, team, head) {
    return (warrior.x / 20) + (warrior.y / 20) * 10 + 100 * (team * 2 + head);
};

Epicart.distance = function (a, b) {
    return Math.sqrt((a.x - b.x) * (a.x - b.x) + (a.y - b.y) * (a.y - b.y));
};

Epicart.TICK = 1 / 60;

Epicart.prototype.battle = function (f) {
    this.onBattleFinished = f;

    this.teams = [];
    this.teams[Warrior.TEAM_PLAYER] = [];
    this.teams[Warrior.TEAM_ENEMY] = [];

    this.battlefield.deploy(this.teams[Warrior.TEAM_PLAYER], this.teams[Warrior.TEAM_ENEMY]);

    this.alive = [];
    this.alive[Warrior.TEAM_PLAYER] = this.teams[Warrior.TEAM_PLAYER].slice();
    this.alive[Warrior.TEAM_ENEMY] = this.teams[Warrior.TEAM_ENEMY].slice();
    this.alive[Warrior.TEAM_ANY] = this.alive[Warrior.TEAM_PLAYER].concat(this.alive[Warrior.TEAM_ENEMY]);

    this.tickCounter = 0;

    this.mode = Epicart.MODE_BATTLE;

    cleverapps.audio.playSound(bundles.units.urls.battle_effect);

    this.battlefield.trigger("hideGrid");

    cleverapps.meta.compound(f, [
        this.introZoom.bind(this),
        function () {
            this.trigger("startBattle");

            if (this.reinforcement) {
                this.reinforcement.startRecharge();
            }

            this.counter.trigger();
        }.bind(this)
    ]);
};

Epicart.prototype.introZoom = function (f, silent) {
    var scene = cc.director.getRunningScene();

    if (this.outcome === GameBase.OUTCOME_GAVEUP) {
        f();
        return;
    }

    var duration = silent ? 0 : 0.8;
    var scale = this.outcome === GameBase.OUTCOME_UNKNOWN ? 1 : 1 / scene.introScale;

    scene.animateZoom(scale, duration, f);
};

Epicart.prototype.isBattleStopped = function () {
    return this.battleStopped;
};

Epicart.prototype.stopBattle = function () {
    this.battleStopped = true;

    var isWin = this.alive[Warrior.TEAM_ENEMY].length === 0;

    this.addSoftReward(isWin ? 15 : 5);

    if (this.reinforcement) {
        this.reinforcement.stopRecharge();
    }

    this.tutorial.stopTutorial();

    this.setTimeout(function () {
        this.onBattleFinished();
        delete this.onBattleFinished;

        if (isWin) {
            this.win();
        } else {
            this.lose();
        }
    }.bind(this), 800);
};

Epicart.prototype.stop = function () {
    GameBase.prototype.stop.apply(this, arguments);

    for (var sound in this.sounds) {
        cleverapps.audio.stopSound(this.sounds[sound].instance);
    }
};

Epicart.prototype.animateBeforeWin = function (f) {
    if (this.outcome === GameBase.OUTCOME_LOST) {
        cleverapps.audio.playSound(bundles.units.urls.lose_effect);
    }

    if (this.outcome !== GameBase.OUTCOME_VICTORY) {
        f();
        return;
    }

    cleverapps.audio.playSound(bundles.units.urls.hurray_effect);

    setTimeout(f, 1000);
};

Epicart.prototype.aliveWarriorsAmount = function (team) {
    if (this.mode === Epicart.MODE_SETUP) {
        return this.battlefield.listSquads(team).reduce(function (sum, squad) {
            return sum + squad.getAliveAmount();
        }, 0);
    }

    return this.alive[team].length;
};

Epicart.prototype.update = function (dt) {
    if (this.mode !== Epicart.MODE_BATTLE) {
        return;
    }

    this.elapsedTime += dt;
    if (this.elapsedTime >= Epicart.TICK) {
        this.battleTick();
        this.elapsedTime -= Epicart.TICK;
    }
};

Epicart.prototype.startSound = function (sound) {
    if (!this.sounds[sound]) {
        this.sounds[sound] = {
            count: 0
        };
    }

    this.sounds[sound].count++;
    if (this.sounds[sound].count === 1) {
        this.sounds[sound].instance = cleverapps.audio.playSound(sound, {
            loop: true
        });
    }
};

Epicart.prototype.stopSound = function (sound) {
    this.sounds[sound].count--;
    if (this.sounds[sound].count === 0) {
        cleverapps.audio.stopSound(this.sounds[sound].instance);
        delete this.sounds[sound];
    }
};

Epicart.prototype.processAreas = function () {
    var finishedAreas = [];
    this.areas = this.areas.filter(function (area) {
        var isFinished = area.isFinished();
        if (isFinished) {
            finishedAreas.push(area);
        }
        return !isFinished;
    });

    finishedAreas.forEach(function (area) {
        area.onFinish();
    });

    this.areas.forEach(function (area) {
        area.onTick();
    });
};

Epicart.prototype.battleTick = function () {
    this.tickCounter++;

    var alive = [];
    alive[Warrior.TEAM_PLAYER] = [];
    alive[Warrior.TEAM_ENEMY] = [];
    alive[Warrior.TEAM_ANY] = [];

    [Warrior.TEAM_PLAYER, Warrior.TEAM_ENEMY].forEach(function (team) {
        this.teams[team].forEach(function (unit) {
            if (unit) {
                unit.resetForce();
                unit.tick(this);

                if (!unit.dead) {
                    alive[unit.team].push(unit);
                    alive[Warrior.TEAM_ANY].push(unit);
                }
            }
        }, this);
    }, this);

    this.alive = alive;

    if ((this.alive[Warrior.TEAM_ENEMY].length === 0 || this.alive[Warrior.TEAM_PLAYER].length === 0)
        && !this.battleStopped && !cleverapps.flags.endlessBattle) {
        this.stopBattle();
    }

    if (this.tickCounter % 20 === 0) {
        [Warrior.TEAM_PLAYER, Warrior.TEAM_ENEMY].forEach(function (team) {
            this.teams[team] = this.teams[team].filter(function (unit) {
                return !unit.needsRemoval;
            });
        }, this);
    }

    this.processAreas();

    this.alive[Warrior.TEAM_ANY].forEach(function (unit) {
        this.calcForceForUnit(unit);
    }, this);

    this.alive[Warrior.TEAM_ANY].forEach(function (unit) {
        unit.move(unit.force);
    });

    if (cleverapps.config.debugMode) {
        this.alive[Warrior.TEAM_ANY].forEach(function (unit) {
            unit.onUpdateDebugSize();
        });
    }
};

Epicart.prototype.calcForceForUnit = function (unit) {
    var pushers = [];

    if (!unit.ignoresCollision) {
        this.alive[Warrior.TEAM_ANY].forEach(function (other) {
            if (other === unit) {
                return;
            }

            if (other.ignoresCollision) {
                return;
            }

            if (unit.x === other.x && unit.y === other.y) {
                unit.x -= 1;
                unit.y -= 1;
                other.x += 1;
                other.y += 1;
            }

            var force = unit.calcPushForce(other);
            if (force) {
                unit.addForce(force);
                pushers.push(other);
                unit.isBeingPushed = true;
            }
        }, this);
    }

    unit.processAppliedForces();

    var forceFromBorders = this.calcForceFromBorders(unit);
    if (forceFromBorders) {
        unit.addForce(forceFromBorders);
        unit.isBeingPushed = true;
    }

    if (unit.moveVector.x !== 0 || unit.moveVector.y !== 0) {
        unit.addForce(unit.calcMoveForce(pushers));
    }
};

Epicart.prototype.calcForceFromBorders = function (unit) {
    if (unit.dragged) {
        return undefined;
    }

    var borderPoints = [];
    if (unit.x < this.battlefield.borders.x) {
        borderPoints.push(cc.p(this.battlefield.borders.x, unit.y));
    }

    if (unit.x > this.battlefield.borders.width) {
        borderPoints.push(cc.p(this.battlefield.borders.width, unit.y));
    }

    if (unit.y < this.battlefield.borders.y) {
        borderPoints.push(cc.p(unit.x, this.battlefield.borders.y));
    }

    if (unit.y > this.battlefield.borders.height) {
        borderPoints.push(cc.p(unit.x, this.battlefield.borders.height));
    }

    if (borderPoints.length === 0) {
        return;
    }

    return borderPoints.reduce(function (res, point) {
        var distanceSQ = cc.pDistanceSQ(unit, point);
        var force = cc.pMult(
            cc.pNormalize(cc.p(point.x - unit.x, point.y - unit.y)),
            distanceSQ * Epicart.PUSH_FROM_BORDERS_FORCE
        );

        return cc.pAdd(res, force);
    }, cc.p(0, 0));
};

Epicart.PUSH_FROM_BORDERS_FORCE = 0.001;
Epicart.DISTANCE_MULTIPLIER = 100;

Epicart.MERGE_AVAILABLE = {
    level: 0.07
};

if (typeof exports !== "undefined") {
    module.exports = {
        calcPowerRating: Epicart.calcPowerRating
    };
}
