/**
 * Created by vladislav on 27/10/2022
 */

var BattleDetailsTab = cleverapps.Layout.extend({
    ctor: function (type) {
        this.type = type;

        var styles = cleverapps.styles.BattleDetailsTab;

        var text = cleverapps.UI.generateOnlyText("BattleStats." + type, cleverapps.styles.FONTS.WINDOW_TEXT);

        var scroll = this.createScroll();

        this._super([text, scroll], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createScroll: function () {
        var styles = cleverapps.styles.BattleDetailsTab;

        var icons = Game.currentGame.battlefield.listSquads(Warrior.TEAM_PLAYER).map(function (squad) {
            return new BattleStatIcon(squad, this.type);
        }, this);

        var grid = new cleverapps.GridLayout(icons, {
            columns: 7,
            margin: styles.scroll.margin,
            padding: styles.scroll.padding
        });

        var gridContainer = new cc.Node();
        gridContainer.addChild(grid);
        gridContainer.setContentSize2(
            styles.scroll.width,
            Math.max(grid.height, styles.scroll.height)
        );
        grid.setPositionRound({ align: "left" }, { align: "top" });

        var scroll = new cleverapps.UI.ScrollView(gridContainer, {
            direction: cleverapps.UI.ScrollView.DIR_VERTICAL
        });
        scroll.setContentSize2(styles.scroll);

        return scroll;
    }
});

cleverapps.styles.BattleDetailsTab = {
    margin: 20,

    scroll: {
        margin: {
            x: 15,
            y: 25
        },
        padding: {
            x: 20,
            y: 20
        },
        width: 1180,
        height: 800
    }
};