/**
 * Created by mac on 10/27/22
 */

var WarriorLogic = function () {

};

WarriorLogic.prototype.chooseTarget = function (warrior, targetSelection) {
    var target = warrior.targeted;
    if (target && target.dead) {
        target = undefined;
    }

    if (warrior.activity && warrior.activity.name === "move"
        && warrior.activity.getTicks() % WarriorLogic.UPDATE_TARGET_INTERVAL === 0) {
        target = undefined;
    }

    if (warrior.targetSelection !== targetSelection) {
        target = undefined;
    }

    if (target) {
        return {
            target: target,
            distance: Epicart.distance(warrior, target)
        };
    }

    return Game.currentGame.targetSelection.chooseTarget(warrior, targetSelection);
};

WarriorLogic.prototype.chooseActivity = function (warrior) {
    if (warrior.activity) {
        if (!warrior.activity.isFinished() && warrior.activity.getDuration() !== Infinity) {
            return warrior.activity;
        }
    }

    if (warrior.dead) {
        return undefined;
    }

    if (warrior.dragged) {
        return warrior.activity;
    }
    
    var skill = warrior.skills.find(function (skill) {
        return skill.canUse();
    });

    var targetData = this.chooseTarget(warrior, skill.targetSelection);
    if (!targetData) {
        return new Activity(warrior, "happy");
    }

    warrior.targeted = targetData.target;
    warrior.targetSelection = skill.targetSelection;

    if (warrior.canAttack(targetData.target, targetData.distance, skill.range)) {
        return skill.getActivity(targetData);
    }

    if (!Warrior.headingTo(warrior, targetData.target)) {
        return new ReverseActivity(warrior);
    }

    if (warrior.activity && warrior.activity.name === "move" && warrior.activity.target === targetData.target) {
        return warrior.activity;
    }

    return new MoveActivity(warrior, targetData.target, targetData.distance);
};

WarriorLogic.UPDATE_TARGET_INTERVAL = 30;