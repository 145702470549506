/**
 * Created by vladislav on 06/04/2023
 */

var MoveActivity = function (warrior, target, distance) {
    Activity.call(this, warrior, "move");

    this.target = target;
    this.distance = distance;

    this.idle = "move";
    this.updateTimeScale();
    this.speedListener = this.warrior.on("attrChanged:speed", this.updateTimeScale.bind(this));
};

MoveActivity.prototype = Object.create(Activity.prototype);
MoveActivity.prototype.constructor = MoveActivity;

MoveActivity.prototype.updateTimeScale = function () {
    this.setTimeScale(this.warrior.getAttribute("speed") / this.warrior.get("speed"));
};

MoveActivity.prototype.getDuration = function () {
    return Infinity;
};

MoveActivity.prototype.onStart = function () {
    if (!this.warrior.get("disableWalkSound")) {
        Game.currentGame.startSound(bundles.units.urls.walking_effect);
    }
};

MoveActivity.prototype.onTick = function () {
    Activity.prototype.onTick.call(this);

    var direction = this.warrior.directionTo(this.target);

    this.warrior.moveVector.x = direction.x * this.warrior.moveSpeed();
    this.warrior.moveVector.y = direction.y * this.warrior.moveSpeed();
};

MoveActivity.prototype.onFinish = function () {
    Activity.prototype.onTick.call(this);

    this.warrior.moveVector.x = 0;
    this.warrior.moveVector.y = 0;

    if (!this.warrior.get("disableWalkSound")) {
        Game.currentGame.stopSound(bundles.units.urls.walking_effect);
    }

    this.speedListener.clear();
};
