/**
 * Created by vladislav on 24/03/2023
 */

var Effect = function (type, warrior, options, sourceWarrior, source) {
    this.type = type;
    this.warrior = warrior;
    this.options = options;
    this.sourceWarrior = sourceWarrior;
    this.source = source;

    this.duration = this.getDuration();
    this.ticks = 0;
};

Effect.prototype.getDuration = function () {
    var duration = this.options.duration;
    if (duration !== WarriorLines.DURATION.INFINITY) {
        duration = Math.round(duration / Epicart.TICK);
    }

    return duration;
};

Effect.prototype.refresh = function () {
    this.ticks = 0;
};

Effect.prototype.onStart = function () {
    if (this.options.animation) {
        this.warrior.onApplyEffect(this.options.animation);
    }

    if (this.options.sound) {
        Game.currentGame.startSound(bundles.units.urls[this.options.sound + "_effect"]);
    }
};

Effect.prototype.onTick = function () {
    this._onTick();

    this.ticks++;
};

Effect.prototype._onTick = function () {

};

Effect.prototype.isFinished = function () {
    if (this.duration === WarriorLines.DURATION.INFINITY) {
        return false;
    }

    return this.ticks === this.duration;
};

Effect.prototype.onFinish = function () {
    if (this.options.animation) {
        this.warrior.onRemoveEffect(this.options.animation);
    }

    if (this.options.sound) {
        Game.currentGame.stopSound(bundles.units.urls[this.options.sound + "_effect"]);
    }
};

Effect.Create = function (type, warrior, options, sourceWarrior, source) {
    if (type === "buff") {
        return new BuffEffect(type, warrior, options, sourceWarrior, source);
    } if (type === "dot") {
        return new DOTEffect(type, warrior, options, sourceWarrior, source);
    }
};
