/**
 * Created by vladislav on 09/12/2022
 */

var TroopRewardWindow = Window.extend({
    onWindowLoaded: function (troop, card) {
        this.troop = troop;
        this.card = card;

        this.rarity = WarriorLines[this.troop.code].base.rarity;

        this.receive();

        this.content = this.createContent();

        this._super({
            title: "TroopRewardWindow.title",
            name: "TroopRewardWindow",
            noBackground: true,
            content: this.content,
            buttons: this.createButtons()
        });

        this.runShowAnimation();

        cleverapps.audio.playSound(bundles.troop_reward_window.urls.card_open_effect);
    },

    runShowAnimation: function () {
        this.animationRunning = true;

        var styles = cleverapps.styles.TroopRewardWindow;

        [this.buttons, this.windowTitle, this.bar, this.troopView].forEach(function (node) {
            node.setVisible(false);
        });

        var originalPos = this.troopView.getPosition();
        this.troopView.setPositionY(originalPos.y + styles.animation.moveDy);

        var animation = new cleverapps.Spine(bundles.troop_reward_window.jsons.troop_card_json);
        this.content.addChild(animation);
        animation.setPositionRound(this.troopView.getPosition());
        animation.setSkin(this.rarity);
        animation.setAnimation(0, "idle", false);
        animation.setCompleteListenerRemove();
        this.troopView.runAction(new cc.Sequence(
            new cc.DelayTime(1.2),
            new cc.CallFunc(function () {
                this.troopView.setVisible(true);
                this.troopView.setScaleX(0.2);
                this.troopView.setScaleY(1.5);
            }, this),
            new cc.ScaleTo(0.1, 1.1, 0.9),
            new cc.Spawn(
                new cc.ScaleTo(0.1, 1, 1),
                new cc.MoveTo(0.1, originalPos)
            ),
            new cc.CallFunc(function () {
                this.shine = new cleverapps.Spine(bundles.troop_reward_window.jsons.open_particles_json);
                this.shine.setAnimationAndIdleAfter("open", "idle");
                this.shine.setLocalZOrder(-1);
                this.content.addChild(this.shine);
                this.shine.setPositionRound(this.troopView.getPosition());

                [this.buttons, this.windowTitle, this.bar].forEach(function (node) {
                    node.setCascadeOpacityEnabledRecursively(true);
                    node.setOpacity(0);
                    node.setVisible(true);
                    node.runAction(new cc.FadeIn(0.3));
                });

                this.runAction(new cc.Sequence(
                    new cc.DelayTime(0.3),
                    new cc.CallFunc(function () {
                        this.animationRunning = false;

                        cleverapps.troopCards.trigger("updatePoints", this.card.points);
                    }.bind(this))
                ));
            }, this)
        ));
    },

    receive: function () {
        this.spawnPosition = cleverapps.formation.receiveNewSquad({
            code: this.troop.code,
            stage: this.troop.stage
        });
    },

    close: function () {
        if (this.animationRunning) {
            return;
        }

        this._super();
    },

    onClose: function () {
        var f = cleverapps.meta.onceNoWindowsListener;
        cleverapps.meta.onceNoWindowsListener = undefined;

        cleverapps.meta.compound(f, [
            function (f) {
                var position = this.troopView.getParent().convertToWorldSpace(this.troopView.getPosition());

                this.shine.removeFromParent();

                TroopRewardWindow.spawnAnimation(this.troopView, {
                    code: this.troop.code,
                    stage: this.troop.stage
                }, this.spawnPosition, position, f);
            }.bind(this),
            function (f) {
                if (this.buyOneMore) {
                    cleverapps.meta.onceNoWindowsListener = f;

                    if (cleverapps.troopCards.hasSpace()) {
                        cleverapps.troopCards.buy(this.card);
                    } else {
                        cleverapps.notification.create("NoSpace");
                    }
                } else {
                    f();
                }
            }.bind(this)
        ]);
    },

    createContent: function () {
        var styles = cleverapps.styles.TroopRewardWindow;

        this.troopView = new ArmyLibraryIcon(this.troop.code, this.troop.stage);

        this.bar = new TroopCardsProgressBar({
            showIcons: true,
            animateDelta: true,
            points: cleverapps.troopCards.getPoints() - this.card.points
        });

        var help = new cleverapps.UI.HelpButton(function () {
            new GuideWindow({
                name: "LuckyBonusGuide",
                bundle: bundles.luckybonusguide
            });
        });
        this.bar.addChild(help);
        help.setPositionRound(styles.help);

        return new cleverapps.Layout([this.troopView, this.bar], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });
    },

    createButtons: function () {
        var styles = cleverapps.styles.TroopRewardWindow;

        var buttons = [new cleverapps.UI.Button({
            text: "OK",
            width: styles.button.width,
            height: styles.button.height,
            onClicked: function () {
                this.close();
            }.bind(this)
        })];

        if (this.card && this.card.price !== 0) {
            buttons.push(new cleverapps.UI.Button({
                content: this.createButtonContent(),
                width: styles.buttonAnother.width,
                height: styles.buttonAnother.height,
                onClicked: function () {
                    if (cleverapps.user.canTakeSoft(this.card.price)) {
                        if (cleverapps.troopCards.hasSpace()) {
                            this.close();

                            this.buyOneMore = true;
                        } else {
                            cleverapps.notification.create("NoSpace");
                        }
                    } else {
                        cleverapps.meta.showControlsWhileFocused(["MenuBarGoldItem"]);
                        new SoftCurrencyShopWindow();
                    }
                }.bind(this)
            }));
        }

        return buttons;
    },

    createButtonContent: function () {
        var styles = cleverapps.styles.TroopRewardWindow;

        var text = cleverapps.UI.generateTTFText("OpenAnotherOne", cleverapps.styles.FONTS.WHITE_TEXT);
        var price = new TextWithIcon("@@" + this.card.price, {
            font: cleverapps.user.canTakeSoft(this.card.price) ? cleverapps.styles.FONTS.WHITE_TEXT
                : cleverapps.styles.FONTS.TROOP_REWARD_RED_TEXT
        });

        return new cleverapps.Layout([text, price], {
            margin: styles.buttonAnother.margin
        });
    }
});

TroopRewardWindow.spawnAnimation = function (icon, info, spawnPosition, startPosition, f) {
    var styles = cleverapps.styles.TroopRewardWindow;

    icon.replaceParentSamePlace(cc.director.getRunningScene(), {
        keepScale: true
    });
    icon.setPositionRound(startPosition);

    var target;
    if (spawnPosition) {
        target = Game.currentGame.battlefield.getCell(spawnPosition.x, spawnPosition.y);
        target = target.parent.convertToWorldSpace(cc.p(target.x + target.width / 2, target.y + target.height / 2));
    } else {
        target = cc.director.getRunningScene().barracksIcon;
        cleverapps.meta.showControlsWhileFocused("barracksIcon");
        target = target.parent.convertToWorldSpace(target.hideHandler.originalPosition);
    }

    cleverapps.audio.playSound(bundles.troop_reward_window.urls.card_spawn_effect);

    icon.runAction(new cc.Sequence(
        new cc.Spawn(
            new cc.RotateTo(0.1, -3),
            new cc.MoveBy(0.1, styles.spawn.offsetX, styles.spawn.offsetY)
        ),
        new cc.Spawn(
            new cc.RotateTo(0.1, 3),
            new cc.MoveBy(0.1, styles.spawn.offsetY, styles.spawn.offsetX)
        ),
        new cc.Spawn(
            new cc.Sequence(
                new cc.RotateTo(0.1, 13),
                new cc.RotateTo(0.3, 0)
            ),
            new cc.MoveTo(0.4, target),
            new cc.ScaleTo(0.4, 0.3)
        ).easing(cc.easeIn(2)),
        new cc.RemoveSelf(),
        new cc.CallFunc(function () {
            if (f) {
                f();
            }

            if (spawnPosition) {
                Game.currentGame.battlefield.spawnSquad({
                    code: info.code,
                    stage: info.stage,
                    x: spawnPosition.x,
                    y: spawnPosition.y
                }, Warrior.TEAM_PLAYER, {
                    animate: true
                });
            } else {
                cc.director.getRunningScene().barracksIcon.animateBought();
            }
        }),
        new cc.RemoveSelf()
    ));
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    TROOP_REWARD_RED_TEXT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_RED
    }
});

cleverapps.styles.TroopRewardWindow = {
    margin: 100,

    padding: {
        bottom: 50,
        top: 100
    },

    help: {
        x: { align: "right", dx: 55 },
        y: { align: "center", dy: 30 }
    },

    button: {
        width: 200,
        height: 110
    },

    buttonAnother: {
        margin: 5,

        width: 330,
        height: 110
    },

    animation: {
        moveDy: 200
    },

    spawn: {
        offsetX: 30,
        offsetY: 10
    }
};