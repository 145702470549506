/**
 * Created by vladislav on 20/03/2023
 */

var BattlefieldTutorialView = cc.Node.extend({
    ctor: function (battlefieldView) {
        this._super();

        this.battlefieldView = battlefieldView;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(cc.director.getRunningScene().getContentSize());

        Game.currentGame.tutorial.on("showFinger", this.createListener(this.showFinger.bind(this)), this);
        Game.currentGame.tutorial.on("hideFinger", this.createListener(this.hideFinger.bind(this)), this);
    },

    showTutorial: function (tutorial) {
        if (Game.currentGame.mode === Epicart.MODE_SETUP) {
            cleverapps.meta.setEventNodes([this.battlefieldView]);
        }

        this.minimal = new MinimalDialogue({
            text: tutorial.text,
            rects: this.listRects(tutorial),
            forcePosition: tutorial.forcePosition
        });
        this.addChild(this.minimal);
        this.minimal.display();

        this.showFinger(tutorial);
    },

    hideTutorial: function () {
        this.minimal.hide(function () {
            this.removeFromParent();
        }.bind(this));

        this.hideFinger();
    },

    showFinger: function (tutorial) {
        if (tutorial.type === "merge") {
            this.finger = FingerView.hintDrag([
                tutorial.units[0].onGetView(),
                tutorial.units[1].onGetView()
            ]);
        } else {
            this.finger = FingerView.hintClick(tutorial.fingerPosition);
        }
    },

    hideFinger: function () {
        FingerView.remove(this.finger);
        this.finger = undefined;
    },

    listRects: function (tutorial) {
        var rects = [];

        if (tutorial.allUnits) {
            tutorial.allUnits.forEach(function (unit) {
                rects.push(this.battlefieldView.getCell(unit.x, unit.y).getGlobalBoundingBox());
                rects.push(unit.onGetView().getGlobalBoundingBox());
            }, this);
        }

        if (tutorial.rects) {
            rects = rects.concat(tutorial.rects);
        }

        return rects;
    }
});
