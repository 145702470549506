/**
 * Created by vladislav on 30/11/2022
 */

var Barracks = function () {
    cleverapps.EventEmitter.call(this);

    this.squads = [];

    this.onGetView = function () {};
};

Barracks.prototype = Object.create(cleverapps.EventEmitter.prototype);
Barracks.prototype.constructor = Barracks;

Barracks.prototype.recallAll = function () {
    var squads = Game.currentGame.battlefield.listSquads(Warrior.TEAM_PLAYER).slice(1);

    squads.forEach(function (squad) {
        cleverapps.barracks.recall(squad);
    });
};

Barracks.prototype.combineAll = function () {
    var toMerge = {};
    this.squads.forEach(function (squad) {
        var key = squad.code + "_" + squad.stage;
        if (!toMerge[key]) {
            toMerge[key] = 0;
        }

        toMerge[key]++;
    });

    var newSquads = [];
    this.squads.forEach(function (squad) {
        var key = squad.code + "_" + squad.stage;

        if (toMerge[key] > 1 && Squad.CanMerge(squad, squad)) {
            newSquads.push(new BarracksSquad({
                code: squad.code,
                stage: squad.stage + 1
            }));

            toMerge[key] -= 2;
        } else if (toMerge[key] > 0) {
            newSquads.push(squad);

            toMerge[key]--;
        }
    });

    this.squads = newSquads;

    var unopened = cleverapps.unique(newSquads.filter(function (squad) {
        return !cleverapps.armyLibrary.isOpened(squad.code, squad.stage);
    }), function (squad) {
        return squad.code + "_" + squad.stage;
    });

    unopened.forEach(function (squad) {
        cleverapps.armyLibrary.setOpened(squad.code, squad.stage);

        new NewTroopWindow(squad.code, squad.stage);
    });

    this.trigger("refresh");
    this.trigger("updateAmount");

    cleverapps.army.save();
};

Barracks.prototype.overlaps = function (squad) {
    var squadBox = squad.onGetView().getGlobalBoundingBox();

    if (!this.opened) {
        var barracksIcon = cc.director.getRunningScene().barracksIcon;

        if (!barracksIcon) {
            return false;
        }

        var intersection = cc.rectIntersection(barracksIcon.getGlobalBoundingBox(), squadBox);

        return intersection.height >= squadBox.height * squad.onGetView().anchorY && intersection.width >= squadBox.width / 2;
    }

    intersection = cc.rectIntersection(
        this.onGetView().content.getGlobalBoundingBox(),
        squadBox
    );
    return intersection.height > squadBox.height / 3;
};

Barracks.prototype.listSquads = function () {
    return this.squads;
};

Barracks.prototype.startDrag = function (barracksSquad) {
    this.draggedSquad = barracksSquad;

    barracksSquad.onDragStart();

    this.trigger("startDrag");
};

Barracks.prototype.stopDrag = function () {
    if (this.draggedSquad) {
        this.draggedSquad.onDragEnd();

        delete this.draggedSquad;

        this.trigger("stopDrag");
    }
};

Barracks.prototype.open = function (f) {
    this.opened = true;

    new BarracksWindow();

    cleverapps.meta.onceNoWindowsListener = f;

    this.trigger("open");
};

Barracks.prototype.close = function () {
    this.opened = false;

    this.trigger("close");
};

Barracks.prototype.getInfo = function () {
    return this.squads;
};

Barracks.prototype.load = function (squads) {
    this.squads = (squads || []).map(function (squad) {
        return new BarracksSquad(squad);
    });
};

Barracks.prototype.isFull = function () {
    return this.squads.length >= Barracks.MAX_AMOUNT;
};

Barracks.prototype.recall = function (squad, ignoreLimit) {
    if (this.addSquad({
        code: squad.code,
        stage: squad.stage
    }, false, ignoreLimit)) {
        if (Game.currentGame.battlefield) {
            Game.currentGame.battlefield.removeSquad(squad.x, squad.y);
        }
    }
};

Barracks.prototype.addSquad = function (info, silent, ignoreLimit) {
    if (this.isFull() && !ignoreLimit) {
        cleverapps.notification.create("Barracks.full");

        return false;
    }

    var squad = new BarracksSquad(info);

    cleverapps.armyLibrary.setOpened(info.code, info.stage);

    this.squads.push(squad);

    cleverapps.army.save();

    if (!silent) {
        this.trigger("addSquad", squad);
        this.trigger("updateAmount");
    }

    return true;
};

Barracks.prototype.deployInstantly = function (squad) {
    var pos = cleverapps.formation.findEmptySlot();

    if (!pos) {
        cleverapps.notification.create("NoSpace");
        return;
    }

    Game.currentGame.battlefield.spawnSquad({
        code: squad.code,
        stage: squad.stage,
        x: pos.x,
        y: pos.y
    }, Warrior.TEAM_PLAYER);

    this.deploy(squad);
};

Barracks.prototype.deploy = function (squad) {
    var index = this.squads.indexOf(squad);

    this.squads.splice(index, 1);

    this.trigger("deploy", squad);
    this.trigger("updateAmount");

    cleverapps.army.save();
};

Barracks.prototype.deployDragged = function () {
    this.deploy(this.draggedSquad);
};

Barracks.isAvailable = function () {
    return cleverapps.user.checkAvailable({
        level: 1
    });
};

Barracks.MAX_AMOUNT = 100;