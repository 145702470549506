/**
 * Created by vladislav on 15/12/2022
 */

var BattleDetailsWindow = Window.extend({
    onWindowLoaded: function () {
        var styles = cleverapps.styles.BattleDetailsWindow;

        this.content = new cc.Node();
        this.content.setAnchorPoint(0.5, 0.5);
        this.content.setContentSize2(styles);

        this._super({
            name: "BattleDetailsWindow",
            title: "BattleDetailsWindow.title",
            content: this.content
        });

        this.tabsInfo = this.createTabs();

        this.tabs = new Tabs(this.tabsInfo, {
            direction: cleverapps.UI.VERTICAL,
            height: styles.tabs.height
        });
        this.tabs.setAnchorPoint(0.5, 0.5);
        this.content.addChild(this.tabs);
        this.tabs.setPositionRound(styles.tabs);
        this.tabs.activateTab(0);
    },

    createTabs: function () {
        var tabs = {};
        cleverapps.values(BattleStats.TYPES).forEach(function (type, index) {
            tabs[index] = {
                icon: bundles.battle_stats.frames["icon_" + type],
                generator: this.createTab.bind(this, type)
            };
        }, this);
        return tabs;
    },

    createTab: function (type) {
        var tab = new BattleDetailsTab(type);
        this.content.addChild(tab);
        tab.setPositionRound(this.content.width / 2, this.content.height / 2);

        return tab;
    }
});

cleverapps.styles.BattleDetailsWindow = {
    width: 1180,
    height: 900,

    tabs: {
        x: { align: "left", dx: -200 },
        y: { align: "top", dy: -100 },
        height: 100
    }
};