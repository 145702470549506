/**
 * Created by vladislav on 11/05/2023
 */

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    animation: {
        "starterPack0": {
            idleAnimation: "0_idle"
        },

        "starterPack": {
            idleAnimation: "1_idle"
        },

        "starterPack2": {
            idleAnimation: "2_idle"
        }
    },

    rewards: {
        delay: 1.5,
        duration: 0.675,
        fitTo: {
            width: 166
        },

        positions: {
            "default": {
                "hard": {
                    x: -465,
                    y: -149
                },
                "soft": {
                    x: 583,
                    y: -149
                },
                "troopCards": {
                    x: 50,
                    y: -347
                }
            }
        }
    }
});