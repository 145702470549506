/**
 * Created by vladislav on 13/02/2023
 */

var BarracksAmountView = cleverapps.Layout.extend({
    ctor: function () {
        var styles = cleverapps.styles.BarracksAmountView;

        var icon = new cc.Sprite(bundles.battlefield.frames.icon_player_blue);

        this.amount = this.createAmount();

        this._super([icon, this.amount], {
            margin: styles.margin,
            direction: cleverapps.UI.HORIZONTAL
        });

        cleverapps.barracks.on("updateAmount", this.createListener(this.updateCurrentAmount.bind(this)), this);
    },

    createAmount: function () {
        var styles = cleverapps.styles.BarracksWindow;

        var font = cleverapps.barracks.listSquads().length < Barracks.MAX_AMOUNT
            ? cleverapps.styles.FONTS.BARRACKS_AMOUNT_TEXT_GREEN
            : cleverapps.styles.FONTS.BARRACKS_AMOUNT_TEXT_RED;
        this.currentAmount = cleverapps.UI.generateImageText(cleverapps.barracks.listSquads().length, font);
        this.currentAmount.setAnchorPoint(1, 0.5);
        var max = cleverapps.UI.generateImageText("/" + Barracks.MAX_AMOUNT, cleverapps.styles.FONTS.BARRACKS_AMOUNT_TEXT);

        return new cleverapps.Layout([this.currentAmount, max], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.textMargin
        });
    },

    updateCurrentAmount: function () {
        this.currentAmount.setString(cleverapps.barracks.listSquads().length);

        var font = cleverapps.barracks.listSquads().length < Barracks.MAX_AMOUNT
            ? cleverapps.styles.FONTS.BARRACKS_AMOUNT_TEXT_GREEN
            : cleverapps.styles.FONTS.BARRACKS_AMOUNT_TEXT_RED;
        this.currentAmount.setFont(font);

        this.amount.reshape();
        this.reshape();
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    BARRACKS_AMOUNT_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },
    BARRACKS_AMOUNT_TEXT_RED: {
        size: 35,
        color: cleverapps.styles.COLORS.COLOR_RED,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },
    BARRACKS_AMOUNT_TEXT_GREEN: {
        size: 35,
        color: cleverapps.styles.COLORS.GREEN,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.BarracksAmountView = {
    textMargin: 3,
    margin: 5
};