/**
 * Created by vladislav on 29/11/2022
 */

var Formation = function () {
    this.formation = {};
};

Formation.prototype.removeSquad = function (x, y) {
    this.formation[x + "_" + y] = undefined;

    cleverapps.army.save();
};

Formation.prototype.moveSquad = function (fromX, fromY, x, y) {
    if (fromX === x && fromY === y) {
        return;
    }

    var target = this.formation[fromX + "_" + fromY];
    var dest = this.formation[x + "_" + y];
    target.x = x;
    target.y = y;
    if (dest) {
        dest.x = fromX;
        dest.y = fromY;
    }

    this.formation[x + "_" + y] = target;
    this.formation[fromX + "_" + fromY] = dest;

    cleverapps.army.save();
};

Formation.prototype.addSquad = function (info) {
    this.formation[info.x + "_" + info.y] = {
        code: info.code,
        stage: info.stage,
        x: info.x,
        y: info.y
    };

    cleverapps.armyLibrary.setOpened(info.code, info.stage);

    cleverapps.army.save();
};

Formation.prototype.findEmptySlot = function () {
    var region;
    if (Game.currentGame) {
        region = Game.currentGame.battlefield.region;
    } else {
        region = cc.rect(0, 0, Battlefield.GRID.x, Battlefield.GRID.y);
    }

    for (var x = region.x; x < region.x + region.width / 2; x++) {
        for (var y = region.y; y < region.y + region.height; y++) {
            if (!this.formation[x + "_" + y]) {
                return {
                    x: x,
                    y: y
                };
            }
        }
    }
};

Formation.prototype.receiveNewSquad = function (squad) {
    var pos = this.findEmptySlot();
    if (pos) {
        cleverapps.formation.addSquad({
            code: squad.code,
            stage: squad.stage,
            x: pos.x,
            y: pos.y
        });
    } else {
        cleverapps.barracks.addSquad({
            code: squad.code,
            stage: squad.stage
        }, true, true);
    }

    return pos;
};

Formation.prototype.listSquads = function () {
    return cleverapps.values(this.formation).filter(Boolean);
};

Formation.prototype.getInfo = function () {
    return this.listSquads();
};

Formation.prototype.initForNewPlayer = function () {
    this.addSquad({
        code: WarriorLines.CODES.INFANTRY,
        stage: 0,
        x: 3,
        y: 3
    });
    this.addSquad({
        code: WarriorLines.CODES.INFANTRY,
        stage: 0,
        x: 4,
        y: 4
    });
    this.addSquad({
        code: WarriorLines.CODES.INFANTRY,
        stage: 0,
        x: 4,
        y: 2
    });

    cleverapps.army.save();
};

Formation.prototype.isEmpty = function () {
    return Object.keys(this.formation).length === 0;
};

Formation.prototype.load = function (formation) {
    this.formation = {};

    if (formation) {
        formation.forEach(function (squad) {
            this.formation[squad.x + "_" + squad.y] = {
                code: squad.code,
                stage: squad.stage,
                x: squad.x,
                y: squad.y
            };
        }, this);
    }
};