/**
 * Created by vladislav on 30/03/2023
 */

var WarriorsConfig = {};

WarriorsConfig[WarriorLines.CODES.VIKING_WARRIOR] = {
    0: [
        {
            json: bundles.units.jsons.unit_11_0,
            skin: "normal"
        }
    ],

    1: [
        {
            json: bundles.units.jsons.unit_11_1,
            skin: "normal"
        }
    ],

    2: [
        {
            json: bundles.units.jsons.unit_11_2,
            skin: "normal"
        }
    ],

    3: [
        {
            json: bundles.units.jsons.unit_11_3,
            skin: "normal"
        }
    ],

    4: [
        {
            json: bundles.units.jsons.unit_11_4,
            skin: "normal"
        }
    ],

    5: [
        {
            json: bundles.units.jsons.unit_11_5,
            skin: "normal"
        }
    ],

    6: [
        {
            json: bundles.units.jsons.unit_11_6,
            skin: "normal"
        }
    ],

    7: [
        {
            json: bundles.units.jsons.unit_11_7,
            skin: "normal"
        }
    ],

    8: [
        {
            json: bundles.units.jsons.unit_11_8,
            skin: "normal"
        }
    ],

    9: [
        {
            json: bundles.units.jsons.unit_11_9,
            skin: "normal"
        }
    ]
};
(function () {
    for (var name in WarriorLines.CODES) {
        var code = WarriorLines.CODES[name];
        WarriorsConfig[code] = WarriorsConfig[code] || {};
        var line = WarriorLines[code].line;
        for (var stage = 0; stage < line.length; stage++) {
            if (!WarriorsConfig[code][stage]) {
                WarriorsConfig[code][stage] = [];

                for (var squadIndex = 0; squadIndex < WarriorLines[0].line[0].load; squadIndex++) {
                    WarriorsConfig[code][stage].push({
                        json: bundles.units.jsons["unit_" + code],
                        skin: stage
                    });
                }
            }
        }
    }
}());