/**
 * Created by vladislav on 30/03/2023
 */

var Flank = function (warrior, options) {
    Skill.call(this, warrior, options);
};

Flank.prototype = Object.create(Skill.prototype);
Flank.prototype.constructor = Flank;

Flank.prototype.canUse = function () {
    return !this.used;
};

Flank.prototype.getActivity = function (targetData) {
    this.used = true;

    return new FlankActivity(this.warrior, targetData.target, this.options.speed);
};