/**
 * Created by vladislav on 06/04/2023
 */

var DieActivity = function (warrior) {
    Activity.call(this, warrior, "die");
};

DieActivity.prototype = Object.create(Activity.prototype);
DieActivity.prototype.constructor = DieActivity;

DieActivity.prototype.onStart = function () {
    this.warrior.onDie();
};

DieActivity.prototype.onFinish = function () {
    this.warrior.afterDied();
};