/**
 * Created by vladislav on 06/04/2023
 */

var RageActivity = function (warrior, target, buff) {
    Activity.call(this, warrior, "rage");

    this.target = target;

    this.firePercent = 100;
    this.buff = buff;
    this.sound = bundles.units.urls.rage_effect;
};

RageActivity.prototype = Object.create(Activity.prototype);
RageActivity.prototype.constructor = RageActivity;

RageActivity.prototype.onFire = function () {
    this.warrior.applyEffect("buff", this.buff, this);

    this.warrior.onSetSkin("rage");
};