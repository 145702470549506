/**
 * Created by vladislav on 15/12/2022
 */

var BattleStatsWindow = Window.extend({
    onWindowLoaded: function () {
        var styles = cleverapps.styles.BattleStatsWindow;

        this._super({
            name: "BattleStatsWindow",
            title: "BattleStatsWindow.title",
            content: this.createContent(),
            button: {
                width: styles.button.width,
                height: styles.button.height,
                text: "BattleStatsWindow.details",
                onPressed: function () {
                    new BattleDetailsWindow();
                }
            }
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.BattleStatsWindow;

        var playerInfo = this.createArmyInfo(Warrior.TEAM_PLAYER);
        var enemyInfo = this.createArmyInfo(Warrior.TEAM_ENEMY);

        return new cleverapps.Layout([playerInfo, enemyInfo], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
    },

    createArmyInfo: function (team) {
        var styles = cleverapps.styles.BattleStatsWindow;

        var stats = cleverapps.values(BattleStats.TYPES).map(function (type) {
            return this.createStatView(team, type);
        }, this);

        return new cleverapps.Layout(stats, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.info.margin
        });
    },

    createStatView: function (team, type) {
        var styles = cleverapps.styles.BattleStatsWindow;

        var bg = new cc.Scale9Sprite(bundles.battle_stats.frames.stat_bg);
        bg.setContentSize2(styles.info);

        var icon = new cc.Sprite(bundles.battle_stats.frames["icon_" + type]);
        bg.addChild(icon);
        icon.setPositionRound(styles.info.icon);

        cleverapps.tooltipManager.create(icon, {
            text: "BattleStats." + type,
            position: cleverapps.styles.UI.Tooltip.LOCATION.above,
            size: cleverapps.styles.UI.Tooltip.SIZE.short
        });

        var amount = cleverapps.UI.generateImageText(
            Game.currentGame.battleStats.calcTotal(type, team),
            cleverapps.styles.FONTS.WINDOW_SMALL_TEXT
        );
        bg.addChild(amount);
        amount.setPositionRound(styles.info.amount);

        return bg;
    }
});

cleverapps.styles.BattleStatsWindow = {
    margin: 80,

    button: {
        width: 250,
        height: 90
    },

    info: {
        margin: 10,

        width: 400,
        height: 50,

        icon: {
            x: { align: "left", anchor: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        },

        amount: {
            x: { align: "right", dx: -10 },
            y: { align: "center", dy: 0 }
        }
    }
};