/**
 * Created by vladislav on 20/02/2023
 */

var TroopCardsWindow = Window.extend({
    onWindowLoaded: function () {
        this.products = cleverapps.troopCards.listProducts();

        this._super({
            title: "TroopCardsWindow.title",
            name: "TroopCardsWindow",
            noBackground: true,
            content: this.createContent()
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.TroopCardsWindow;

        var cards = this.products.map(function (product) {
            return new TroopCardView(product);
        }, this);

        this.probabilities = new TroopProbabilityView(this.products[0].probabilities);

        this.button = this.createButton(this.products[0].price);

        var carousel = new cleverapps.UI.CarouselView(cards, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            onChange: this.onChangeCard.bind(this)
        });

        var bar = new TroopCardsProgressBar({
            showIcons: true
        });

        return new cleverapps.Layout([carousel, this.probabilities, bar, this.button], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.bar.margin,
            padding: styles.padding
        });
    },

    createButton: function (price) {
        var styles = cleverapps.styles.TroopCardsWindow;

        return new cleverapps.UI.Button({
            text: "@@" + price,
            width: styles.button.width,
            height: styles.button.height,
            onClicked: function () {
                if (cleverapps.troopCards.hasSpace()) {
                    if (cleverapps.troopCards.buy(this.currentProduct)) {
                        this.close();
                    }
                } else {
                    cleverapps.notification.create("NoSpace");

                    this.close();
                }
            }.bind(this)
        });
    },

    onChangeCard: function (index, silent) {
        this.currentProduct = this.products[index];

        this.button.setString("@@" + this.currentProduct.price);
        this.probabilities.setProbabilities(this.currentProduct.probabilities);

        if (!silent) {
            cleverapps.audio.playSound(bundles.troop_cards.urls.card_swipe_effect);
        }
    }
});

cleverapps.styles.TroopCardsWindow = {
    margin: 50,

    bar: {
        margin: 50
    },

    padding: {
        top: 50
    },

    button: {
        width: 300,
        height: 100
    }
};