/**
 * Created by mac on 9/18/22
 */

var LinesHelper = {
    guessValueAt: function (arr, index) {
        if (!Array.isArray(arr)) {
            return arr;
        }

        if (arr[index]) {
            return arr[index];
        }

        if (arr.length === 1) {
            return arr[0];
        }

        var diff = arr[arr.length - 1] - arr[arr.length - 2];
        return arr[arr.length - 1] + diff * (index - arr.length + 1);
    },

    processOptions: function (stage, options) {
        var res = {};

        for (var key in options) {
            var value = options[key];

            if (typeof value === "object" && !Array.isArray(value)) {
                res[key] = this.processOptions(stage, options[key]);
            } else {
                res[key] = LinesHelper.guessValueAt(value, stage);
            }
        }

        return res;
    },

    createLine: function (total, options) {
        var line = [];
        for (var i = 0; i < total; i++) {
            line.push(this.processOptions(i, options));
        }

        return {
            base: {
                rarity: options.rarity,
                race: options.race,
                range: options.normalAttack.range,
                available: options.available
            },
            line: line
        };
    },

    listBasicStats: function (code, stage) {
        return [
            "hp",
            "atk",
            "def",
            "speed",
            "load"
        ].map(function (stat) {
            var value = WarriorLines[code].line[stage][stat] || WarriorLines[code].line[stage].normalAttack.effects[stat];

            if (value === undefined) {
                return;
            }

            return {
                type: stat,
                value: value,
                baseValue: WarriorLines[code].line[0][stat] || WarriorLines[code].line[0].normalAttack.effects[stat]
            };
        }).filter(Boolean);
    },

    listAttackStats: function (code, stage) {
        return [
            "aspd",
            "range",
            "type",
            "shape",
            "shapeSize",
            "targetSelection"
        ].map(function (stat) {
            var value = WarriorLines[code].line[stage].normalAttack[stat];

            if (value === undefined) {
                return;
            }

            return {
                type: stat,
                value: value,
                baseValue: WarriorLines[code].line[0].normalAttack[stat]
            };
        }).filter(Boolean);
    },

    findStatsToDisplay: function (set, prefix, object) {
        var stats = [];

        for (var key in object) {
            var value = object[key];

            if (typeof value === "object") {
                stats = this.findStatsToDisplay(set, key, value).concat(stats);
            } else if (set[prefix + "_" + key] || set[key]) {
                var valueType;
                if (prefix === "buffs") {
                    valueType = "percent";
                }
                stats.push({
                    type: set[prefix + "_" + key] ? prefix + "_" + key : key,
                    value: value,
                    valueType: valueType
                });
            }
        }

        return stats;
    },

    listSkillStats: function (code, stage, name) {
        var skills = WarriorLines[code].line[stage].skills;
        for (var skillType in skills) {
            if (skills[skillType].name === name) {
                var skill = skills[skillType];
                var skillBase = WarriorLines[code].line[0].skills[skillType];
                break;
            }
        }

        var statsToDisplay = [
            "atk",
            "dot_dmg",
            "duration",
            "buff_duration",
            "shapeSize",
            "triggerNumber",
            "skillUsages",
            "knockback",
            "dot_dmgCount",
            "buffs_atk",
            "buffs_aspd",
            "buffs_speed"
        ];

        var statsSet = cleverapps.createSet(statsToDisplay);

        var order = {};
        statsToDisplay.forEach(function (key, index) {
            order[key] = index;
        });

        var stats = this.findStatsToDisplay(statsSet, "", skill).sort(function (a, b) {
            return order[a.type] - order[b.type];
        });
        var baseStats = this.findStatsToDisplay(statsSet, "", skillBase).sort(function (a, b) {
            return order[a.type] - order[b.type];
        });

        return stats.map(function (stat, index) {
            return {
                type: stat.type,
                value: stat.value,
                baseValue: baseStats[index].value,
                valueType: stat.valueType
            };
        });
    }
};

if (typeof exports !== "undefined") {
    module.exports = LinesHelper;
}