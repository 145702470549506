/**
 * Created by vladislav on 07/02/2023
 */

Squad.prototype.move = cleverapps.extendFunc(Squad.prototype.move, function (pos) {
    if (cleverapps.environment.isEditorScene() && this.team === Warrior.TEAM_ENEMY) {
        this.onMaximize();

        if (!pos) {
            pos = cc.p(this.x, this.y);
        }

        Editor.currentEditor.moveSquad(this, pos.x, pos.y);
    } else {
        return this._super.apply(this, arguments);
    }
});