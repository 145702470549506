/**
 * Created by vladislav on 29/11/2022
 */

var Squad = function (info, team) {
    this.x = info.x;
    this.y = info.y;

    this.code = info.code;
    this.stage = info.stage;

    this.team = team;

    var amount = Warrior.GET(info.code, info.stage, "load");
    var smallFormation = Epicart.SMALL_FORMATIONS[amount - 1];

    this.smallFormation = smallFormation;

    this.warriors = [];
    var squadIndex = 0;
    for (var i = 0; i < smallFormation.length; i++) {
        for (var j = 0; j < smallFormation[i].length; j++) {
            if (smallFormation[i].charAt(j) === ".") {
                continue;
            }

            var squadPosition = this.warriorSquadPosition(j, i);

            var warrior = new Warrior({
                code: info.code,
                stage: info.stage,
                team: team,
                squad: this,
                squadIndex: squadIndex,
                squadX: squadPosition.x,
                squadY: squadPosition.y
            });

            squadIndex++;

            this.warriors.push(warrior);
        }
    }

    this.stats = {};
    for (var type in BattleStats.TYPES) {
        this.stats[BattleStats.TYPES[type]] = 0;
    }

    this.isHighlighted = false;

    this.onMove = function () {};
    this.onRemove = function () {};
    this.onHighlight = function () {};
    this.onMinimize = function () {};
    this.onMaximize = function () {};
    this.onGetView = function () {};
    this.onDragStart = function () {};
    this.onDragMove = function () {};
    this.onDragEnd = function () {};
    this.onFollowPointer = function () {};
    this.onDeploy = function () {};
};

Squad.prototype.isDead = function () {
    return this.warriors.every(function (warrior) {
        return warrior.dead;
    });
};

Squad.prototype.getAliveAmount = function () {
    return this.warriors.filter(function (warrior) {
        return !warrior.dead;
    }).length;
};

Squad.prototype.addStat = function (type, amount) {
    this.stats[type] += amount;
};

Squad.prototype.getStat = function (type) {
    return this.stats[type];
};

Squad.prototype.listWarriors = function () {
    return this.warriors;
};

Squad.prototype.deploy = function (parent) {
    this.warriors.forEach(function (warrior) {
        parent.push(warrior);

        var virtualPos = this.warriorVirtualPosition(warrior);
        warrior.setPosition(virtualPos.x, virtualPos.y);
    }, this);

    this.onDeploy();
};

Squad.prototype.warriorVirtualPosition = function (warrior) {
    var x = this.x + 0.5;
    var y = this.y + 0.5;

    return cc.p(x * Epicart.VIRTUAL_CELL.width + warrior.squadX, y * Epicart.VIRTUAL_CELL.height + warrior.squadY);
};

Squad.prototype.warriorSquadPosition = function (indexX, indexY) {
    var dx = 1 / (this.smallFormation[0].length + 1);
    var dy = 1 / (this.smallFormation.length + 1);

    var x = (indexX + 1) * dx - 0.5;
    var y = (indexY + 1) * dy - 0.5;

    return cc.p(x * Epicart.VIRTUAL_CELL.width, y * Epicart.VIRTUAL_CELL.height);
};

Squad.prototype.remove = function () {
    this.onRemove();
};

Squad.prototype.moveTo = function (x, y) {
    this.x = x;
    this.y = y;

    this.onMove();
};

Squad.prototype.highlight = function (active) {
    if (this.isHighlighted === active) {
        return;
    }

    this.isHighlighted = active;

    this.onHighlight();
};

Squad.prototype.handleDragStart = function () {
    Game.currentGame.battlefield.highlightMerge(this);

    this.onDragStart();

    return true;
};

Squad.prototype.handleFollowPointer = function (pos) {
    this.onFollowPointer(pos);
};

Squad.prototype.handleDragMove = function (pos) {
    var view = this.onGetView();

    var cellPos = Game.currentGame.battlefield.alignInGrid(view.x, view.y - view.height / 2);
    if (!Game.currentGame.battlefield.isWithinBounds(cellPos.x, cellPos.y)) {
        cellPos = undefined;
    }

    Game.currentGame.battlefield.highlight.move(cellPos, this.team);

    if (cleverapps.barracks.overlaps(this) && this.team === Warrior.TEAM_PLAYER) {
        this.onMinimize();
    } else {
        this.onMaximize();
    }

    this.onDragMove(pos);
};

Squad.prototype.handleDragEnd = function (pos, fromBarracks) {
    var position = Game.currentGame.battlefield.highlight.getPosition();

    Game.currentGame.battlefield.highlight.stop();

    Game.currentGame.battlefield.unHighlightMerge();

    if (cleverapps.config.debugMode && Game.currentGame.battlefield.isInDeleteZone(this)) {
        Game.currentGame.battlefield.removeSquad(this.x, this.y);
    } else if (cleverapps.barracks.overlaps(this) && this.team === Warrior.TEAM_PLAYER) {
        if (!fromBarracks) {
            this.move(position, fromBarracks);

            cleverapps.barracks.recall(this);
        }
    } else {
        var other = position && Game.currentGame.battlefield.findSquad(position.x, position.y);

        if (other && Squad.CanMerge(this, other)) {
            Game.currentGame.battlefield.merge(this, other, fromBarracks);

            if (fromBarracks) {
                cleverapps.barracks.deployDragged();
            }
        } else if (fromBarracks) {
            if (other) {
                cleverapps.barracks.recall(other, true);
            }

            if (position) {
                cleverapps.barracks.deployDragged();

                Game.currentGame.battlefield.spawnSquad({
                    code: this.code,
                    stage: this.stage,
                    x: position.x,
                    y: position.y
                }, Warrior.TEAM_PLAYER);
            }
        } else {
            this.move(position, fromBarracks);
        }
    }

    this.onDragEnd(fromBarracks);

    cleverapps.barracks.stopDrag();
};

Squad.prototype.move = function (pos, fromBarracks) {
    this.onMaximize();

    if (!pos) {
        pos = cc.p(this.x, this.y);
    }

    Game.currentGame.battlefield.moveSquad(this, pos.x, pos.y, fromBarracks);
};

Squad.CanMerge = function (squadA, squadB) {
    if (cleverapps.environment.isEditorScene() && squadA.team === Warrior.TEAM_ENEMY) {
        return false;
    }

    if (!cleverapps.user.checkAvailable(Epicart.MERGE_AVAILABLE)) {
        return false;
    }

    return squadA.code === squadB.code && squadA.stage === squadB.stage
        && squadA.stage < WarriorLines[squadA.code].line.length - 1
        && !(squadA.x !== undefined && squadA.y !== undefined && squadA.x === squadB.x && squadA.y === squadB.y);
};