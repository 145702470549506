/**
 * Created by vladislav on 25/04/2023
 */

var Reinforcement = function (region) {
    this.amount = Reinforcement.INITIAL_AMOUNT;

    this.spawnPosition = cc.p(
        (region.x + region.width / 4) * Epicart.VIRTUAL_CELL.width,
        (region.y + region.height / 2) * Epicart.VIRTUAL_CELL.height
    );
    this.spawnUnit = {
        code: WarriorLines.CODES.INFANTRY,
        stage: 0
    };

    this.onStartRecharge = function () {};
    this.onPauseRecharge = function () {};
    this.onResumeRecharge = function () {};
    this.onChangeAmount = function () {};
    this.onSpawn = function () {};
    this.onMoveSpawn = function () {};
};

Reinforcement.prototype.startRecharge = function () {
    if (this.amount < Reinforcement.MAX_AMOUNT) {
        this.recharging = true;

        this.onStartRecharge();
    }
};

Reinforcement.prototype.recharge = function () {
    this.setAmount(this.amount + 1);

    this.recharging = false;

    this.startRecharge();
};

Reinforcement.prototype.stopRecharge = function () {
    this.onPauseRecharge();
};

Reinforcement.prototype.pauseRecharge = function () {
    this.onPauseRecharge();
};

Reinforcement.prototype.resumeRecharge = function () {
    this.onResumeRecharge();
};

Reinforcement.prototype.setAmount = function (amount) {
    this.amount = amount;

    this.onChangeAmount();
};

Reinforcement.prototype.fieldClicked = function (pos) {
    if (Game.currentGame.isBattleStopped()) {
        return;
    }

    this.moveSpawnPosition(pos);

    this.spawn();
};

Reinforcement.prototype.moveSpawnPosition = function (pos) {
    var coef = Epicart.realToVirtual();

    this.spawnPosition = cc.p(pos.x * coef, pos.y * coef);

    this.onMoveSpawn();
};

Reinforcement.prototype.spawn = function () {
    if (this.amount < 1) {
        return;
    }
    this.setAmount(this.amount - 1);

    Game.currentGame.battlefield.spawnSquadDuringBattle({
        code: this.spawnUnit.code,
        stage: this.spawnUnit.stage,
        team: Warrior.TEAM_PLAYER
    }, this.spawnPosition.x, this.spawnPosition.y);

    Game.currentGame.tutorial.onReinforcement();

    if (!this.recharging) {
        this.startRecharge();
    }
};

Reinforcement.IsAvailable = function () {
    return cleverapps.user.checkAvailable(Reinforcement.AVAILABLE);
};

Reinforcement.AVAILABLE = {
    level: 0.13
};

Reinforcement.INITIAL_AMOUNT = 1;
Reinforcement.MAX_AMOUNT = 1;
Reinforcement.COOLDOWN = 4 * 1000;
