/**
 * Created by vladislav on 28/03/2023
 */

var AppliedForce = function (warrior, force, duration) {
    duration = Math.round(duration / Epicart.TICK);

    this.warrior = warrior;
    this.force = cc.p(force.x / duration, force.y / duration);
    this.duration = duration;

    this.ticks = 0;
};

AppliedForce.prototype.onTick = function () {
    var mult = 1.5 - Math.pow(this.ticks / this.duration, 1 / 3);
    this.warrior.addForce(cc.pMult(this.force, mult));

    this.ticks++;
};

AppliedForce.prototype.isFinished = function () {
    return this.ticks >= this.duration;
};