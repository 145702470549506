/**
 * Created by vladislav on 03/04/2023
 */

var TargetSelection = function () {
    this.closestCache = {};
    this.closestCacheTTL = {};
};

TargetSelection.prototype.chooseTarget = function (warrior, targetSelection) {
    targetSelection = targetSelection || WarriorLines.TARGET_SELECTION.CLOSEST;

    switch (targetSelection) {
        case WarriorLines.TARGET_SELECTION.CLOSEST:
            return this.closestEnemy(warrior, warrior.otherTeam(), Warrior.HEAD_ANY);
        case WarriorLines.TARGET_SELECTION.FARTHEST:
            return this.farthestEnemy(warrior, warrior.otherTeam(), Warrior.HEAD_ANY);
        case WarriorLines.TARGET_SELECTION.RANDOM:
            return this.randomEnemy(warrior, warrior.otherTeam(), Warrior.HEAD_ANY);
        case WarriorLines.TARGET_SELECTION.SELF:
            return {
                target: warrior,
                distance: 0
            };
    }
};

TargetSelection.prototype.closestEnemy = function (pos, team, head) {
    var hash = Epicart.hashPosition(pos, team, head);
    if (this.closestCache[hash] && this.closestCacheTTL[hash] + 20 < Game.currentGame.tickCounter) {
        if (this.closestCache[hash].dead) {
            this.closestCache[hash] = undefined;
        } else {
            return this.closestCache[hash];
        }
    }

    var minDist = 1e9;
    var target = undefined;
    Game.currentGame.alive[team].forEach(function (enemy) {
        if (enemy && !enemy.dead) {
            var dist = Epicart.distance(pos, enemy);
            if (dist < minDist) {
                minDist = dist;
                target = enemy;
            }
        }
    });

    if (target === undefined) {
        return undefined;
    }

    return {
        target: target,
        distance: minDist
    };
};

TargetSelection.prototype.farthestEnemy = function (pos, team) {
    var enemies = Game.currentGame.alive[team];

    var maxDist = 0;
    var target = undefined;
    enemies.forEach(function (enemy) {
        if (enemy && !enemy.dead) {
            var dist = Epicart.distance(pos, enemy);
            if (dist > maxDist) {
                maxDist = dist;
                target = enemy;
            }
        }
    });

    if (target === undefined) {
        return undefined;
    }

    return {
        target: target,
        distance: maxDist
    };
};

TargetSelection.prototype.randomEnemy = function (pos, team) {
    if (!Game.currentGame.alive[team].length) {
        return undefined;
    }

    var enemy = cleverapps.Random.choose(Game.currentGame.alive[team]);

    return {
        target: enemy,
        distance: Epicart.distance(pos, enemy)
    };
};