/**
 * Created by vladislav on 05/04/2023
 */

var AttackActivity = function (warrior, attack, target, distance, name) {
    Activity.call(this, warrior, name);

    this.attack = attack;
    this.target = target;
    this.distance = distance;

    this.targetSize = this.target.getSize();

    this.firePercent = this.fireAttackPercent();
    this.sound = bundles.units.urls[this.attack.asound + "_attack_effect"];

    if (this.attack.aspd !== undefined) {
        this.updateTimeScale();
        this.aspdListener = this.warrior.on("attrChanged:aspd", this.updateTimeScale.bind(this));
    }
};

AttackActivity.prototype = Object.create(Activity.prototype);
AttackActivity.prototype.constructor = AttackActivity;

AttackActivity.prototype.updateTimeScale = function () {
    var aspd = this.warrior.getAttribute("aspd", this.attack.aspd);

    var realTicks = this.warrior.getDurationTicks(this.name);
    var ticks = Math.round(1 / Epicart.TICK / aspd);

    this.setTimeScale(realTicks / ticks);
};

AttackActivity.prototype.onFire = function () {
    this.warrior.fireAttack(this.attack, this.target, this.targetSize, this.distance);
};

AttackActivity.prototype.onFinish = function () {
    if (this.aspdListener) {
        this.aspdListener.clear();
    }
};

AttackActivity.prototype.fireAttackPercent = function () {
    if (this.attack.firePercent !== undefined) {
        return this.attack.firePercent;
    }

    if (this.attack.range === WarriorLines.RANGE.INFINITY) {
        return 10;
    }

    return 70;
};