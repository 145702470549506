/**
 * Created by mac on 9/16/22
 */

var EpicartScene = GameSceneBase.extend({
    onSceneLoaded: function () {
        this._super();

        this.battlefield = new BattlefieldView();
        this.battlefield.setPositionRound(cleverapps.styles.EpicartScene.battlefield);
        this.battlefield.originalPosition = cleverapps.styles.EpicartScene.battlefield;
        this.scene.addChild(this.battlefield);

        this.attackButton = new AttackButton(this.game.buttonPressed.bind(this.game));
        this.scene.addChild(this.attackButton);

        if (Barracks.isAvailable()) {
            this.barracksIcon = new BarracksIcon();
            this.scene.addChild(this.barracksIcon);
        }

        if (TroopCardsIcon.isAvailable()) {
            this.cardsButton = new TroopCardsIcon();
            this.scene.addChild(this.cardsButton);
        }

        if (cleverapps.user.checkAvailable({
            level: 0.06
        })) {
            this.helpButton = this.createHelpButton();
            this.scene.addChild(this.helpButton);
        }

        this.amountPlayer = new WarriorsAmountView(Warrior.TEAM_PLAYER);
        this.amountEnemy = new WarriorsAmountView(Warrior.TEAM_ENEMY);
        this.scene.addChild(this.amountPlayer);
        this.scene.addChild(this.amountEnemy);

        var orangery = Game.currentGame.orangery;
        if (orangery) {
            this.orangeryView = new OrangeryView(orangery);
            this.addChild(this.orangeryView);
        }

        cleverapps.barracks.on("open", this.createListener(this.onOpenBarracks.bind(this)));
        cleverapps.barracks.on("close", this.createListener(this.onCloseBarracks.bind(this)));

        this.game.on("pause", this.createListener(this.onPause.bind(this)));
        this.game.on("resume", this.createListener(this.onResume.bind(this)));
        this.game.on("startBattle", this.createListener(this.onStartBattle.bind(this)));
        this.game.tutorial.on("showTutorial", this.createListener(this.showTutorial.bind(this)));
        this.game.tutorial.on("stopTutorial", this.createListener(this.stopTutorial.bind(this)));

        if (cleverapps.config.debugMode) {
            cleverapps.debugSlider.createTimeScaleSlider();
            cleverapps.debugSlider.toggle(cleverapps.flags.debugInfo);
        }
    },

    updatePosition: function () {
        this.helpButton.setPositionRound(cleverapps.styles.EpicartScene.helpButton);

        this.listNodesForBarracks().forEach(function (node) {
            var position = node.calculatePositionRound(node.originalPosition);
            if (this.barracksOpen) {
                position.y += cleverapps.styles.EpicartScene.battlefield.moveDy;
            }
            node.setPositionRound(position);
        }, this);
    },

    createHelpButton: function () {
        var styles = cleverapps.styles.EpicartScene;

        var help = new cleverapps.UI.HelpButton(function () {
            cleverapps.meta.display({
                focus: "GuideWindow",
                action: function (f) {
                    new GuideWindow({
                        name: "BattlefieldGuide",
                        bundle: bundles.battlefieldguide
                    });

                    cleverapps.meta.onceNoWindowsListener = f;
                }
            });
        });
        help.setPositionRound(styles.helpButton);

        cleverapps.UI.makeHideable(help, cleverapps.UI.VERTICAL);

        cleverapps.meta.registerControl("helpButton", help);

        return help;
    },

    onPause: function () {
        this.battlefield.performRecursive(function (node) {
            node.pause();
        });

        cleverapps.meta.hideControlsWhileFocused(["SceneControlButtonsView"]);
    },

    onResume: function () {
        this.battlefield.performRecursive(function (node) {
            node.resume();
        });

        cleverapps.meta.showControlsWhileFocused(["SceneControlButtonsView"]);

        this.setEventNodes();
    },

    showTutorial: function (tutorial) {
        this.tutorialView = new BattlefieldTutorialView(this.battlefield);
        this.addChild(this.tutorialView);
        this.tutorialView.setPositionRound(this.width / 2, this.height / 2);
        this.tutorialView.showTutorial(tutorial);
    },

    stopTutorial: function () {
        this.tutorialView.hideTutorial();
        delete this.tutorialView;
    },

    onOpenBarracks: function () {
        this.barracksOpen = true;

        var dy = cleverapps.styles.EpicartScene.battlefield.moveDy;

        this.listNodesForBarracks().forEach(function (node) {
            var position = node.calculatePositionRound(node.originalPosition);
            position.y += dy;
            node.runAction(new cc.MoveTo(0.3, position.x, position.y));
        });
    },

    onCloseBarracks: function () {
        this.barracksOpen = false;

        this.listNodesForBarracks().forEach(function (node) {
            node.runAction(new cc.MoveTo(0.3, node.calculatePositionRound(node.originalPosition)));
        });
    },

    listNodesForBarracks: function () {
        return [this.battlefield, this.background, this.amountPlayer, this.amountEnemy];
    },

    onStartBattle: function () {
        this.startOrRestartMusic({
            res: bundles.game.urls.background_music_battle
        });

        var rewardView = new RewardSoftView();
        rewardView.setPositionRound(cleverapps.styles.EpicartScene.rewardView);
        this.scene.addChild(rewardView);

        if (this.game.reinforcement) {
            this.reinforcementIcon = new ReinforcementIcon(this.game.reinforcement);
            this.scene.addChild(this.reinforcementIcon);
        }

        this.upMenuButtons.refresh();

        this.setEventNodes();

        cleverapps.meta.showControlsWhileFocused(["SceneControlButtonsView"]);
    },

    setEventNodes: function () {
        var eventNodes = [this.battlefield, this.upMenuButtons, this.reinforcementIcon];
        if (cleverapps.config.debugMode) {
            eventNodes.push(this.orangeryView);
        }

        cleverapps.meta.setEventNodes(eventNodes);
    },

    getBackgroundStyles: function () {
        var styles = this._super();

        styles.position = cleverapps.styles.EpicartScene.background.position;
        styles.size = cleverapps.styles.EpicartScene.background.size;
        
        return styles;
    }
});

GameScene = EpicartScene;

cleverapps.styles.EpicartScene = {
    background: {
        position: {
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: 0 }
        },

        size: {
            width: 2900,
            height: 1334
        }
    },

    battlefield: {
        x: { align: "center" },
        y: { align: "center", dy: -40 },

        moveDy: 200
    },

    helpButton: {
        x: { align: "right", dx: -360 },
        y: { align: "bottom", dy: 100 }
    },

    rewardView: {
        x: { align: "center" },
        y: { align: "top", dy: -150 }
    },

    cell: {
        width: Epicart.VIRTUAL_CELL.width,
        height: Epicart.VIRTUAL_CELL.height
    }
};