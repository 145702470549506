/**
 * Created by vladislav on 06/04/2023
 */

var NormalAttack = function (warrior, options) {
    Skill.call(this, warrior, options);
};

NormalAttack.prototype = Object.create(Skill.prototype);
NormalAttack.prototype.constructor = NormalAttack;

NormalAttack.prototype.canUse = function () {
    return true;
};

NormalAttack.prototype.getActivity = function (targetData) {
    return new AttackActivity(this.warrior, this.options, targetData.target, targetData.distance, "attack");
};