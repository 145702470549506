/**
 * Created by vladislav on 06/04/2023
 */

var FlankActivity = function (warrior, target, speed) {
    this.idle = "flank";
    this.target = target;
    this.speed = speed;

    var sign = warrior.head === Warrior.HEAD_RIGHT ? 1 : -1;
    this.targetPosition = cc.p(
        target.x + sign * (target.getRadius() + warrior.getRadius()),
        warrior.y
    );
    this.startPosition = warrior.getPosition();
    this.midPoint = cc.pMidpoint(this.startPosition, this.targetPosition);

    if (warrior.y > Battlefield.GRID.y * Epicart.VIRTUAL_CELL.height / 2) {
        this.midPoint.y += 500;
    } else {
        this.midPoint.y -= 500;
    }

    warrior.ignoresCollision = true;

    Activity.call(this, warrior, "flank");
};

FlankActivity.prototype = Object.create(Activity.prototype);
FlankActivity.prototype.constructor = FlankActivity;

FlankActivity.prototype.getDuration = function () {
    return Math.round(cc.pDistance(this.targetPosition, this.startPosition) / this.speed / Epicart.TICK);
};

FlankActivity.prototype.bezierAt = function (p0, p1, p2, t) {
    var q = Math.pow(1 - t, 2);
    var p = 2 * (1 - t) * t;
    var r = Math.pow(t, 2);

    return p0 * q + p1 * p + p2 * r;
};

FlankActivity.prototype.onTick = function () {
    Activity.prototype.onTick.call(this);

    var dt = this.counter / this.duration;
    var x = this.bezierAt(this.startPosition.x, this.midPoint.x, this.targetPosition.x, dt);
    var y = this.bezierAt(this.startPosition.y, this.midPoint.y, this.targetPosition.y, dt);

    this.warrior.moveVector.x = x - this.warrior.x;
    this.warrior.moveVector.y = y - this.warrior.y;
};

FlankActivity.prototype.onStart = function () {
    Activity.prototype.onTick.call(this);

    Game.currentGame.startSound(bundles.units.urls.magic_apprentice_flank_effect);
};

FlankActivity.prototype.onFinish = function () {
    Activity.prototype.onTick.call(this);

    this.warrior.moveVector.x = 0;
    this.warrior.moveVector.y = 0;

    cleverapps.audio.playSound(bundles.units.urls.magic_apprentice_reversal_effect);

    Game.currentGame.stopSound(bundles.units.urls.magic_apprentice_flank_effect);

    this.warrior.ignoresCollision = false;
};
