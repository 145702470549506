/**
 * Created by vladislav on 02/12/2022
 */

var BarracksSquad = function (info) {
    this.code = info.code;
    this.stage = info.stage;

    this.line = WarriorLines[this.code];

    this.onDragStart = function () {};
    this.onDragEnd = function () {};
    this.onGetView = function () {};
};