/**
 * Created by vladislav on 14/03/2023
 */

var LuckyBonusWindow = Window.extend({
    onWindowLoaded: function () {
        this._super({
            title: "LuckyBonusWindow.title",
            name: "LuckyBonusWindow",
            noBackground: true,
            content: this.createContent()
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.LuckyBonusWindow;

        this.units = cleverapps.troopCards.pickBonusUnits();

        this.icons = this.units.map(function (unit) {
            var icon = new ArmyLibraryIcon(unit.code, unit.stage);

            icon.setCascadeOpacityEnabled(true);
            cleverapps.UI.onClick(icon, this.onClicked.bind(this, unit));
            cleverapps.UI.applyHover(icon);

            return icon;
        }, this);

        this.layout = new cleverapps.Layout(this.icons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.icons.margin
        });

        this.text = cleverapps.UI.generateOnlyText("LuckyBonusWindow.choose", cleverapps.styles.FONTS.WHITE_TEXT);

        var textWrap = new cc.Node();
        textWrap.setAnchorPoint(0.5, 0.5);
        textWrap.setContentSize2(this.text.width, styles.text.height);

        textWrap.addChild(this.text);
        this.text.setPositionRound(textWrap.width / 2, textWrap.height / 2);

        return new cleverapps.Layout([textWrap, this.layout], {
            direction: cleverapps.UI.VERTICAL
        });
    },

    getPrice: function () {
        return TroopCards.LUCKY_BONUS_PRICES[this.units.length - 1];
    },

    createButton: function (unit) {
        var styles = cleverapps.styles.LuckyBonusWindow;

        var price = this.getPrice();

        return new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.small_button_green,
            text: "$$" + price,
            width: styles.button.width,
            height: styles.button.height,
            onClicked: this.onClicked.bind(this, unit)
        });
    },

    onClicked: function (unit) {
        if (this.inAnimation) {
            return;
        }

        var price = this.getPrice();
        if (price) {
            if (cleverapps.user.spendHard(cleverapps.EVENTS.SPENT.LUCKY_BONUS, price)) {
                this.animate(unit);
            }
        } else {
            this.animate(unit);
        }
    },

    animate: function (unit) {
        this.inAnimation = true;

        var styles = cleverapps.styles.LuckyBonusWindow;

        var index = this.units.indexOf(unit);
        var icon = this.icons[index];

        if (icon.button) {
            icon.button.removeFromParent();
        }

        this.layout.setVisible(false);

        var spawnPosition = cleverapps.formation.receiveNewSquad({
            code: unit.code,
            stage: unit.stage
        });

        TroopRewardWindow.spawnAnimation(
            icon, 
            unit,
            spawnPosition,
            icon.parent.convertToWorldSpace(icon.getPosition()),
            function () {
                this.units.splice(index, 1);
                this.icons.splice(index, 1);

                this.layout.setItems(this.icons, false);

                if (this.icons.length === 0) {
                    this.close();

                    return;
                }

                this.icons.forEach(function (icon, index) {
                    if (icon.button) {
                        icon.button.removeFromParent();
                    }

                    icon.button = this.createButton(this.units[index]);
                    icon.addChild(icon.button);
                    icon.button.setPositionRound(styles.button);
                }, this);

                this.layout.setVisible(true);

                this.inAnimation = false;
            }.bind(this)
        );
    }
});

cleverapps.styles.LuckyBonusWindow = {
    icons: {
        margin: 50
    },

    text: {
        height: 300
    },

    button: {
        width: 200,
        height: 100,
        x: { align: "center" },
        y: { align: "bottom", dy: -130 }
    }
};