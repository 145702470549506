/**
 * Created by vladislav on 30/03/2023
 */

var ThrowingKnives = function (warrior, options) {
    Skill.call(this, warrior, options);

    this.usedCount = 0;
};

ThrowingKnives.prototype = Object.create(Skill.prototype);
ThrowingKnives.prototype.constructor = ThrowingKnives;

ThrowingKnives.prototype.canUse = function () {
    return this.usedCount < this.options.skillUsages;
};

ThrowingKnives.prototype.getActivity = function (targetData) {
    this.usedCount++;

    return new AttackActivity(this.warrior, this.options, targetData.target, targetData.distance, "knifeMan");
};