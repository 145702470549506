/**
 * Created by vladislav on 07/12/2022
 */

var BattlefieldView = cc.Node.extend({
    ctor: function () {
        this._super();

        this.battlefield = Game.currentGame.battlefield;

        this.grid = this.createGrid();
        this.addChild(this.grid);
        this.setContentSize2(this.grid.getContentSize());
        this.clickRect = cc.rect(0, 0, this.width, this.height + cleverapps.styles.BattlefieldView.additionalHeight);
        this.grid.setPositionRound(this.width / 2, this.height / 2);

        this.updateSize();

        this.layers = {};
        cleverapps.values(Battlefield.LAYERS).forEach(function (id) {
            this.layers[id] = new cc.Node();
            this.addChild(this.layers[id]);
            this.layers[id].setAnchorPoint(0.5, 0.5);
            this.layers[id].setContentSize2(this.grid.getContentSize());
            this.layers[id].setPositionRound(this.width / 2, this.height / 2);
        }, this);

        this.setLocalZOrder(11);

        this.addToLayer(Battlefield.LAYERS.UNITS, new HighlightView(this.battlefield.highlight));

        this.battlefield.listSquads().forEach(function (squad) {
            this.addToLayer(Battlefield.LAYERS.UNITS, new SquadView(squad));
        }, this);

        this.battlefield.on("spawnSquad", this.createListener(this.spawnSquad.bind(this)));
        this.battlefield.on("spawnWarrior", this.createListener(this.spawnWarrior.bind(this)));
        this.battlefield.on("addToLayer", this.createListener(this.addToLayer.bind(this)));
        this.battlefield.on("spawnArea", this.createListener(this.spawnArea.bind(this)));
        this.battlefield.on("hideGrid", this.createListener(this.hideGrid.bind(this)));
        this.battlefield.onGetCell = this.createListener(this.getCell.bind(this));
        this.battlefield.onGetView = this.createListener(function () {
            return this;
        });

        Game.currentGame.on("startBattle", this.createListener(function () {
            if (this.setupDragHandler) {
                this.setupDragHandler.remove();
                this.setupDragHandler = undefined;
            }

            this.addBattleControls();

            if (Game.currentGame.reinforcement) {
                var reinforcementFlag = new ReinforcementFlag(Game.currentGame.reinforcement);
                this.addToLayer(Battlefield.LAYERS.UNITS, reinforcementFlag);
            }
        }.bind(this)));
        Game.currentGame.on("rewardSoft", this.createListener(this.showRewardText.bind(this)));

        this.addSetupControls();

        Game.currentGame.on("startBattle", this.createListener(this.scheduleUpdate.bind(this)));
    },

    updateSize: function () {
        var styles = cleverapps.styles.BattlefieldView;

        cleverapps.UI.fitToBox(this, {
            width: cleverapps.UI.getSceneSize().width - styles.widthOffset,
            height: styles.height
        });
    },

    update: function (dt) {
        Game.currentGame.update(dt);
    },

    addToLayer: function (layer, node) {
        if (node.parent) {
            node.replaceParentSamePlace(this.layers[layer]);
        } else {
            this.layers[layer].addChild(node);
        }
    },

    calcDragDiff: function (touch) {
        var pos = touch.getLocation();
        var startPos = touch.getStartLocation();

        return cc.p((pos.x - startPos.x) / this.scale, (pos.y - startPos.y) / this.scale);
    },

    addSetupControls: function () {
        this.setupDragHandler = cleverapps.UI.onDrag(this, {
            instantDrag: true,

            followPointer: function (touch) {
                this.battlefield.handleFollowPointer(this.calcDragDiff(touch));
            }.bind(this),

            onClick: this.onClickDebugSetup.bind(this),

            onDragStart: function (touch) {
                var startPos = this.convertToNodeSpace(touch.getStartLocation());
                return this.battlefield.handleDragStart(startPos);
            }.bind(this),

            onDragMove: function (touch) {
                this.battlefield.handleDragMove(this.calcDragDiff(touch));
            }.bind(this),

            onDragEnd: function () {
                this.battlefield.handleDragEnd();
            }.bind(this)
        });
    },

    addBattleControls: function () {
        var dragged;
        var draggedView;
        var basePos;

        cleverapps.UI.onDrag(this, {
            onClick: function (touch) {
                if (cleverapps.config.debugMode && cleverapps.keyboardController.isPressed(cc.KEY.shift)) {
                    this.onClickDebugBattle(touch);
                } else if (Game.currentGame.reinforcement) {
                    Game.currentGame.reinforcement.fieldClicked(this.convertTouchToNodeSpace(touch));
                }
            }.bind(this),

            followPointer: function (touch) {
                if (dragged.dead) {
                    return;
                }

                var displacement = draggedView.parent.convertTouchToNodeSpaceDisplacement(touch);
                dragged.setPosition(basePos.x + displacement.x, basePos.y + displacement.y);

                if (Game.currentGame.battlefield.isInDeleteZone(dragged)) {
                    draggedView.setColor(cc.color.RED);
                } else {
                    draggedView.setColor(cc.color.WHITE);
                }
            },

            onDragStart: function (touch) {
                dragged = undefined;

                if (!cleverapps.keyboardController.isPressed(cc.KEY.shift)) {
                    return;
                }

                var pos = this.convertToNodeSpace(touch.getStartLocation());

                dragged = Game.currentGame.findWarriorForDrag(pos);
                if (dragged) {
                    console.log(dragged);
                    dragged.runActivity(new DragActivity(dragged));
                    draggedView = dragged.onGetView();
                    basePos = draggedView.getPosition();
                    return true;
                }
            }.bind(this),

            onDragEnd: function (touch) {
                if (dragged.dead) {
                    return;
                }

                if (Game.currentGame.battlefield.isInDeleteZone(dragged)) {
                    dragged.dead = true;
                    dragged.afterDied();

                    return;
                }

                var ds = this.convertTouchToNodeSpaceDisplacement(touch);

                var pos = cc.p(basePos.x + ds.x, basePos.y + ds.y);

                dragged.setPosition(pos.x, pos.y);

                dragged.runActivity(undefined);
            }.bind(this)
        });
    },

    showRewardText: function (amount, options) {
        if (!options) {
            return;
        }

        var styles = cleverapps.styles.BattlefieldView;

        var koef = Epicart.virtualToReal();

        var x = options.x * koef;
        var y = options.y * koef + styles.killReward.offsetY;

        var text = new TextWithIcon("@@" + amount, {
            font: cleverapps.styles.FONTS.KILL_REWARD_TEXT
        });
        this.addToLayer(Battlefield.LAYERS.REWARDS, text);
        text.setPositionRound(x, y);
        text.setCascadeOpacityEnabled(true);
        text.runAction(new cc.Sequence(
            new cc.PlaySound(bundles.units.urls.coins_unit_effect),
            new cc.Spawn(
                new cc.FadeOut(1),
                new cc.MoveBy(1, 0, styles.killReward.animation.dy)
            ),
            new cc.RemoveSelf()
        ));
    },

    hideGrid: function () {
        for (var i = 0; i < this.cells.length; i++) {
            for (var j = 0; j < this.cells[i].length; j++) {
                this.cells[i][j].runAction(new cc.FadeOut(0.6));
            }
        }
    },

    createGrid: function () {
        var styles = cleverapps.styles.EpicartScene;

        var node = new cc.Node();
        node.setContentSize2(styles.cell.width * Battlefield.GRID.x, styles.cell.height * Battlefield.GRID.y);
        node.setAnchorPoint(0.5, 0.5);

        this.cells = [];
        for (var i = 0; i < Battlefield.GRID.x; i++) {
            this.cells.push([]);
            for (var j = 0; j < Battlefield.GRID.y; j++) {
                var cell = new BattleFieldCellView(i, j);
                node.addChild(cell);

                this.cells[i].push(cell);
            }
        }

        return node;
    },

    getCell: function (x, y) {
        return this.cells[x][y];
    },

    spawnSquad: function (squad, options) {
        options = options || {};

        var squadView = new SquadView(squad);

        this.addToLayer(Battlefield.LAYERS.UNITS, squadView);

        if (options.animate) {
            this.mergeAnimation(squad);

            squadView.setScale(0.1);
            squadView.runAction(new cc.Sequence(
                new cc.DelayTime(0.1),
                new cc.ScaleTo(0.2, 1.1).easing(cc.easeOut(2)),
                new cc.ScaleTo(0.2, 1).easing(cc.easeIn(2))
            ));
        }
    },

    spawnArea: function (area) {
        var areaView = new AreaView(area);
        this.addToLayer(Battlefield.LAYERS.AREAS, areaView);
    },

    spawnWarrior: function (warrior) {
        var warriorView = new WarriorView(warrior);
        this.addToLayer(Battlefield.LAYERS.UNITS, warriorView);
        warriorView.syncPosition();
    },

    mergeAnimation: function (squad) {
        var animation = new cleverapps.Spine(bundles.battlefield.jsons.merge_animation_json);
        this.addToLayer(Battlefield.LAYERS.ANIMATIONS, animation);
        animation.setAnimation(0, "animation", false);
        animation.setPositionRound(Battlefield.cellPosToReal(squad.x, squad.y));
        animation.setCompleteListenerRemove();

        cleverapps.audio.playSound(bundles.units.urls.prepare_merge_effect);
    }
});

BattlefieldView.prototype.onClickDebugSetup = function (touch) {
    if (!cleverapps.config.debugMode) {
        return;
    }

    var orangery = Game.currentGame.orangery || Editor.currentEditor.orangery;

    var selected = orangery.selected;

    var pos = this.convertTouchToNodeSpace(touch);

    var squad = this.battlefield.findSquadForDrag(pos);

    if (squad) {
        pos.x = squad.x;
        pos.y = squad.y;
    } else {
        pos = this.battlefield.alignInGrid(pos.x, pos.y);
    }

    if (!Game.currentGame.battlefield.isWithinBounds(pos.x, pos.y)) {
        return;
    }

    var team = Battlefield.getTeamByX(pos.x);

    if (cleverapps.environment.isEditorScene() && team === Warrior.TEAM_ENEMY) {
        Editor.currentEditor.editorClicked(pos);
    } else if (selected) {
        if (selected.eraser) {
            this.battlefield.removeSquadAt(pos.x, pos.y);
        } else {
            this.battlefield.createSquadDebug(selected, pos);
        }
    }
};

BattlefieldView.prototype.onClickDebugBattle = function (touch) {
    if (!cleverapps.keyboardController.isPressed(cc.KEY.shift)) {
        return;
    }

    var orangery = Game.currentGame.orangery || Editor.currentEditor.orangery;

    var selected = orangery.selected;

    var pos = this.convertTouchToNodeSpace(touch);

    var team = Battlefield.getTeamByX(this.battlefield.alignInGrid(pos.x, pos.y).x);

    if (!selected || selected.eraser) {
        var options = cleverapps.unique(this.battlefield.listSquads(team).map(function (squad) {
            return {
                code: squad.code,
                stage: squad.stage
            };
        }), function (squad) {
            return squad.code + "_" + squad.stage;
        });

        selected = cleverapps.Random.mathChoose(options);
    }

    var coef = Epicart.realToVirtual();

    this.battlefield.spawnSquadDuringBattle({
        code: selected.code,
        stage: selected.stage,
        team: team
    }, pos.x * coef, pos.y * coef);
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    KILL_REWARD_TEXT: {
        name: "nostroke",
        size: 35,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.BattlefieldView = {
    height: 700,

    widthOffset: 450,

    additionalHeight: 200,

    killReward: {
        offsetY: 200,

        animation: {
            dy: 50
        }
    },

    removeUnitY: 300
};