/**
 * Created by vladislav on 07/12/2022
 */

var Battlefield = function (size, enemyFormation) {
    cleverapps.EventEmitter.call(this);

    this.borders = cc.rect(
        0, 
        0,
        Battlefield.GRID.x * Epicart.VIRTUAL_CELL.width,
        Battlefield.GRID.y * Epicart.VIRTUAL_CELL.height
    );

    if (!size) {
        size = {
            x: Battlefield.GRID.x / 2,
            y: Battlefield.GRID.y
        };
    }

    this.region = cc.rect(
        Battlefield.GRID.x / 2 - size.x,
        Math.ceil((Battlefield.GRID.y - size.y) / 2),
        2 * size.x,
        size.y
    );

    this.field = [];
    for (var x = 0; x < Battlefield.GRID.x; x++) {
        this.field.push([]);
        for (var y = 0; y < Battlefield.GRID.y; y++) {
            this.field[x].push(undefined);
        }
    }

    this.highlight = new Highlight();

    this.getPlayerFormation().forEach(function (info) {
        if (this.isWithinBounds(info.x, info.y, Warrior.TEAM_PLAYER)) {
            var squad = new Squad(info, Warrior.TEAM_PLAYER);

            this.field[squad.x][squad.y] = squad;
        } else {
            cleverapps.formation.removeSquad(info.x, info.y);
            cleverapps.barracks.recall(info);
        }
    }, this);

    enemyFormation.forEach(function (info) {
        if (this.isWithinBounds(info.x, info.y, Warrior.TEAM_ENEMY)) {
            var squad = new Squad(info, Warrior.TEAM_ENEMY);

            this.field[squad.x][squad.y] = squad;
        }
    }, this);

    this.onGetCell = function () {};
    this.onGetView = function () {};
};

Battlefield.prototype = Object.create(cleverapps.EventEmitter.prototype);
Battlefield.constructor = Battlefield;

Battlefield.prototype.getCell = function (x, y) {
    return this.onGetCell(x, y);
};

Battlefield.prototype.findSquadForDrag = function (pos) {
    for (var i = 0; i < this.field.length; i++) {
        for (var j = 0; j < this.field[i].length; j++) {
            var squad = this.field[i][j];
            if (squad && squad.onGetView().containsPoint(pos)) {
                return squad;
            }
        }
    }
};

Battlefield.prototype.handleDragStart = function (startPos) {
    var squad = this.findSquadForDrag(startPos);

    if (!squad) {
        return;
    }

    if (squad.team === Warrior.TEAM_ENEMY && !cleverapps.config.debugMode) {
        return;
    }

    if (cleverapps.config.debugMode) {
        console.log(squad);
    }

    if (squad.handleDragStart()) {
        this.draggedSquad = squad;
        Game.currentGame.tutorial.onDragStart();
        return true;
    }
};

Battlefield.prototype.handleFollowPointer = function (diff) {
    if (!this.draggedSquad) {
        return;
    }

    var pos = Battlefield.cellPosToReal(this.draggedSquad.x, this.draggedSquad.y);
    pos.x += diff.x;
    pos.y += diff.y;

    this.draggedSquad.handleFollowPointer(pos);
};

Battlefield.prototype.handleDragMove = function (diff) {
    if (!this.draggedSquad) {
        return;
    }

    var pos = Battlefield.cellPosToReal(this.draggedSquad.x, this.draggedSquad.y);
    pos.x += diff.x;
    pos.y += diff.y;

    this.draggedSquad.handleDragMove(pos);
};

Battlefield.prototype.handleDragEnd = function () {
    if (!this.draggedSquad) {
        return;
    }

    this.draggedSquad.handleDragEnd();

    Game.currentGame.tutorial.onDragEnd();

    this.draggedSquad = undefined;
};

Battlefield.prototype.deploy = function (team, teamEnemy) {
    this.listSquads(Warrior.TEAM_PLAYER).forEach(function (squad) {
        squad.deploy(team);
    });

    this.listSquads(Warrior.TEAM_ENEMY).forEach(function (squad) {
        squad.deploy(teamEnemy);
    }); 
};

Battlefield.prototype.getPlayerFormation = function () {
    return cleverapps.formation.listSquads();
};

Battlefield.prototype.isOccupied = function (x, y) {
    return this.field[x][y];
};

Battlefield.prototype.listSquads = function (team) {
    var squads = [];
    for (var x = 0; x < this.field.length; x++) {
        for (var y = 0; y < this.field[x].length; y++) {
            var squad = this.field[x][y];
            if (squad && (team === undefined || squad.team === team)) {
                squads.push(squad);
            }
        }
    }

    return squads;
};

Battlefield.prototype.highlightMerge = function (squad) {
    var canMerge = false;
    this.listSquads(squad.team).forEach(function (other) {
        if (Squad.CanMerge(squad, other)) {
            canMerge = true;

            other.highlight(true);
        }
    });

    if (canMerge) {
        this.mergeClueEffect = cleverapps.audio.playSound(bundles.battlefield.urls.merge_clue_effect, {
            loop: true
        });
    }
};

Battlefield.prototype.unHighlightMerge = function () {
    this.listSquads().forEach(function (squad) {
        squad.highlight(false);
    });

    if (this.mergeClueEffect) {
        cleverapps.audio.stopSound(this.mergeClueEffect);
        delete this.mergeClueEffect;
    }
};

Battlefield.prototype.removeSquadAt = function (x, y) {
    var squad = this.findSquad(x, y);
    if (squad) {
        this.removeSquad(squad.x, squad.y);

        return squad;
    }
};

Battlefield.prototype.removeSquad = function (x, y) {
    var squad = this.findSquad(x, y);

    this.field[squad.x][squad.y] = undefined;

    squad.remove();

    cleverapps.formation.removeSquad(squad.x, squad.y);

    this.trigger("removeSquad", squad);
};

Battlefield.prototype.merge = function (squadA, squadB, fromBarracks) {
    if (!fromBarracks) {
        this.removeSquad(squadA.x, squadA.y);
    }
    this.removeSquad(squadB.x, squadB.y);

    var code = squadB.code;
    var stage = squadB.stage + 1;

    if (!cleverapps.armyLibrary.isOpened(code, stage)) {
        cleverapps.meta.displayWhenFreeFocus({
            focus: "NewUnitWindowAfterMerge",
            actions: [
                function (f) {
                    setTimeout(f, 500);
                },
                function (f) {
                    new NewTroopWindow(code, stage);

                    cleverapps.meta.onceNoWindowsListener = f;
                }
            ]
        });
    }

    this.spawnSquad(
        {
            code: code,
            stage: stage,
            x: squadB.x,
            y: squadB.y
        },
        squadA.team,
        {
            animate: true
        }
    );

    Game.currentGame.tutorial.onMerge();
};

Battlefield.prototype.moveSquad = function (squad, x, y) {
    if (squad.team === Warrior.TEAM_PLAYER) {
        cleverapps.formation.moveSquad(squad.x, squad.y, x, y);
    }

    var oldX = squad.x;
    var oldY = squad.y;
    var otherSquad = this.findSquad(x, y);

    if (otherSquad) {
        otherSquad.moveTo(squad.x, squad.y);
    }

    this.field[squad.x][squad.y] = otherSquad;
    this.field[x][y] = squad;

    squad.moveTo(x, y);

    this.trigger("moveSquad", squad, oldX, oldY);
};

Battlefield.prototype.spawnSquad = function (info, team, options) {
    if (team === Warrior.TEAM_PLAYER) {
        cleverapps.formation.addSquad(info);
    }

    var squad = new Squad(info, team);

    return this.addSquad(squad, options);
};

Battlefield.prototype.addSquad = function (squad, options) {
    this.field[squad.x][squad.y] = squad;

    this.trigger("spawnSquad", squad, options);

    Game.currentGame.counter.trigger();

    return squad;
};

Battlefield.prototype.findSquad = function (x, y) {
    return this.field[x][y];
};

Battlefield.prototype.addToLayer = function (layer, node) {
    this.trigger("addToLayer", layer, node);
};

Battlefield.prototype.alignInGrid = function (x, y) {
    var koef = Epicart.realToVirtual();

    var virtual = cc.p(x * koef, y * koef);

    return cc.p(
        Math.floor(virtual.x / Epicart.VIRTUAL_CELL.width),
        Math.floor(virtual.y / Epicart.VIRTUAL_CELL.height)
    );
};

Battlefield.prototype.spawnSquadDuringBattle = function (info, x, y) {
    var squad = new Squad({
        x: 0,
        y: 0,
        code: info.code,
        stage: info.stage
    }, info.team);
    squad.listWarriors().forEach(function (warrior) {
        warrior.setPosition(warrior.squadX + x, warrior.squadY + y);

        Game.currentGame.teams[warrior.team].push(warrior);

        this.trigger("spawnWarrior", warrior);
    }, this);
};

Battlefield.cellPosToReal = function (x, y) {
    return cc.p(
        (x + 0.5) * cleverapps.styles.EpicartScene.cell.width,
        (y + 0.5) * cleverapps.styles.EpicartScene.cell.height
    );
};

Battlefield.prototype.isWithinBounds = function (x, y, team) {
    if (team !== undefined) {
        if (team === Warrior.TEAM_PLAYER) {
            return x >= this.region.x && x < Math.floor(Battlefield.GRID.x / 2)
                && y >= this.region.y && y < this.region.y + this.region.height;
        }

        return x < this.region.x + this.region.width && x >= Math.floor(Battlefield.GRID.x / 2)
            && y >= this.region.y && y < this.region.y + this.region.height;
    }

    return x >= this.region.x && y >= this.region.y
        && x < this.region.x + this.region.width && y < this.region.y + this.region.height;
};

Battlefield.getTeamByX = function (x) {
    if (x < Math.floor(Battlefield.GRID.x / 2)) {
        return Warrior.TEAM_PLAYER;
    }

    return Warrior.TEAM_ENEMY;
};

Battlefield.LAYERS = {};
Battlefield.LAYERS.AREAS = 0;
Battlefield.LAYERS.UNITS = 1;
Battlefield.LAYERS.ANIMATIONS = 2;
Battlefield.LAYERS.REWARDS = 3;

Battlefield.GRID = {
    x: 12,
    y: 6
};

if (cleverapps.config.debugMode) {
    Battlefield.prototype.createSquadDebug = function (data, pos) {
        this.removeSquadAt(pos.x, pos.y);

        var info = {
            code: data.code,
            stage: data.stage,
            x: pos.x,
            y: pos.y
        };

        var team = this.isWithinBounds(pos.x, pos.y, Warrior.TEAM_ENEMY) ? Warrior.TEAM_ENEMY : Warrior.TEAM_PLAYER;

        Game.currentGame.battlefield.spawnSquad(info, team);
    };

    Battlefield.prototype.isInDeleteZone = function (unit) {
        var styles = cleverapps.styles.BattlefieldView;

        return unit.onGetView().y > cc.director.getRunningScene().height - styles.removeUnitY;
    };
}