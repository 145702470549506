/**
 * Created by vladislav on 02/03/2023
 */

var SortIcon = cc.Node.extend({
    ctor: function (sortings, onClickCallback) {
        this.sortings = sortings;
        this.onClickCallback = onClickCallback;

        this.current = 0;

        this._super();
        this.setAnchorPoint(0.5, 0.5);

        var styles = cleverapps.styles.SortIcon;

        this.icon = new cc.Sprite(this.sortings[this.current].icon);
        this.addChild(this.icon);
        this.setContentSize2(this.icon.getContentSize());
        this.icon.setPositionRound(this.width / 2, this.height / 2);

        var arrow = new cc.Sprite(bundles.barracks.frames.sort_arrow);
        this.addChild(arrow);
        arrow.setPositionRound(styles.arrow);

        cleverapps.UI.wrap(this);

        cleverapps.UI.onClick(this, this.onClick.bind(this));
        cleverapps.UI.applyHover(this);
    },

    onClick: function () {
        this.current = (this.current + 1) % this.sortings.length;

        this.icon.setSpriteFrame(this.sortings[this.current].icon);

        this.onClickCallback(this.getFunc());
    },

    getFunc: function () {
        return this.sortings[this.current].func;
    }
});

cleverapps.styles.SortIcon = {
    arrow: {
        x: { align: "right", dx: 15 },
        y: { align: "bottom", dy: -15 }
    }
};