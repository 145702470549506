/**
 * Created by vladislav on 29/11/2022
 */

var Highlight = function () {
    this.pos = undefined;

    this.onMove = function () {};
};

Highlight.prototype.move = function (pos, team) {
    if (pos && Game.currentGame.battlefield.isWithinBounds(pos.x, pos.y, team)) {
        this.pos = pos;
    } else {
        this.pos = undefined;
    }

    this.onMove();
};

Highlight.prototype.stop = function () {
    this.pos = undefined;

    this.onMove();
};

Highlight.prototype.getPosition = function () {
    return this.pos;
};