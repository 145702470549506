/**
 * Created by vladislav on 30/11/2022
 */

var BarracksWindow = Window.extend({
    ctor: function () {
        this._super({
            name: "BarracksWindow",
            content: this.createContent(),
            closeButton: false,
            closeByBackButton: false,
            noBackground: true,
            styles: cleverapps.overrideStyles(cleverapps.styles.Window, {
                windowShowUpAnimation: false,
                shadow: false
            }, true),
            noScaleAdjustment: true
        });

        this.grid.sortItems(this.sortIcon.getFunc());

        cleverapps.barracks.on("close", this.createListener(this.close.bind(this)), this);
        cleverapps.barracks.on("addSquad", this.createListener(this.addSquad.bind(this)), this);
        cleverapps.barracks.on("deploy", this.createListener(this.deploy.bind(this)), this);
        cleverapps.barracks.on("startDrag", this.createListener(this.disableScroll.bind(this)), this);
        cleverapps.barracks.on("stopDrag", this.createListener(this.enableScroll.bind(this)), this);
        cleverapps.barracks.on("refresh", this.createListener(this.refresh.bind(this)), this);

        this.setLocalZOrder(10);

        cleverapps.barracks.onGetView = this.createListener(function () {
            return this;
        }.bind(this));
    },

    onShow: function () {
        this._super();

        cleverapps.meta.setEventNodes([this, cc.director.getRunningScene().battlefield]);
    },

    chooseAnimation: function () {
        return {
            open: function (callback) {
                this.bg.show();

                this.runAction(new cc.Sequence(
                    new cc.DelayTime(0.3),
                    new cc.CallFunc(callback)
                ));
            }.bind(this),

            close: function (callback) {
                this.bg.hide();

                this.runAction(new cc.Sequence(
                    new cc.DelayTime(0.3),
                    new cc.CallFunc(callback)
                ));
            }.bind(this)
        };
    },

    createContent: function () {
        var styles = cleverapps.styles.BarracksWindow;

        this.bg = new cc.Scale9Sprite(bundles.barracks.frames.dark_bg);
        this.bg.setContentSize2(cleverapps.UI.getSceneSize().width, styles.height);

        this.content = this.bg;

        var closeButton = new cleverapps.UI.Button({
            type: {
                button_png: bundles.buttons_main.frames.window_close_png,
                button_on_png: bundles.buttons_main.frames.window_close_on_png
            },
            onClicked: cleverapps.barracks.close.bind(cleverapps.barracks)
        });
        this.bg.addChild(closeButton);
        closeButton.setPositionRound(styles.closeButton);

        this.scroll = this.createScroll();
        this.bg.addChild(this.scroll);
        this.scroll.setPositionRound((styles.scroll.padding.left + this.bg.width - styles.scroll.padding.right) / 2, this.bg.height / 2);

        var buttons = this.createButtons();
        this.bg.addChild(buttons);
        buttons.setPositionRound(styles.buttons);

        var contentWrap = new cc.Node();
        contentWrap.setAnchorPoint(0.5, 0.5);
        contentWrap.setContentSize(cleverapps.UI.getSceneSize());
        contentWrap.addChild(this.bg);
        this.bg.setPositionRound(contentWrap.width / 2, this.bg.height / 2 - cleverapps.UI.getSceneOffsetY());

        this.bg.hidePosition = cc.p(this.bg.width / 2, -this.bg.height / 2);

        cleverapps.UI.makeHideable(this.bg, cleverapps.UI.VERTICAL);

        this.bg.hide(true);

        return contentWrap;
    },

    disableScroll: function () {
        this.scroll.touchScrollDisabled = true;
    },

    enableScroll: function () {
        this.scroll.touchScrollDisabled = false;
    },

    refresh: function () {
        var views = [];

        cleverapps.barracks.listSquads().forEach(function (squad) {
            var view = squad.onGetView();
            if (!view) {
                view = new BarracksSquadView(squad);
            }

            views.push(view);
        }, this);

        this.views = views;

        this.grid.setItems(views);

        this.grid.sortItems(this.sortIcon.getFunc());

        this.updateContainerSize();
    },

    addSquad: function (squad) {
        var view = new BarracksSquadView(squad);

        this.views.push(view);

        this.grid.addItems(view);

        this.grid.sortItems(this.sortIcon.getFunc());

        this.updateContainerSize();
    },

    deploy: function (squad) {
        var view = this.views.find(function (view) {
            return view.barracksSquad === squad;
        });

        this.grid.removeItems(view);

        this.grid.sortItems(this.sortIcon.getFunc());

        this.updateContainerSize();
    },

    createScroll: function () {
        var sceneWidth = cc.director.getRunningScene().width;

        var styles = cleverapps.styles.BarracksWindow;

        this.views = cleverapps.barracks.listSquads().map(function (squad) {
            return new BarracksSquadView(squad);
        });

        var columns = Math.floor((sceneWidth - styles.scroll.padding.left - styles.scroll.padding.right)
            / (styles.itemWidth + styles.scroll.margin));
        this.grid = new cleverapps.GridLayout(this.views, {
            columns: columns,
            padding: styles.padding,
            margin: {
                x: styles.scroll.margin,
                y: styles.scroll.margin
            }
        });

        this.gridContainer = new cc.Node();
        this.gridContainer.addChild(this.grid);

        this.availableWidth = columns * (styles.itemWidth + styles.scroll.margin) - styles.scroll.margin;

        this.updateContainerSize();

        var scroll = new cleverapps.UI.ScrollView(this.gridContainer, {
            direction: cleverapps.UI.ScrollView.DIR_VERTICAL
        });
        scroll.setContentSize2(this.availableWidth, styles.scroll.height);
        scroll.setBarPadding(styles.scroll.barPadding);

        return scroll;
    },

    updateContainerSize: function () {
        var styles = cleverapps.styles.BarracksWindow;

        var currentScroll = this.scroll && this.scroll.getCurrentScroll();

        this.gridContainer.setContentSize2(
            this.availableWidth,
            Math.max(this.grid.height, styles.height)
        );
        this.grid.setPositionRound({ align: "left" }, { align: "top" });
        this.gridContainer.setPositionRound(0, 0);
        if (this.scroll) {
            this.scroll.updateInnerContent();
            this.scroll.scrollToPercent((1 - currentScroll.y) * 100);
        }
    },

    createButtons: function () {
        var styles = cleverapps.styles.BarracksWindow;

        var amount = new BarracksAmountView();

        this.sortIcon = new SortIcon(
            BarracksWindow.SORTINGS,
            function (sortFunc) {
                this.grid.sortItems(sortFunc);
            }.bind(this)
        );

        var deleteIcon = new cc.Sprite(bundles.barracks.frames.delete_icon);

        var iconsLayout = new cleverapps.Layout([this.sortIcon, deleteIcon], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.icons.margin
        });

        var combineAll = new cleverapps.UI.Button({
            text: "combineAll.button",
            width: styles.button.width,
            height: styles.button.height,
            onClicked: cleverapps.barracks.combineAll.bind(cleverapps.barracks)
        });

        var recallAll = new cleverapps.UI.Button({
            text: "recallAll.button",
            width: styles.button.width,
            height: styles.button.height,
            onClicked: cleverapps.barracks.recallAll.bind(cleverapps.barracks)
        });

        return new cleverapps.Layout([amount, iconsLayout, combineAll, recallAll], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    }
});

BarracksWindow.DEFAULT_SORT = function (iconA, iconB) {
    return iconA.barracksSquad.line.base.id - iconB.barracksSquad.line.base.id
        || iconB.barracksSquad.stage - iconA.barracksSquad.stage;
};

BarracksWindow.SORTINGS = [
    {
        icon: bundles.barracks.frames.sort_icon_race,
        func: function (iconA, iconB) {
            return iconA.barracksSquad.line.base.race - iconB.barracksSquad.line.base.race
                || BarracksWindow.DEFAULT_SORT(iconA, iconB);
        }
    },
    {
        icon: bundles.barracks.frames.sort_icon_rarity,
        func: function (iconA, iconB) {
            return iconB.barracksSquad.line.base.rarity - iconA.barracksSquad.line.base.rarity
                || BarracksWindow.DEFAULT_SORT(iconA, iconB);
        }
    },
    {
        icon: bundles.barracks.frames.sort_icon_level,
        func: function (iconA, iconB) {
            return iconB.barracksSquad.stage - iconA.barracksSquad.stage
                || BarracksWindow.DEFAULT_SORT(iconA, iconB);
        }
    }
];

cleverapps.styles.BarracksWindow = {
    x: { align: "center" },
    y: { align: "bottom" },

    height: 450,

    itemWidth: 150,

    scroll: {
        height: 446,

        padding: {
            left: 280,
            right: 110
        },

        barPadding: {
            cornerPadding: 10,
            sidePadding: 10
        },

        margin: 20
    },

    amount: {
        x: { align: "center" },
        y: { align: "top", dy: -30 }
    },

    margin: 20,

    icons: {
        margin: 30
    },

    button: {
        width: 200,
        height: 90
    },

    buttons: {
        x: { align: "left", dx: 35 },
        y: { align: "top", dy: -20 }
    },

    padding: {
        y: 20
    },

    closeButton: {
        x: { align: "right", dx: -20 },
        y: { align: "top", dy: -20 }
    }
};