/**
 * Created by vladislav on 30/11/2022
 */

var AttackButton = cleverapps.UI.Button.extend({
    ctor: function (onClick) {
        var styles = cleverapps.styles.AttackButton;

        this._super({
            text: "attack.button",
            width: styles.width,
            height: styles.height,
            type: cleverapps.styles.UI.Button.Images.button_red,
            onClicked: function () {
                cleverapps.meta.display({
                    focus: "Attack",
                    control: ["warriorsAmount0", "warriorsAmount1", "rewardSoft", "reinforcementIcon", "Orangery"],
                    action: onClick
                });
            }
        });

        cleverapps.UI.makeHideable(this, cleverapps.UI.VERTICAL);
        cleverapps.meta.registerControl("attackButton", this);

        this.setPositionRound(styles);
    },

    updatePosition: function () {
        this.setPositionRound(cleverapps.styles.AttackButton);
    }
});

cleverapps.styles.AttackButton = {
    width: 220,
    height: 110,
    x: { align: "center" },
    y: { align: "center", dy: -500 }
};