/**
 * Created by vladislav on 14/12/2022
 */

var BattleStats = function () {

};

BattleStats.prototype.calcTotal = function (type, team) {
    return Game.currentGame.battlefield.listSquads(team).reduce(function (total, squad) {
        return total + squad.getStat(type);
    }, 0);
};

BattleStats.TYPES = {};
BattleStats.TYPES.DAMAGE = "dmg";
BattleStats.TYPES.DAMAGE_RECEIVED = "dmg_received";
BattleStats.TYPES.DAMAGE_BLOCKED = "dmg_blocked";