/**
 * Created by vladislav on 09/12/2022
 */

var TroopCardsIcon = cleverapps.Layout.extend({
    ctor: function () {
        var styles = cleverapps.styles.TroopCardsIcon;

        this.icon = new cc.Sprite(bundles.battlefield.frames.troops_shop_icon_png);

        var bar = new TroopCardsProgressBar({
            width: styles.bar.width
        });

        this.attention = new Attention();
        this.icon.addChild(this.attention);
        this.attention.setPositionRound(cleverapps.styles.TroopCardsIcon.attention);
        this.attention.setVisible(false);

        this._super([this.icon, bar], {
            margin: styles.margin,
            direction: cleverapps.UI.VERTICAL
        });

        if (cleverapps.troopCards.isBeginner()) {
            this.setProduct(cleverapps.troopCards.getBeginnerProduct());
        }

        cleverapps.UI.applyHover(this);

        cleverapps.UI.onClick(this, this.onClicked.bind(this));

        cleverapps.UI.makeHideable(this, cleverapps.UI.VERTICAL);

        cleverapps.meta.registerControl("troopCardsIcon", this);

        cleverapps.troopCards.on("showForce", this.createListener(this.showForce.bind(this)), this);
        cleverapps.user.on("changeSoft", this.createListener(function () {
            this.setAttention(cleverapps.troopCards.needsAttention());

            if (this.price) {
                this.updatePriceTextVariant();
            }
        }.bind(this)), this);

        this.setAttention(cleverapps.troopCards.needsAttention());

        this.setPositionRound(styles);
    },

    updatePosition: function () {
        this.setPositionRound(cleverapps.styles.TroopCardsIcon);
    },

    setProduct: function (product) {
        this.product = product;

        if (!product) {
            if (this.price) {
                this.price.removeFromParent();
                delete this.price;
            }

            return;
        }

        if (this.price) {
            this.updatePriceText();
            this.updatePriceTextVariant();
        } else {
            this.price = this.createPrice(this.product.price);
            this.addChild(this.price);
        }
    },

    updatePriceText: function () {
        this.price.setString(this.product.price === 0 ? "FREE" : "@@" + this.product.price);
    },

    updatePriceTextVariant: function () {
        this.price.setTextVariant(this.product.price === 0 || cleverapps.user.canTakeSoft(this.product.price)
            ? cleverapps.UI.Button.TEXT_REGULAR
            : cleverapps.UI.Button.TEXT_EMPHASIS);
    },

    showForce: function (force, product) {
        this.show(true);

        this.realProduct = this.product;
        if (product) {
            this.setProduct(product);
        }

        cleverapps.forces.create(this, force);
    },

    createPrice: function (price) {
        var styles = cleverapps.styles.TroopCardsIcon;

        var isFree = price === 0;

        var button = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.button_green,
            text: isFree ? "FREE" : "@@" + price,
            textVariant: isFree || cleverapps.user.canTakeSoft(price)
                ? cleverapps.UI.Button.TEXT_REGULAR
                : cleverapps.UI.Button.TEXT_EMPHASIS,
            onClicked: this.onClicked.bind(this),
            width: styles.price.width,
            height: styles.price.height
        });
        button.setPositionRound(styles.price);

        return button;
    },

    onClicked: function () {
        var product = this.product;

        if (cleverapps.forces.isRunningForce()) {
            this.setProduct(this.realProduct);

            cleverapps.forces.closeRunningForce();
        }

        cleverapps.meta.display({
            focus: "TroopCardsWindow",
            control: ["MenuBarCoinsItem"],
            action: function (f) {
                cleverapps.audio.playSound(bundles.main.urls.click_effect);

                if (product) {
                    if (cleverapps.troopCards.hasSpace()) {
                        cleverapps.troopCards.buy(product);

                        cleverapps.meta.onceNoWindowsListener = f;
                    } else {
                        cleverapps.notification.create("NoSpace");

                        f();
                    }
                } else {
                    new TroopCardsWindow();

                    cleverapps.meta.onceNoWindowsListener = f;
                }
            }
        });
    },

    setAttention: function (attention) {
        if (this.attention.isVisible() === attention) {
            return;
        }

        this.attention.setVisible(attention);
        this.attention.stopAllActions();
        this.attention.pulse();
    }
});

TroopCardsIcon.isAvailable = function () {
    return cleverapps.user.checkAvailable({
        level: 0.06
    });
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    TROOP_CARD_BAR_TEXT: {
        size: 25,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.TroopCardsIcon = {
    x: { align: "right", dx: -50 },
    y: { align: "center", dy: -500 },

    attention: {
        x: { align: "right", dx: -100 },
        y: { align: "top", dy: -20 }
    },

    margin: 30,

    bar: {
        width: 250
    },

    price: {
        width: 230,
        height: 90,

        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 40 }
    }
};