/**
 * Created by vladislav on 25/04/2023
 */

var ReinforcementIcon = cc.Scale9Sprite.extend({
    ctor: function (reinforcement) {
        this.reinforcement = reinforcement;

        this._super(bundles.reinforcement.frames.bg_png);

        var styles = cleverapps.styles.ReinforcementIcon;

        this.setContentSize2(styles);
        this.setPositionRound(styles);

        var innerBg = new cc.Scale9Sprite(bundles.reinforcement.frames.bg_inner_png);
        this.addChild(innerBg);
        innerBg.setContentSize2(styles.innerBg);
        innerBg.setPositionRound(styles.innerBg);

        var icon = WarriorView.getUnitImage(
            WarriorLines[this.reinforcement.spawnUnit.code],
            this.reinforcement.spawnUnit.stage,
            true
        );
        this.addChild(icon);
        cleverapps.UI.fitToBox(icon, {
            height: styles.unit.height
        });
        icon.setPositionRound(styles.unit);

        this.timer = new cleverapps.Spine(bundles.reinforcement.jsons.timer_json);
        this.addChild(this.timer);
        this.timer.setPositionRound(styles.timer);

        this.active = true;

        cleverapps.UI.onClick(this, this.createListener(this.reinforcement.spawn.bind(this.reinforcement)));

        cleverapps.UI.applyHover(this);

        var duration = this.timer.getAnimationData("animation").duration * 1000;
        this.timeScale = duration / Reinforcement.COOLDOWN;

        cleverapps.UI.makeHideable(this, cleverapps.UI.VERTICAL);

        cleverapps.meta.registerControl("reinforcementIcon", this);

        this.reinforcement.onChangeAmount = this.createListener(this.changeAmount.bind(this));
        this.reinforcement.onStartRecharge = this.createListener(this.startRecharge.bind(this));
        this.reinforcement.onPauseRecharge = this.createListener(this.pauseRecharge.bind(this));
        this.reinforcement.onResumeRecharge = this.createListener(this.resumeRecharge.bind(this));
    },

    pauseRecharge: function () {
        this.timer.setTimeScale(0);
    },

    resumeRecharge: function () {
        this.timer.setTimeScale(this.timeScale);
    },

    startRecharge: function () {
        this.timer.setTimeScale(this.timeScale);
        this.timer.setAnimation(0, "animation", false);
        this.timer.setCompleteListener(function () {
            this.reinforcement.recharge();
        }.bind(this));
    },

    changeAmount: function () {
        var active = this.reinforcement.amount > 0;
        if (this.active !== active) {
            this.active = active;

            if (active) {
                this.setSpriteFrame(new cc.Sprite(bundles.reinforcement.frames.bg_png).getSpriteFrame());
            } else {
                this.setSpriteFrame(new cc.Sprite(bundles.reinforcement.frames.bg_inactive_png).getSpriteFrame());
            }
        }
    }
});

cleverapps.styles.ReinforcementIcon = {
    width: 213,
    height: 252,

    innerBg: {
        width: 170,
        height: 180,

        x: { align: "center", dx: -4 },
        y: { align: "bottom", dy: 20 }
    },

    timer: {
        x: { align: "center", dx: -5 },
        y: { align: "center", dy: 3 }
    },

    unit: {
        height: 150,
        x: { align: "center", dx: -20 },
        y: { align: "bottom", dy: 40 }
    },

    x: { align: "left", dx: 50 },
    y: { align: "center", dy: -500 }
};