/**
 * Created by mac on 4/22/20
 */

var EpicArtEditorView = EditorViewBase.extend({
    ctor: function (editor) {
        if (resolutionScale < 1) {
            throw "resolutionScale must be 1 in editor!";
        }

        this._super(editor);

        this.battlefield = new BattlefieldView();
        this.battlefield.setPositionRound(cleverapps.styles.EpicartScene.battlefield);
        this.addChild(this.battlefield);

        this.addChild(new BattleFieldSizeView());

        var playerPR = new EditorPowerRatingView(Warrior.TEAM_PLAYER);
        var enemyPR = new EditorPowerRatingView(Warrior.TEAM_ENEMY);
        this.addChild(playerPR);
        this.addChild(enemyPR);

        this.addChild(new GenerateArmyButton());

        var gitButtons = new GitButtonsView();
        this.addChild(gitButtons);
        gitButtons.setPositionRound(cleverapps.styles.EpicArtEditorView.gitButtons);

        Editor.currentEditor.onUpdatePowerRating = this.createListener(function () {
            playerPR.updateRating();
            enemyPR.updateRating();
        });

        editor.onUpdateSize = this.createListener(this.recreateBattlefield.bind(this));
    },

    recreateBattlefield: function () {
        this.battlefield.removeFromParent();

        this.battlefield = new BattlefieldView();
        this.battlefield.setPositionRound(cleverapps.styles.EpicartScene.battlefield);
        this.addChild(this.battlefield);
    }
});

EditorView = EpicArtEditorView;

cleverapps.styles.EpicArtEditorView = {
    gitButtons: {
        x: { align: "center", dx: 300 },
        y: { align: "top", dy: -20 }
    }
};

cleverapps.overrideStyles(cleverapps.styles.EditorControlsView, {
    y: { align: "center", dy: 200 }
});
