/**
 * Created by vladislav on 06/04/2023
 */

var DragActivity = function (warrior) {
    Activity.call(this, warrior, "drag");

    this.idle = "idle";
};

DragActivity.prototype = Object.create(Activity.prototype);
DragActivity.prototype.constructor = DragActivity;

DragActivity.prototype.getDuration = function () {
    return Infinity;
};

DragActivity.prototype.onStart = function () {
    this.warrior.dragged = true;
};

DragActivity.prototype.onFinish = function () {
    this.warrior.dragged = false;
};