/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["luckyBonus"] = {
    type: Placements.FREE_FOCUS_GAME,

    filter: function () {
        return cleverapps.troopCards.isLuckyBonusReady();
    },

    action: function () {
        cleverapps.troopCards.showLuckyBonus();
    }
};

Placements.ENTRIES["troopCardsForce"] = {
    type: Placements.FREE_FOCUS,
    priority: -1001,

    filter: function () {
        return Game.currentGame && cleverapps.troopCards.isForceAvailable();
    },

    action: function () {
        cleverapps.meta.display({
            focus: "TroopCardsForce",
            control: ["MenuBarCoinsItem"],
            action: function (f) {
                cleverapps.troopCards.showForce(f, Forces.TROOP_CARDS_FORCE);

                cleverapps.forces.onceForceClosed = f;
            }
        });
    }
};

Placements.ENTRIES["newTroopWindow"] = {
    type: Placements.FREE_FOCUS_GAME,

    filter: function () {
        return cleverapps.armyLibrary.getUnopenedAvailable() !== undefined;
    },

    action: function () {
        var code = cleverapps.armyLibrary.getUnopenedAvailable();

        cleverapps.meta.display({
            focus: "NewTroopWindow",
            actions: [
                function (f) {
                    new NewTroopWindow(code);

                    cleverapps.meta.onceNoWindowsListener = f;
                },
                function (f) {
                    cleverapps.troopCards.showNewUnitForce(f, code);
                }
            ]
        });
    }
};

Placements.ENTRIES["softForce"] = {
    type: Placements.FREE_FOCUS_GAME,

    filter: function () {
        return !cleverapps.forces.isShown(Forces.MENU_BAR_SOFT_FORCE.id)
            && cleverapps.user.checkAvailable(Forces.MENU_BAR_SOFT_FORCE.available);
    },

    action: function () {
        cleverapps.menuBar.showForce("CoinsItem");
    }
};

Placements.ENTRIES["playButtonForce"] = {
    type: Placements.FREE_FOCUS_MAIN,

    filter: function () {
        return !cleverapps.forces.isShown(Forces.METHA_PLAY_BUTTON.id);
    },

    action: function () {
        cleverapps.meta.display({
            focus: "PlayButtonForce",
            action: function (f) {
                cleverapps.scenes.getRunningScene().showPlayButtonForce(f);
            }
        });
    }
};
