/**
 * Created by vladislav on 06/04/2023
 */

var ReverseActivity = function (warrior) {
    Activity.call(this, warrior, "reverse");

    this.firePercent = 100;
};

ReverseActivity.prototype = Object.create(Activity.prototype);
ReverseActivity.prototype.constructor = ReverseActivity;

ReverseActivity.prototype.onFire = function () {
    this.warrior.turnAround();
};