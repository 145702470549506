/**
 * Created by vladislav on 25/05/2023
 */

var TroopStageView = cc.Sprite.extend({
    ctor: function (stage) {
        this._super(bundles.troop_icon.frames["stage_badge_" + (stage + 1)]);

        var styles = cleverapps.styles.TroopStageView;

        var text = cleverapps.UI.generateImageText(stage + 1, cleverapps.styles.FONTS.WHITE_TEXT);
        this.addChild(text);
        text.fitTo(styles.text.width, styles.text.height);
        text.setPositionRound(styles.text[stage] || styles.text);
    }
});

cleverapps.styles.TroopStageView = {
    text: {
        width: 40,
        height: 50,

        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 11 },

        "0": {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        },

        "9": {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 4 }
        }
    }
};