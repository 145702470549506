/**
 * Created by vladislav on 25/04/2023
 */
var ReinforcementFlag = cleverapps.Spine.extend({
    ctor: function (reinforcement) {
        this.reinforcement = reinforcement;
        
        this._super(bundles.reinforcement.jsons.flag_json);

        this.setAnimation(0, "idle", true);

        this.setAnchorPoint(0.2, 0.1);

        this.updatePosition();

        this.reinforcement.onMoveSpawn = this.createListener(this.onMoveSpawn.bind(this));
    },

    onMoveSpawn: function () {
        this.updatePosition();

        this.setAnimationAndIdleAfter("animation", "idle");

        var circle = new cleverapps.Spine(bundles.reinforcement.jsons.flag_circle_json);
        circle.setAnimation(0, "animation", false);
        circle.setCompleteListenerRemove();
        this.parent.addChild(circle);
        circle.setPositionRound(this.getPosition());
        circle.setLocalZOrder(this.getLocalZOrder() - 1);
    },

    updatePosition: function () {
        var coef = Epicart.virtualToReal();

        this.setPositionRound(this.reinforcement.spawnPosition.x * coef, this.reinforcement.spawnPosition.y * coef);

        this.setLocalZOrder(-this.reinforcement.spawnPosition.y);
    }
});