/**
 * Created by vladislav on 10/04/2023
 */

var Area = function (position, duration, shape, shapeSize, effects, sourceWarrior, source) {
    this.duration = duration;
    this.shape = shape;
    this.shapeSize = shapeSize;
    this.effects = effects;
    this.position = position;
    this.sourceWarrior = sourceWarrior;
    this.source = source;

    this.durationInTicks = Math.round(this.duration / Epicart.TICK);
    this.ticks = 0;

    this.onRemove = function () {};
};

Area.prototype.onTick = function () {
    if (this.ticks % Area.TRIGGER_INTERVAL === 0) {
        Game.currentGame.applyEffects(
            this.shape, 
            this.shapeSize, 
            this.effects, 
            this.position,
            Warrior.HEAD_ANY, 
            this.sourceWarrior,
            this
        );
    }

    this.ticks++;
};

Area.prototype.isFinished = function () {
    return this.ticks === this.durationInTicks;
};

Area.prototype.onFinish = function () {
    this.onRemove();
};

Area.TRIGGER_INTERVAL = 30;