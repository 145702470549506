/**
 * Created by vladislav on 02/03/2023
 */

cleverapps.override(cleverapps.Tool.game, {
    debugInfo: {
        flag: "debugInfo"
    },
    noDeath: {
        flag: "noDeath"
    },
    endlessBattle: {
        flag: "endlessBattle"
    },
    unitBorders: {
        flag: "unitBorders"
    },
    addLuckPoints: function () {
        cleverapps.troopCards.addPoints(TroopCards.LUCKY_POINTS);
    },
    clearBarracks: function () {
        var squads = cleverapps.barracks.listSquads().slice();

        squads.forEach(function (squad) {
            cleverapps.barracks.deploy(squad);
        });
    },
    clearPlayerUnits: function () {
        var squads = Game.currentGame.battlefield.listSquads(Warrior.TEAM_PLAYER).slice();

        squads.forEach(function (squad) {
            Game.currentGame.battlefield.removeSquad(squad.x, squad.y);
        });
    },
    clearEnemyUnits: function () {
        var squads = Game.currentGame.battlefield.listSquads(Warrior.TEAM_ENEMY).slice();

        squads.forEach(function (squad) {
            Game.currentGame.battlefield.removeSquad(squad.x, squad.y);
        });
    },
    fillBarracks: function () {
        var amount = cleverapps.barracks.listSquads().length;

        for (var i = 0; i < Barracks.MAX_AMOUNT - amount; i++) {
            cleverapps.barracks.addSquad({
                code: cleverapps.Random.choose(Object.values(WarriorLines.CODES)),
                stage: cleverapps.Random.random(0, 9)
            });
        }
    }

});
