/**
 * Created by vladislav on 10/04/2023
 */

var BuffEffect = function (type, warrior, options, sourceWarrior, source) {
    Effect.call(this, type, warrior, options, sourceWarrior, source);
};

BuffEffect.prototype = Object.create(Effect.prototype);
BuffEffect.prototype.constructor = BuffEffect;

BuffEffect.prototype.applyModifier = function (attr, value) {
    if (this.options.buffs[attr]) {
        return value * this.options.buffs[attr];
    }

    return value;
};

BuffEffect.prototype.onStart = function () {
    Effect.prototype.onStart.call(this);

    for (var attr in this.options.buffs) {
        this.warrior.trigger("attrChanged:" + attr);
    }
};

BuffEffect.prototype.onFinish = function () {
    Effect.prototype.onFinish.call(this);

    for (var attr in this.options.buffs) {
        this.warrior.trigger("attrChanged:" + attr);
    }
};