/**
 * Created by vladislav on 20/02/2023
 */

var TroopProbabilityView = cc.Scale9Sprite.extend({
    ctor: function () {
        this._super(bundles.troop_cards.frames.probabilities_bg);

        this.setContentSize2(cleverapps.styles.TroopProbabilityView);
    },

    setProbabilities: function (probabilities) {
        if (this.layout) {
            this.layout.removeFromParent();
        }

        this.layout = this.createLayout(probabilities);
        this.addChild(this.layout);
        this.layout.setPositionRound(this.width / 2, this.height / 2);
    },

    createLayout: function (probabilities) {
        var styles = cleverapps.styles.TroopProbabilityView;

        var title = cleverapps.UI.generateOnlyText("Probability", cleverapps.styles.FONTS.WHITE_TEXT);

        var rows = probabilities.map(function (probability, index) {
            var rarity = cleverapps.UI.generateOnlyText(Messages.get("Rarity." + index) + ":", cleverapps.styles.FONTS.SMALL_WHITE_TEXT);
            rarity.setColor(cleverapps.styles.COLORS["RARITY_COLOR_" + index]);

            var prob = cleverapps.UI.generateImageText(probability * 100 + "%", cleverapps.styles.FONTS.TROOP_CARD_PROB);

            var node = new cc.Node();
            node.setAnchorPoint(0.5, 0.5);
            node.setContentSize2(styles.probability.width, rarity.height);

            node.addChild(rarity);
            rarity.setPositionRound(styles.probability.rarity);

            node.addChild(prob);
            prob.setPositionRound(styles.probability.prob);

            return node;
        });

        return new cleverapps.Layout([title].concat(rows), {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.probability.margin,
            padding: styles.padding
        });
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    TROOP_CARD_TEXT: {
        name: "nostroke",
        size: 45,
        color: cleverapps.styles.COLORS.WHITE
    },
    TROOP_CARD_PROB: {
        name: "nostroke",
        size: 35,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.TroopProbabilityView = {
    width: 400,
    height: 250,

    probability: {
        width: 350,

        margin: 5,

        rarity: {
            x: { align: "left" },
            y: { align: "center" }
        },

        prob: {
            x: { align: "right", anchor: "center", dx: -50 },
            y: { align: "center" }
        }
    }
};