/**
 * Created by vladislav on 10/04/2023
 */

var DOTEffect = function (type, warrior, options, sourceWarrior, source) {
    Effect.call(this, type, warrior, options, sourceWarrior, source);
};

DOTEffect.prototype = Object.create(Effect.prototype);
DOTEffect.prototype.constructor = DOTEffect;

DOTEffect.prototype.getDuration = function () {
    return (this.options.dmgCount - 1) * this.options.dmgInterval + 1;
};

DOTEffect.prototype.refresh = function () {
    this.ticks %= this.options.dmgInterval;
};

DOTEffect.prototype._onTick = function () {
    if (this.ticks % this.options.dmgInterval === 0) {
        this.warrior.receiveDamage(this.options.dmg, Warrior.HEAD_ANY, this.sourceWarrior, this);
    }
};
