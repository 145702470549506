/**
 * Created by vladislav on 09/12/2022
 */

var TroopCardsProgressBar = ScaledProgressBar.extend({
    ctor: function (options) {
        this.options = options || {};

        var points = this.options.points !== undefined ? this.options.points : cleverapps.troopCards.getPoints();

        var styles = cleverapps.styles.TroopCardsProgressBar;

        this._super({
            background: ScaledProgressBar.Types.green.background,
            progress: ScaledProgressBar.Types.green.progress,
            barText: {
                text: points + "/" + TroopCards.LUCKY_POINTS,
                font: cleverapps.styles.FONTS.TROOP_CARD_BAR_TEXT
            }
        });

        this.setLength(this.options.width || styles.width);
        this.setPercentage(cleverapps.troopCards.getPercentage());
        this.setAnchorPoint(0.5, 0.5);

        if (this.options.showIcons) {
            var cardsIcon = new cc.Sprite(bundles.lucky_bonus.frames.cards_icon);
            this.addChild(cardsIcon);
            cardsIcon.setPositionRound(styles.cardsIcon);
        }

        cleverapps.troopCards.on("updatePoints", this.createListener(this.updatePoints.bind(this)), this);
    },

    updatePoints: function (points) {
        var styles = cleverapps.styles.TroopCardsProgressBar;

        this.animatedChange(cleverapps.troopCards.getPercentage());
        this.updateBarText(cleverapps.troopCards.getPoints() + "/" + TroopCards.LUCKY_POINTS);

        if (this.options.animateDelta) {
            this.runAction(AnimationsLibrary.animateDelta(points, {
                target: this,
                font: cleverapps.styles.FONTS.SMALL_WHITE_TEXT
            }));

            this.barText.runAction(new cc.Sequence(
                new cc.ScaleTo(0.3, 1.2),
                new cc.ScaleTo(0.3, 1)
            ));
        }
    }
});

cleverapps.styles.TroopCardsProgressBar = {
    width: 500,

    cardsIcon: {
        x: { align: "right", dx: 20 },
        y: { align: "center", dy: 0 }
    }
};