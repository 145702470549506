/**
 * Created by vladislav on 09/12/2022
 */

var TroopCards = function () {
    cleverapps.EventEmitter.call(this);

    this.load();
};

TroopCards.prototype = Object.create(cleverapps.EventEmitter.prototype);
TroopCards.prototype.constructor = TroopCards;

TroopCards.prototype.isForceAvailable = function () {
    return !cleverapps.forces.isShown(Forces.TROOP_CARDS_FORCE.id)
        && cleverapps.user.level === 1 && cleverapps.user.episode === 0;
};

TroopCards.prototype.showForce = function (f, force, product) {
    this.trigger("showForce", force, product);

    cleverapps.forces.onceForceClosed = f;
};

TroopCards.prototype.showNewUnitForce = function (f, code) {
    this.forceCode = code;

    this.showForce(f, {
        text: "TroopCards.newUnitForce"
    }, {
        price: 0,
        points: 4,
        stage: 0
    });
};

TroopCards.prototype.hasSpace = function () {
    return cleverapps.formation.findEmptySlot() || Barracks.isAvailable() && !cleverapps.barracks.isFull();
};

TroopCards.prototype.pickBonusUnits = function () {
    var brackets = [
        [WarriorLines.RARITY.EPIC, WarriorLines.RARITY.LEGENDARY],
        [WarriorLines.RARITY.RARE, WarriorLines.RARITY.EPIC, WarriorLines.RARITY.LEGENDARY],
        [WarriorLines.RARITY.COMMON, WarriorLines.RARITY.RARE, WarriorLines.RARITY.EPIC, WarriorLines.RARITY.LEGENDARY]
    ];

    var codes;
    for (var i = 0; i < brackets.length; i++) {
        codes = cleverapps.armyLibrary.listBy({
            rarity: brackets[i]
        }, true);

        if (codes.length >= 3) {
            break;
        }
    }

    while (codes.length < 3) {
        codes.push(codes[0]);
    }

    return cleverapps.Random.chooseAmount(codes, 3).map(function (code) {
        return {
            code: code,
            stage: 2
        };
    });
};

TroopCards.prototype.buy = function (product) {
    if (cleverapps.user.spendSoft(cleverapps.EVENTS.SPENT.TROOP_CARDS, product.price)) {
        this.addPoints(product.points, true);

        var code = this.pickCode(product);

        new TroopRewardWindow({
            code: code,
            stage: product.stage
        }, product);

        delete this.forceCode;

        return true;
    }

    return false;
};

TroopCards.prototype.isBeginner = function () {
    return this.calcPrice() < 900;
};

TroopCards.prototype.needsAttention = function () {
    return this.listProducts().some(function (product) {
        return cleverapps.user.canTakeSoft(product.price);
    });
};

TroopCards.prototype.listProducts = function () {
    if (this.isBeginner()) {
        return [this.getBeginnerProduct()];
    }

    return ["troops900", "troops1900", "troops3800", "troops7500"].map(function (name) {
        return VirtualProducts[name];
    });
};

TroopCards.prototype.calcPrice = function () {
    return Math.min(Math.round(9 * cleverapps.user.getHumanReadableNumber() / 10) * 10, 900);
};

TroopCards.prototype.getBeginnerProduct = function () {
    return {
        probabilities: [0.5, 0.5, 0],
        price: this.calcPrice(),
        points: 1,
        stage: 0
    };
};

TroopCards.prototype.pickCode = function (product) {
    if (this.forceCode !== undefined) {
        return this.forceCode;
    }

    return TroopCards.PickCode(product.probabilities);
};

TroopCards.prototype.addPoints = function (points, silent) {
    this.points += points;

    if (!silent) {
        this.trigger("updatePoints", points);
    }

    this.save();
};

TroopCards.prototype.isLuckyBonusReady = function () {
    return this.points >= TroopCards.LUCKY_POINTS;
};

TroopCards.prototype.showLuckyBonus = function () {
    cleverapps.meta.display({
        focus: "LuckyBonusWindow",
        control: "MenuBarGoldItem",
        action: function (f) {
            this.addPoints(-TroopCards.LUCKY_POINTS);

            new LuckyBonusWindow();

            cleverapps.meta.onceNoWindowsListener = f;
        }.bind(this)
    });
};

TroopCards.prototype.getPoints = function () {
    return this.points;
};

TroopCards.prototype.getPercentage = function () {
    return this.points / TroopCards.LUCKY_POINTS * 100;
};

TroopCards.prototype.load = function () {
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.TROOP_CARDS) || {};

    this.points = data.points || 0;
};

TroopCards.prototype.save = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.TROOP_CARDS, {
        points: this.points
    });
};

TroopCards.PickCode = function (probabilities) {
    probabilities = probabilities.slice();
    probabilities.forEach(function (probability, rarity) {
        var codes = cleverapps.armyLibrary.listBy({
            rarity: rarity
        }, true);

        if (codes.length === 0) {
            probabilities[rarity] = 0;
        }
    });
    var sum = probabilities.reduce(function (acc, val) {
        return acc + val;
    }, 0);

    probabilities = probabilities.map(function (probability) {
        return probability / sum;
    });

    var rarity = cleverapps.Random.pickOne(probabilities);

    return cleverapps.Random.mathChoose(cleverapps.armyLibrary.listBy({
        rarity: rarity
    }, true));
};

TroopCards.LUCKY_POINTS = 50;
TroopCards.LUCKY_BONUS_PRICES = [800, 400];