/**
 * Created by mac on 7/24/17.
 */

var RewardSoftView = cc.Node.extend({
    ctor: function () {
        var styles = cleverapps.styles.RewardSoftView;

        this._super();
        this.setContentSize2(styles);
        this.setAnchorPoint(0.5, 0.5);

        this.text = new TextWithIcon("@@0", cleverapps.styles.FONTS.SMALL_WHITE_TEXT);
        this.addChild(this.text);
        this.text.setPositionRound(this.width / 2, this.height / 2);

        Game.currentGame.on("rewardSoft", this.createListener(function () {
            this.text.stopAllActions();

            this.text.runAction(new cc.Sequence(
                new cc.ScaleTo(0.2, 1.3),
                new cc.CallFunc(function () {
                    this.text.setString("@@" + Game.currentGame.rewards[GameBase.REWARD_SOFT]);
                }.bind(this)),
                new cc.ScaleTo(0.2, 1.0)
            ));
        }.bind(this)));

        cleverapps.UI.makeHideable(this, cleverapps.UI.VERTICAL);

        cleverapps.meta.registerControl("rewardSoft", this);
    }
});

cleverapps.styles.RewardSoftView = {
    width: 200,
    height: 70,

    icon: {
        x: { align: "left", dx: 20 },
        y: { align: "center" }
    },

    text: {
        x: { align: "right", dx: -20 },
        y: { align: "center" }
    }
};