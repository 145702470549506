/**
 * Created by vladislav on 13/12/2022
 */

var BattleFieldCellView = cc.Sprite.extend({
    ctor: function (x, y) {
        this.cellX = x;
        this.cellY = y;

        this._super(Game.currentGame.battlefield.isWithinBounds(this.cellX, this.cellY, Warrior.TEAM_PLAYER)
            ? bundles.formation.frames.grid1
            : bundles.formation.frames.grid0);

        this.setAnchorPoint(0, 0);
        this.setPositionRound({
            x: this.cellX * cleverapps.styles.EpicartScene.cell.width,
            y: this.cellY * cleverapps.styles.EpicartScene.cell.height
        });

        this.setCascadeOpacityEnabledRecursively(true);
    }
});