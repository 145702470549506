/**
 * Created by vladislav on 14/12/2022
 */

var EditorPowerRatingView = cc.Node.extend({
    ctor: function (team) {
        this._super();

        this.team = team;

        var rating = Epicart.calcArmyPowerRating(Game.currentGame.battlefield.listSquads(this.team));

        this.text = cleverapps.UI.generateOnlyText(rating, cleverapps.styles.FONTS.EDITOR_BIG_TEXT);
        this.addChild(this.text);
        this.setContentSize2(this.text.getContentSize());
        this.text.setPositionRound(this.width / 2, this.height / 2);

        this.setPositionRound(cleverapps.styles.EditorPowerRatingView[this.team]);
    },

    updateRating: function () {
        var rating = Epicart.calcArmyPowerRating(Game.currentGame.battlefield.listSquads(this.team));

        this.text.setString(rating);
    }
});

cleverapps.styles.EditorPowerRatingView = {};

cleverapps.styles.EditorPowerRatingView[Warrior.TEAM_PLAYER] = {
    x: { align: "center", dx: -400 },
    y: { align: "center", dy: 400 }
};

cleverapps.styles.EditorPowerRatingView[Warrior.TEAM_ENEMY] = {
    x: { align: "center", dx: 400 },
    y: { align: "center", dy: 400 }
};
