/**
 * Created by vladislav on 08/12/2022
 */

var ArrowView = cleverapps.Spine.extend({
    ctor: function (warrior, attack) {
        this.warrior = warrior;
        this.attack = attack;

        this._super(WarriorView.UnitArrowSpine(this.warrior.code));

        var styles = cleverapps.styles.ArrowView["unit" + this.warrior.code];
        this.setPositionRound(styles);

        if (this.hasAnimation("showup")) {
            this.setAnimationAndIdleAfter("showup", "fly");
        } else {
            this.setIdle("fly");
        }

        if (this.hasSkin(this.warrior.stage)) {
            this.setSkin(this.warrior.stage);
        }

        this.head = this.warrior.head;
        if (this.head === Warrior.HEAD_LEFT) {
            this.setScaleX(-1);
        }
    },

    chooseTrajectory: function (time, target) {
        switch (this.attack.projectile.type) {
            case WarriorLines.PROJECTILE.ARROW:
                return this.trajectoryFixedAngleArc(time, target);
            case WarriorLines.PROJECTILE.BOMB:
                return this.trajectoryArc(time, target);
            case WarriorLines.PROJECTILE.SIMPLE:
                return this.trajectorySimple(time, target);
        }
    },

    trajectoryArc: function (time, target) {
        var first = this.getPosition();
        var third = target;

        var midpoint = cc.pMidpoint(first, third);

        midpoint.y = Math.max(first.y, third.y) + cleverapps.styles.ArrowView.trajectory.y;

        return new cc.QuadraticBezier(time, [first, midpoint, third]).easing(cc.easeOutIn(1.12));
    },

    trajectoryFixedAngleArc: function (time, target) {
        var unitStyles = cleverapps.styles.ArrowView["unit" + this.warrior.code];

        var angle = unitStyles.angle / 180 * Math.PI;
        var first = this.getPosition();
        var third = target;

        var midpoint = cc.pMidpoint(first, third);

        var beta = Math.abs(Math.atan2(midpoint.y - first.y, midpoint.x - first.x));
        if (beta > Math.PI / 2) {
            beta = Math.PI - beta;
        }

        var dist = cc.pDistance(midpoint, first) * Math.cos(beta) / Math.cos(angle);
        var second = cc.pAdd(first, cc.pMult(cc.pForAngle(this.warrior.head === Warrior.HEAD_LEFT ? Math.PI - angle : angle), dist));

        return new cc.QuadraticBezier(time, [first, second, third]).easing(cc.easeOutIn(1.12));
    },

    trajectorySimple: function (time, target) {
        return new cc.MoveTo(time, target);
    },

    shoot: function (options, callback) {
        this.setLocalZOrder(-this.y);

        var time = options.distance / this.attack.projectile.speed;
        var styles = cleverapps.styles.ArrowView;

        var unitStyles = cleverapps.styles.ArrowView["unit" + this.warrior.code];

        var target = cc.pMult(options, Epicart.virtualToReal());
        var offsetX = this.warrior.x < options.x ? -styles.target.x : styles.target.x;
        target.x += offsetX;
        target.y += options.size.height / 2;

        var action = this.chooseTrajectory(time, target);

        this.runAction(
            new cc.Sequence(
                action,
                new cc.CallFunc(function () {
                    this.setAnimation(0, "damage", false);
                    this.setCompleteListenerRemove();
                    callback();
                }.bind(this))
            )
        );

        if (this.attack.projectile.type === WarriorLines.PROJECTILE.SIMPLE) {
            var angle = -Math.atan2(target.y - this.y, target.x - this.x) * 180 / Math.PI;
            if (this.head === Warrior.HEAD_LEFT) {
                angle -= 180;
            }
            this.setRotation(angle);
        }

        if (this.attack.projectile.type === WarriorLines.PROJECTILE.ARROW) {
            this.setRotation(this.head === Warrior.HEAD_LEFT ? unitStyles.angle : -unitStyles.angle);

            this.prevPos = this.getPosition();
            this.scheduleUpdate();
        }
    },

    update: function (dt) {
        this._super(dt);

        if (this.prevPos.x !== this.x && this.prevPos.y !== this.y) {
            var rotation = -Math.atan2(this.y - this.prevPos.y, this.x - this.prevPos.x) * 180 / Math.PI;
            if (this.head === Warrior.HEAD_LEFT) {
                rotation -= 180;
            }
            this.setRotation(rotation);
        }

        this.prevPos = this.getPosition();
    }
});

cleverapps.styles.ArrowView = {
    target: {
        x: 30
    },

    trajectory: {
        y: 300
    },

    "unit1": {
        x: { align: "center", dx: 25 },
        y: { align: "center", dy: 50 },
        angle: 42
    },

    "unit3": {
        x: { align: "center", dx: 120 },
        y: { align: "center", dy: 40 },
        angle: 45
    },

    "unit4": {
        x: { align: "right", dx: -55 },
        y: { align: "top", dy: 5 },
        angle: 35
    },

    "unit6": {
        x: { align: "center", dx: 88 },
        y: { align: "center", dy: 93 }
    },

    "unit7": {
        x: { align: "center", dx: 80 },
        y: { align: "center", dy: 40 }
    },

    "unit9": {
        x: { align: "right", dx: 20 },
        y: { align: "top", dy: -94 },
        angle: 30
    },

    "unit11": {
        x: { align: "right", dx: 20 },
        y: { align: "top", dy: -94 },
        angle: 30
    }
};