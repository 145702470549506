/**
 * Created by vladislav on 15/12/2022
 */

var BattleStatIcon = cleverapps.Layout.extend({
    ctor: function (squad, type) {
        this.squad = squad;

        var styles = cleverapps.styles.BattleStatIcon;

        this.backlight = this.createBacklight();

        this.bg = this.createBg();
        this.backlight.addChild(this.bg);
        this.bg.setPositionRound(this.backlight.width / 2, this.backlight.height / 2);

        this.icon = this.createIcon();
        this.backlight.addChild(this.icon);
        this.icon.setPositionRound(this.backlight.width / 2, this.backlight.height / 2);

        this.backlight.addChild(this.createLevel());

        var text = cleverapps.UI.generateImageText(squad.getStat(type), cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);

        this._super([this.backlight, text], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createBg: function () {
        var styles = cleverapps.styles.BattleStatIcon;

        var background = new cc.Scale9Sprite(bundles.troop_icon.frames.unit_bg);
        background.setContentSize2(styles.background);
        background.setPositionRound(this.backlight.width / 2, this.backlight.height / 2);

        return background;
    },

    createLevel: function () {
        var styles = cleverapps.styles.BattleStatIcon;

        var bg = new cc.Sprite(bundles.troop_icon.frames.level_bg);
        bg.setScale(0.7);
        bg.setPositionRound(styles.level);

        var level = cleverapps.UI.generateOnlyText(this.squad.stage + 1, cleverapps.styles.FONTS.WHITE_TEXT);
        bg.addChild(level);
        level.setPositionRound(bg.width / 2, bg.height / 2);

        if (this.squad.isDead()) {
            cleverapps.UI.convertToGrayScale(bg);
            cleverapps.UI.convertToGrayScale(level);
        }

        return bg;
    },

    createBacklight: function () {
        var styles = cleverapps.styles.BattleStatIcon;

        var backlight = new cc.Scale9Sprite(bundles.troop_icon.frames["backlight_" + WarriorLines[this.squad.code].base.rarity]);
        backlight.setContentSize2(styles.backlight);

        return backlight;
    },

    createIcon: function () {
        var styles = cleverapps.styles.BattleStatIcon;

        var icon = WarriorView.getUnitImage(WarriorLines[this.squad.code], this.squad.stage);
        cleverapps.UI.fitToBox(icon, styles.icon);
        icon.setPositionRound(styles.icon);
        if (this.squad.isDead()) {
            cleverapps.UI.convertToGrayScale(icon);
        }

        return icon;
    }
});

cleverapps.styles.BattleStatIcon = {
    backlight: {
        width: 150,
        height: 150
    },

    background: {
        width: 130,
        height: 130
    },

    icon: {
        width: 100,
        height: 100
    },

    level: {
        x: { align: "center" },
        y: { align: "top", dy: 10 }
    }
};