/**
 * Created by r4zi4l on 05.04.2024
 */

RewardTypes.troopCards = {
    parse: function (value, options) {
        var rewards = [];

        for (var i = 0; i < value.amount; i++) {
            rewards.push(new Reward("troop", {
                code: TroopCards.PickCode(value.probabilities),
                stage: 0
            }, options));
        }

        return rewards;
    }
};

RewardTypes.troop = {
    handler: function (value) {
        cleverapps.formation.receiveNewSquad(value);
    }
};
