/**
 * Created by vladislav on 10/02/2023
 */

var ArmyGenerator = function () {

};

ArmyGenerator.prototype.listAllFormations = function (templates) {
    var formations = [];
    templates.forEach(function (config, index) {
        formations.push([]);
        var n = config.minN ? config.minN(this.size) : 1;
        var maxN = config.maxN || Infinity;
        while (n <= maxN) {
            var formation = config.generate(n);

            var fitsSize = formation.size.x <= this.size.x && formation.size.y <= this.size.y;
            var isSymmetrical = formation.size.y % 2 === this.size.y % 2 || formation.size.y <= this.size.y / 2;
            if (fitsSize && isSymmetrical) {
                formations[index].push(formation);

                n++;
            } else {
                break;
            }
        }
    }, this);

    return formations;
};

ArmyGenerator.prototype.init = function (size, levelNo) {
    this.size = {
        x: size.x / 2,
        y: size.y
    };
    this.levelNo = levelNo;
    this.targetPower = Math.min(200 + levelNo * 100, 70000);

    this.allPRVariants = this.listAllPRVariants().sort(function (a, b) {
        return Epicart.calcPowerRating(b.stage, b.rarity) - Epicart.calcPowerRating(a.stage, a.rarity);
    });

    this.maxUnitPower = Epicart.calcPowerRating(this.allPRVariants[0].stage, this.allPRVariants[0].rarity);
    this.powerEps = 100;
};

ArmyGenerator.prototype.listAllPRVariants = function () {
    var rarities = Object.keys(WarriorLines.RARITY).length;
    var stages = 5;

    var res = [];

    for (var i = 0; i < rarities; i++) {
        for (var j = 0; j < stages; j++) {
            res.push({
                rarity: i,
                stage: j
            });
        }
    }

    return res;
};

ArmyGenerator.prototype.findVariant = function (formations) {
    var shuffled = cleverapps.Random.shuffle(formations);

    var i = 0;
    while (i < shuffled.length) {
        var j = 0;
        var subtypes = cleverapps.Random.shuffle(shuffled[i]);
        while (j < subtypes.length) {
            var formation = subtypes[j];

            var variants = this.listVariants(formation);
            var variant = cleverapps.Random.choose(variants);
            j++;
            if (variant) {
                return {
                    variant: variant,
                    formation: formation
                };
            }
        }
        i++;
    }
};

ArmyGenerator.prototype.generate = function (size, levelNo) {
    this.init(size, levelNo);
    this.prVariants = this.allPRVariants.filter(function (prVariant) {
        var pr = Epicart.calcPowerRating(prVariant.stage, prVariant.rarity);

        return pr <= this.maxUnitPower;
    }, this);

    this.primaryFormations = this.listAllFormations(ArmyGenerator.FORMATIONS);
    this.secondaryFormations = this.listAllFormations(ArmyGenerator.SECONDARY_FORMATIONS);

    this.currentAmount = 0;
    this.currentPower = -1000;

    while (Math.abs(this.targetPower - this.currentPower) > this.powerEps) {
        this.currentAmount = 0;
        this.currentPower = 0;
        this.army = [];
        for (var x = 0; x < this.size.x; x++) {
            this.army.push([]);
            for (var y = 0; y < this.size.y; y++) {
                this.army[x].push(0);
            }
        }

        var variant = true;
        while (variant) {
            variant = this.findVariant(this.primaryFormations);

            if (!variant) {
                variant = this.findVariant(this.secondaryFormations);
            }

            if (variant) {
                this.addFormation(variant.variant, variant.formation);
            }
        }
    }

    return this.army;
};

ArmyGenerator.prototype.addFormation = function (variant, formation) {
    var cells = formation.cells.map(function (cell) {
        return {
            x: cell.x + variant.x,
            y: cell.y + variant.y
        };
    });

    if (variant.needsSymmetrical) {
        var symm = this.createSymmetrical(cells);
        cells = cells.concat(symm);
    }

    cells.forEach(function (cell) {
        this.army[cell.x][cell.y] = {
            x: cell.x + this.size.x,
            y: cell.y,
            code: variant.code,
            stage: variant.stage
        };
    }, this);

    this.currentAmount += variant.amount;
    this.currentPower += variant.power;
};

ArmyGenerator.prototype.createSymmetrical = function (cells) {
    var center = Math.floor(this.size.y / 2);

    var isEven = this.size.y % 2 === 0;

    return cells.map(function (cell) {
        var y = center - (cell.y - center);
        if (isEven) {
            y--;
        }

        return {
            x: cell.x,
            y: y
        };
    });
};

ArmyGenerator.prototype.listVariants = function (formation) {
    var variants = [];
    for (var x = 0; x < this.size.x - formation.size.x + 1; x++) {
        for (var y = 0; y < this.size.y - formation.size.y + 1; y++) {
            var variant = this.getVariant(x, y, formation);
            if (variant) {
                variants.push(variant);
            }
        }
    }

    return variants;
};

ArmyGenerator.prototype.canFitAmount = function (amount, unit) {
    var leftPower = this.targetPower - this.currentPower;

    var unitPower = Epicart.calcPowerRating(unit.stage, unit.rarity);

    var power = amount * unitPower;

    var leftAmount = this.size.x * this.size.y - this.currentAmount;

    if (amount > leftAmount) {
        return false;
    }

    if (amount === leftAmount) {
        return Math.abs(leftPower - power) <= this.powerEps;
    }

    return power <= leftPower
        && (leftPower + this.powerEps - power) / (leftAmount - amount) <= this.maxUnitPower;
};

ArmyGenerator.prototype.pickCode = function (x, y, rarity) {
    var codes = Object.values(WarriorLines.CODES).filter(function (code) {
        return WarriorLines[code].base.rarity === rarity;
    }, this);

    var filtered = codes.filter(function (code) {
        if (WarriorLines[code].base.range === WarriorLines.RANGE.INFINITY && x < Math.ceil(this.size.x / 3)) {
            return false;
        }
        if (WarriorLines[code].base.range === WarriorLines.RANGE.CLOSE && x >= Math.floor(2 * this.size.x / 3)) {
            return false;
        }

        if (WarriorLines[code].base.range === WarriorLines.RANGE.MEDIUM
            && (x < Math.floor(this.size.x / 3) || x > Math.floor(2 * this.size.x / 3))) {
            return false;
        }

        return true;
    }, this);

    if (!filtered.length) {
        filtered = codes;
    }

    return cleverapps.Random.choose(filtered);
};

ArmyGenerator.prototype.getVariant = function (x, y, formation) {
    for (var i = 0; i < formation.cells.length; i++) {
        var posX = formation.cells[i].x + x;
        var posY = formation.cells[i].y + y;

        if (this.army[posX][posY]) {
            return;
        }
    }

    var isSymmetrical = this.isSymmetrical(x, y, formation);

    var needsSymmetrical = !isSymmetrical && this.canAddSymmetrical(x, y, formation);

    if (!isSymmetrical && !needsSymmetrical) {
        return;
    }

    var prVariants = [];
    for (i = 0; i < this.prVariants.length; i++) {
        var prVariant = this.prVariants[i];

        var amount = formation.cells.length;
        if (needsSymmetrical) {
            amount *= 2;
        }

        var code = this.pickCode(x, y, prVariant.rarity);
        if (this.canFitAmount(amount, prVariant) && code !== undefined) {
            prVariants.push({
                code: code,
                stage: prVariant.stage,
                needsSymmetrical: needsSymmetrical,
                x: x,
                y: y,
                amount: amount,
                power: Epicart.calcPowerRating(prVariant.stage, prVariant.rarity) * amount
            });
        }
    }

    return cleverapps.Random.choose(prVariants);
};

ArmyGenerator.prototype.canAddSymmetrical = function (x, y, formation) {
    return y >= Math.ceil(this.size.y / 2)
        || y + formation.size.y - 1 < Math.floor(this.size.y / 2);
};

ArmyGenerator.prototype.isSymmetrical = function (x, y, formation) {
    var top = [];
    var bottom = [];
    var bottomSet = {};

    var isEven = this.size.y % 2 === 0;

    var center = Math.floor(this.size.y / 2);

    for (var i = 0; i < formation.cells.length; i++) {
        var posX = formation.cells[i].x + x;
        var posY = formation.cells[i].y + y;

        if (posY < center) {
            bottom.push({
                x: posX,
                y: posY
            });

            bottomSet[posX + "_" + posY] = true;
        } else if (isEven || posY > center) {
            top.push({
                x: posX,
                y: posY
            });
        }
    }
    
    if (top.length !== bottom.length) {
        return false;
    }

    return top.every(function (pos) {
        var y = center - (pos.y - center);
        if (isEven) {
            y--;
        }

        return bottomSet[pos.x + "_" + y];
    });
};

ArmyGenerator.SECONDARY_FORMATIONS = [
    {
        maxN: 1,
        generate: function () {
            return {
                n: 1,
                cells: [
                    {
                        x: 0,
                        y: 0
                    }
                ],
                size: {
                    x: 1,
                    y: 1
                }
            };
        }
    }
];

ArmyGenerator.FORMATIONS = [
    {
        type: "cross",
        minN: function () {
            return 3;
        },
        generate: function (n) {
            var cells = [];

            for (var x = 0; x < n; x++) {
                cells.push({
                    x: x,
                    y: x
                });
                if (n - x - 1 !== x) {
                    cells.push({
                        x: x,
                        y: n - x - 1
                    });
                }
            }

            return {
                n: n,
                cells: cells,
                size: {
                    x: n,
                    y: n
                }
            };
        }
    },
    {
        type: "wedge",
        minN: function () {
            return 2;
        },
        generate: function (n) {
            var cells = [];

            for (var x = 0; x < n; x++) {
                for (var y = -x; y <= x; y++) {
                    cells.push({
                        x: x,
                        y: y + n - 1
                    });
                }
            }

            return {
                n: n,
                cells: cells,
                size: {
                    x: n,
                    y: 1 + 2 * (n - 1)
                }
            };
        }
    },
    {
        type: "hollow_wedge",
        minN: function () {
            return 2;
        },
        generate: function (n) {
            var cells = [];

            for (var x = 0; x < n; x++) {
                if (x === 0) {
                    cells.push({
                        x: x,
                        y: -x + n - 1
                    });
                } else {
                    cells.push({
                        x: x,
                        y: -x + n - 1
                    });
                    cells.push({
                        x: x,
                        y: x + n - 1
                    });
                }
            }

            return {
                n: n,
                cells: cells,
                size: {
                    x: n,
                    y: 1 + 2 * (n - 1)
                }
            };
        }
    },
    {
        type: "column",
        minN: function (size) {
            return Math.ceil(size.y / 2);
        },
        generate: function (n) {
            var cells = [];

            for (var y = 0; y < n; y++) {
                cells.push({
                    x: 0,
                    y: y
                });
            }

            return {
                n: n,
                cells: cells,
                size: {
                    x: 1,
                    y: n
                }
            };
        }
    },
    {
        type: "row",
        minN: function (size) {
            return Math.ceil(size.x / 2);
        },
        generate: function (n) {
            var cells = [];

            for (var x = 0; x < n; x++) {
                cells.push({
                    x: x,
                    y: 0
                });
            }

            return {
                n: n,
                cells: cells,
                size: {
                    x: n,
                    y: 1
                }
            };
        }
    }
];

if (typeof cc === "undefined") {
    module.exports = ArmyGenerator;
}
