/**
 * Created by andrey on 27.01.2021.
 */

var ArmyLibraryIcon = cc.Node.extend({
    ctor: function (code, stage, options) {
        this._super();

        this.code = code;
        this.stage = stage || 0;
        this.options = options || {};
        this.unitLine = WarriorLines[code];

        var styles = cleverapps.styles.ArmyLibraryIcon;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(styles.background);

        var background = this.background = cleverapps.UI.createScale9Sprite(bundles.troop_icon.frames.unit_bg, cleverapps.UI.Scale9Rect.TwoPixelY);
        background.setContentSize2(styles.background);
        background.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(background);

        this.icon = this.createIcon();
        background.addChild(this.icon);

        if (cleverapps.armyLibrary.isFresh(this.code) && this.options.library) {
            this.rays = this.createRays();
            background.addChild(this.rays);
            this.rays.setPositionRound(this.icon.getPosition());
        }

        this.title = this.createTitle();
        background.addChild(this.title);

        this.backlight = this.createBacklight();
        this.addChild(this.backlight);

        var raceIcon = this.createRaceIcon();
        this.addChild(raceIcon);

        cleverapps.UI.wrap(this);

        if (stage !== undefined) {
            var stageBadge = new TroopStageView(stage);
            this.addChild(stageBadge);
            stageBadge.setPositionRound(styles.stars);
        }
    },

    collectReward: function () {
        cleverapps.armyLibrary.removeFresh(this.code, this.icon);

        this.rays.setLocalZOrder(2);
        this.rays.runAction(new cc.Sequence(
            new cc.ScaleTo(0.5, 0),
            new cc.RemoveSelf()
        ));

        this.icon.removeFromParent();

        this.icon = this.createIcon();
        this.background.addChild(this.icon);
    },

    createRaceIcon: function () {
        var styles = cleverapps.styles.ArmyLibraryIcon;

        var raceIcon = new cc.Scale9Sprite(bundles.race_icons.frames["race_" + this.unitLine.base.race]);
        raceIcon.setPositionRound(styles.raceIcon);

        return raceIcon;
    },

    createBacklight: function () {
        var styles = cleverapps.styles.ArmyLibraryIcon;

        var backlight = new cc.Scale9Sprite(bundles.troop_icon.frames["backlight_" + this.unitLine.base.rarity]);
        backlight.setContentSize2(styles.backlight);
        backlight.setPositionRound(this.width / 2, this.height / 2);
        backlight.setLocalZOrder(-1);

        return backlight;
    },

    createIcon: function () {
        var styles = cleverapps.styles.ArmyLibraryIcon;

        var icon;
        if (cleverapps.armyLibrary.isFresh(this.code) && this.options.library) {
            icon = new cc.Sprite(bundles.prize.frames.big_prize_png);
        } else if (!cleverapps.armyLibrary.isCodeOpened(this.code) && this.options.library) {
            icon = new cc.Sprite(bundles.troop_icon.frames.unit_unknown);
        } else {
            icon = WarriorView.getUnitImage(this.unitLine, this.stage);
        }
        cleverapps.UI.fitToBox(icon, styles.icon);
        icon.setPositionRound(styles.icon);
        icon.setLocalZOrder(1);

        return icon;
    },

    createRays: function () {
        var rays = new cleverapps.Spine(bundles.armylibrarywindow.jsons.rays_json, 2.2);
        rays.setAnimation(0, "animation", true);

        return rays;
    },

    createTitle: function () {
        var styles = cleverapps.styles.ArmyLibraryIcon;

        var name = cleverapps.armyLibrary.getUnitName(this.unitLine);

        var title = cleverapps.UI.generateOnlyText(name, cleverapps.styles.FONTS.UNITICON_TITLE);
        title.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        title.setDimensions(styles.title.width, 0);
        title.fitTo(undefined, styles.title.height);
        title.setAnchorPoint(0.5, 0.5);
        title.setPositionRound(styles.title);
        title.setLocalZOrder(1);

        return title;
    },

    getPreferredBundles: function () {
        return ["game"];
    }
});

cleverapps.overrideColors(cleverapps.styles.COLORS, {
    UNITICON_COLOR: new cc.Color(255, 255, 255, 255)
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    UNITICON_TITLE: {
        size: 30,
        color: cleverapps.styles.COLORS.SMALL_WHITE_TEXT
    }
});

cleverapps.styles.ArmyLibraryIcon = {
    stars: {
        x: { align: "center" },
        y: { align: "top", dy: 40 },
        margin: 5
    },

    background: {
        width: 260,
        height: 340
    },

    backlight: {
        width: 285,
        height: 365
    },

    raceIcon: {
        x: { align: "left", dx: 3 },
        y: { align: "bottom", dy: 3 }
    },

    icon: {
        x: { align: "center" },
        y: { align: "center", dy: 20 },
        width: 200,
        height: 190,
        maxScale: 1.3
    },

    title: {
        x: { align: "center", dx: 30 },
        y: { align: "bottom", dy: 37, anchor: "center" },
        width: 175,
        height: 70
    }
};