/**
 * Created by vladislav on 30/11/2022
 */

var BarracksIcon = cleverapps.Layout.extend({
    ctor: function () {
        var styles = cleverapps.styles.BarracksIcon;

        var icon = new cc.Sprite(bundles.battlefield.frames.barracks_icon_png);

        var bar = this.createBar();
        icon.addChild(bar);
        bar.setPositionRound(styles.bar);

        var text = cleverapps.UI.generateOnlyText("barracks", cleverapps.styles.FONTS.SMALL_WHITE_TEXT);

        this._super([icon, text], {
            margin: styles.margin,
            direction: cleverapps.UI.VERTICAL
        });

        cleverapps.UI.applyHover(this);

        cleverapps.UI.onClick(this, this.onClicked.bind(this));

        cleverapps.UI.makeHideable(this, cleverapps.UI.VERTICAL);

        cleverapps.meta.registerControl("barracksIcon", this);

        this.setPositionRound(styles);
    },

    updatePosition: function () {
        this.setPositionRound(cleverapps.styles.BarracksIcon);
    },

    onClicked: function () {
        cleverapps.meta.display({
            focus: "Barracks",
            action: function (f) {
                cleverapps.audio.playSound(bundles.main.urls.click_effect);

                cleverapps.barracks.open(f);
            }
        });
    },

    createBar: function () {
        var styles = cleverapps.styles.BarracksIcon;

        var bg = cleverapps.UI.createScale9Sprite(bundles.battlefield.frames.barracks_bar_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize2(styles.bar);

        var amount = new BarracksAmountView();
        bg.addChild(amount);
        amount.setPositionRound(bg.width / 2, bg.height / 2);

        return bg;
    },

    animateBought: function () {
        this.runAction(new cc.Sequence(
            new cc.ScaleTo(0.15, 1.2).easing(cc.easeOut(2)),
            new cc.ScaleTo(0.15, 1.0).easing(cc.easeOut(2))
        ));
        cleverapps.barracks.trigger("updateAmount");
    }
});

cleverapps.styles.BarracksIcon = {
    width: 200,
    height: 110,

    margin: 40,

    bar: {
        width: 270,
        height: 75,

        x: { align: "center" },
        y: { align: "bottom", dy: -40 }
    },

    x: { align: "left", dx: 50 },
    y: { align: "center", dy: -500 }
};