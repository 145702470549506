/**
 * Created by vladislav on 29/11/2022
 */

var SquadView = cc.Node.extend({
    ctor: function (squad) {
        this._super();

        this.squad = squad;

        this.views = this.squad.warriors.map(function (warrior) {
            var warriorView = new WarriorView(warrior);
            this.addChild(warriorView);

            var coef = Epicart.virtualToReal();

            var x = warrior.squadX * coef;
            var y = warrior.squadY * coef;

            warriorView.setLocalZOrder(-y);
            warriorView.setPositionRound(x, y);

            return warriorView;
        }, this);

        this.setAnchorPoint(0.5, 0.5);

        cleverapps.UI.wrap(this);

        this.offsetX = this.x;
        this.offsetY = this.y;

        this.updatePosition();

        var cellHeight = cleverapps.styles.EpicartScene.cell.height;
        this.dragRect = cc.rect(-this.width / 2, -cellHeight / 2, 2 * this.width, this.height + cellHeight / 2);

        this.squad.onMove = this.createListener(this.move.bind(this));
        this.squad.onRemove = this.createListener(this.remove.bind(this));
        this.squad.onHighlight = this.createListener(this.highlight.bind(this));
        this.squad.onMinimize = this.createListener(this.minimize.bind(this));
        this.squad.onMaximize = this.createListener(this.maximize.bind(this));
        this.squad.onDragStart = this.createListener(this.onDragStart.bind(this));
        this.squad.onDragEnd = this.createListener(this.onDragEnd.bind(this));
        this.squad.onDragMove = this.createListener(this.onDragMove.bind(this));
        this.squad.onFollowPointer = this.createListener(this.onFollowPointer.bind(this));
        this.squad.onDeploy = this.createListener(this.onDeploy.bind(this));
        this.squad.onGetView = this.createListener(function () {
            return this;
        }.bind(this));

        if (cleverapps.config.debugMode) {
            this.setRecCascadeColorEnabled(true);
        }
    },

    updatePosition: function () {
        var pos = Battlefield.cellPosToReal(this.squad.x, this.squad.y);
        this.setPositionRound(pos.x + this.offsetX, pos.y + this.offsetY);

        this.setLocalZOrder(-this.squad.y * Battlefield.GRID.x + this.squad.x);
    },

    onDragStart: function () {
        this.views.forEach(function (warriorView) {
            if (warriorView.hasAnimation("fly")) {
                warriorView.setAnimation(0, "fly", true);
            }
        });

        this.setLocalZOrder(100);

        this.info = this.createInfo();
        this.addChild(this.info);
        this.info.setPositionRound(cleverapps.styles.SquadView.info);
    },

    createInfo: function () {
        var name = cleverapps.UI.generateOnlyText("Units." + this.squad.code + ".name", cleverapps.styles.FONTS.WHITE_TEXT);

        var styles = cleverapps.styles.SquadView;

        return new cleverapps.Layout([name, new TroopStageView(this.squad.stage)], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.info.margin
        });
    },

    onFollowPointer: function (pos) {
        this.setPositionRound(pos.x + this.offsetX, pos.y + this.offsetY);
    },

    onDragMove: function () {
        if (cleverapps.config.debugMode) {
            if (Game.currentGame.battlefield.isInDeleteZone(this.squad)) {
                this.setColor(cc.color.RED);
            } else {
                this.setColor(cc.color.WHITE);
            }
        }
    },
    onDragEnd: function (fromBarracks) {
        this.views.forEach(function (warriorView) {
            warriorView.setAnimation(0, "idle", true);
        });

        if (fromBarracks) {
            this.removeFromParent();
        }

        this.info.removeFromParent();
        delete this.info;
    },

    containsPoint: function (pos) {
        pos = cc.p(pos.x - this.x + this.width / 2, pos.y - this.y + this.height / 2);

        return cc.rectContainsPoint(this.dragRect, pos);
    },

    highlight: function () {
        if (this.squad.isHighlighted) {
            this.highlightAnimationDown = new cleverapps.Spine(bundles.battlefield.jsons.merge_highlight_json);
            this.addChild(this.highlightAnimationDown);
            this.highlightAnimationDown.setPositionRound(this.width / 2, 0);
            this.highlightAnimationDown.setAnimation(0, "animation", true);
            this.highlightAnimationDown.setLocalZOrder(-10000);

            this.highlightAnimationUp = new cleverapps.Spine(bundles.battlefield.jsons.merge_arrows_json);
            this.addChild(this.highlightAnimationUp);
            this.highlightAnimationUp.setPositionRound(this.width / 2, 0);
            this.highlightAnimationUp.setAnimation(0, "animation", true);
            this.highlightAnimationUp.setLocalZOrder(10000);
        } else {
            this.highlightAnimationDown.removeFromParent();
            delete this.highlightAnimationDown;
            this.highlightAnimationUp.removeFromParent();
            delete this.highlightAnimationUp;
        }
    },

    minimize: function () {
        if (this.minimized) {
            return;
        }

        this.minimized = true;

        this.info.setVisible(false);
        this.views.forEach(function (view) {
            view.setVisible(false);
        });

        if (!this.iconView) {
            this.iconView = new BarracksSquadView(new BarracksSquad(this.squad));
            this.addChild(this.iconView);
            this.iconView.setPositionRound(this.width * this.anchorX, this.height * this.anchorY);
            this.iconView.setScale(1 / cc.director.getRunningScene().battlefield.scale);
        }

        this.iconView.setVisible(true);
    },

    maximize: function () {
        if (!this.minimized) {
            return;
        }

        this.minimized = false;

        this.info.setVisible(true);

        this.views.forEach(function (view) {
            view.setVisible(true);
        });

        this.iconView.setVisible(false);
    },

    remove: function () {
        this.removeFromParent();
    },

    move: function () {
        this.updatePosition();

        cleverapps.audio.playSound(bundles.units.urls.prepare_stand_effect);
    },

    onDeploy: function () {
        this.views.forEach(function (view) {
            Game.currentGame.battlefield.addToLayer(Battlefield.LAYERS.UNITS, view);

            view.syncPosition();
        }, this);

        this.removeFromParent();
    }
});

cleverapps.styles.SquadView = {
    padding: {
        bottom: 30
    },

    info: {
        margin: 10,

        x: { align: "center" },
        y: { align: "top", dy: 150 }
    }
};