/**
 * Created by vladislav on 28/03/2023
 */

var Rage = function (warrior, options) {
    Skill.call(this, warrior, options);
};

Rage.prototype = Object.create(Skill.prototype);
Rage.prototype.constructor = Rage;

Rage.prototype.canUse = function () {
    return !this.active && this.warrior.getRemainingHealthPercent() < 50;
};

Rage.prototype.getActivity = function () {
    this.active = true;

    return new RageActivity(this.warrior, "rage", this.options.buff);
};